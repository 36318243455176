<template>
    <b-row class="library h-100 p-0">
        <div class="position-absolute h-100 w-100">
            <spinner v-if="running"></spinner>
        </div>
        <b-col v-for="(user, o) in users" :key="o" class="p-5">
            <b-btn @click="runLibrarySearch(user)">{{user === 'RachelTal2' ? 'RiHazaken' : user === 'RachelTal' ? 'RiHazaken2' : user}}</b-btn>
        </b-col>
    </b-row>
</template>
<script>
import Spinner from '@/components/spinner'

import { Actions } from '@/store/stateChanges'
import { RunStates } from '@/store/runStates'

export default {
    name: 'Library',
    components: { Spinner },
    data() {
        return {
            showSelectText: false,
            morphTool: process.env.VUE_APP_TOOL_NAME === 'morph-search',
            morphUser: process.env.VUE_APP_USER_NAME,
            morphUsers: ['Orly','Elisheva','RachelTal', 'RachelTal2'],
            allUsers: ['library', 'shas']
        }
    },
    mounted () {
      
    },
    methods: {
      runLibrarySearch(uid) {
        this.$store.commit('SET_POLLING_PARAMS', { uid: uid })         
        this.$store.dispatch(Actions.GET_MORPH_TAGGING)   
        //this.$router.push({ name: "results" })     
      }
    },
    computed: {
        users() {
            let singleMorphUser = this.morphUsers.indexOf(this.morphUser)
            return this.morphTool ? singleMorphUser >= 0 ? [this.morphUsers[singleMorphUser]] : this.morphUsers : this.allUsers  
        },
        hebrew() {
            return this.$settings.hebrew
        },
        running() {
            return this.$store.state.morphApiState === RunStates.RUNNING
        },
    }

}
</script>      