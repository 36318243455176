import { Actions, StateChanges } from './stateChanges'
import { RunStates } from './runStates'
import { StoreConfig } from './storeConfig'
import axios from 'axios'
import router from '../router'


const NAKDAN_SERVER = StoreConfig.NAKDAN_SERVER
var pollIndex = 0

const axiosInstance = axios.create({ timeout: 220000 })
axiosInstance.defaults.headers = {
    // 'Content-Type': 'text/plain;charset=UTF-8'
    'content-type': 'application/json'
}
const controller = new AbortController();

export default {
    [Actions.RUN_NAKDAN]: function ({ commit, state, dispatch }) {
        commit(StateChanges.MORPH_RUNNING)
        var formData = new FormData()
        formData.append("file", state.fileForApi)
        formData.append('fileType', state.fileType)
        formData.append("uid", '')
        axiosInstance.post(NAKDAN_SERVER + '/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
            // commit(StateChanges.SET_ABBR_RESULTS, response.data)
            if (response.data) {
                commit(StateChanges.SET_POLLING_PARAMS, response.data)
                commit(StateChanges.MORPH_COMPLETE)
                if (!response.data.error && !response.data.noFiles && response.data.invalidFiles.length === 0)
                    dispatch(Actions.RUN_POLL)
            }
        })
            .catch(function (error) {
                console.log(error)
                commit(StateChanges.MORPH_FAILED)
            })

    },
    [Actions.RUN_POLL]: function ({ state, dispatch, commit }) {
        commit(StateChanges.MORPH_RUNNING)
        const requestObj = {
            uid: state.uid,
            processId: state.processId
        }
        axiosInstance.post(NAKDAN_SERVER + '/poll', JSON.stringify(requestObj)) //{ headers: { 'Content-Type': 'application/json' } }
            .then((response) => {
                //console.log(response)
                if (response.data) {
                    if (response.data.done) {
                        pollIndex = 0
                        commit(StateChanges.MORPH_COMPLETE)
                        dispatch(Actions.GET_MORPH_TAGGING)
                    }
                    else {
                        if (response.data.error) {
                            pollIndex = 0
                            commit(StateChanges.MORPH_FAILED)
                        }
                        else {
                            pollIndex++
                            if (pollIndex < 31)
                                setTimeout(() => { dispatch(Actions.RUN_POLL) }, 1000)
                            else {
                                commit(StateChanges.MORPH_TIMED_OUT)
                                pollIndex = 0
                            }
                        }
                    }
                }

            }).catch(error => {
                console.log(error)
                if (error.code === 'ECONNABORTED') {
                    commit(StateChanges.MORPH_TIMED_OUT)
                } else {
                    commit(StateChanges.MORPH_FAILED)
                }
            })
    },
    [Actions.GET_MORPH_TAGGING]: function ({ state, commit }) {
        controller.abort()
        if (state.morphApiState === RunStates.RUNNING) {
            controller.abort()
        }
        commit(StateChanges.MORPH_RUNNING)
        const requestObj = {
            uid: state.uid,
            filters: state.filters,
            offset: state.offset,
            resultsPerPage: 20,
            contextLength: 20,
            morphLang: state.morphLang,
            hideNames: state.hideShemot
        }
        axiosInstance.post(NAKDAN_SERVER + '/search', JSON.stringify(requestObj), { signal: controller.signal })
            .then((response) => {
                if (router.currentRoute.name !== 'results')
                    router.push({ name: "results" })
                window.localStorage.setItem('nikud-search-uid', state.uid)
                commit(StateChanges.MORPH_COMPLETE)
                commit(StateChanges.SET_SERVER_RESULTS, response.data)
            })

            .catch(error => {
                if (!axios.isCancel(error)) {
                    console.log(error)
                    if (error.code === 'ECONNABORTED') {
                        commit(StateChanges.MORPH_TIMED_OUT)
                    } else {
                        commit(StateChanges.MORPH_FAILED)
                    }
                }
            })
    },

    [Actions.GET_OCCURENCES]: function ({ state, commit, dispatch }) {
        if (state.morphApiState === RunStates.RUNNING) {
            controller.abort()
        }
        commit(StateChanges.MORPH_RUNNING)
        let firstCall = state.unfilteredCountItems.length === 0 || state.unfilteredLexCountItems.length === 0
        const requestObj = {
            uid: state.uid,
            filters: state.unfilteredCountItems.length === 0 || state.unfilteredLexCountItems.length === 0 ? [] : state.filters,
            groupBy: state.selectedMode,
            resultsPerPage: firstCall ? 200 : 150,
            hideNames: state.hideShemot
        }
        axiosInstance.post(NAKDAN_SERVER + '/count', JSON.stringify(requestObj), { signal: controller.signal })
            .then((response) => {
                if (firstCall) //for sw data
                    commit(StateChanges.SET_ALL_WORD_COUNT_RESULTS, response.data)
                else //for filtered data
                    commit(StateChanges.SET_COUNT_RESULTS, response.data)                
                if (state.selectedMode === 'menukad') {
                    commit(StateChanges.SET_MODE, 'lexeme')
                    dispatch(Actions.GET_OCCURENCES)
                }
                else {
                    if (state.selectedMode === 'lexeme') {
                        commit(StateChanges.SET_MODE, 'baseword')
                        dispatch(Actions.GET_OCCURENCES)
                    }
                    else {
                        if (state.selectedMode === 'baseword') {
                            commit(StateChanges.SET_MODE, 'menukad')
                        }
                    }
                }
                commit(StateChanges.MORPH_COMPLETE)

            }).catch(error => {
                if (!axios.isCancel(error)) {
                    console.log(error)
                    if (error.code === 'ECONNABORTED') {
                        commit(StateChanges.MORPH_TIMED_OUT)
                    } else {
                        commit(StateChanges.MORPH_FAILED)
                    }
                } else {
                    console.log('cancel')
                }
            })
    },
    [Actions.GET_FREQUENCY]: function ({ state, commit }) {
        if (state.morphApiState === RunStates.RUNNING) {
            controller.abort()
            //console.log(controller)
        }
        commit(StateChanges.MORPH_RUNNING)
        const requestObj = {
            task: 'frequency',
            uid: state.uid,
            filters: state.filters,//.filter(e =>!e.negate),
          
            histogramResolution: state.histogramResolution,// state.readerContentLength, default: 50
        
            length: state.readerContentLength // default 1000
        }
        axiosInstance.post(NAKDAN_SERVER + '/api', JSON.stringify(requestObj), { signal: controller.signal })
            .then((response) => {
                commit(StateChanges.MORPH_COMPLETE)
                commit(StateChanges.SET_FREQUENCY_RESULTS, response.data)

            }).catch(error => {
                if (!axios.isCancel(error)) {
                    console.log(error)
                    if (error.code === 'ECONNABORTED') {
                        commit(StateChanges.MORPH_TIMED_OUT)
                    } else {
                        commit(StateChanges.MORPH_FAILED)
                    }
                } else {
                    console.log('cancel')
                }
            })

    },    
    [Actions.GET_READER]: function ({ state, commit }) {
        if (state.morphApiState === RunStates.RUNNING) {
            controller.abort()
         //   console.log(controller)
        }
        commit(StateChanges.MORPH_RUNNING)
        let fileFilters = state.filters.filter(e => e.filterType === 'files') //get saved filter file name
        const requestObj = {
            task: 'reader',
            uid: state.uid,
            filters: state.filters,//.filter(e =>!e.negate),
            filename: fileFilters.length > 0 ? fileFilters[0].filter[state.selectedFileIndex] : state.userFiles[state.selectedFileIndex].filename,
            histogramResolution: state.histogramResolution,// state.readerContentLength, default: 50
            offset: state.offset,
            length: state.readerContentLength, // default 1000
            hideNames: state.hideShemot
        }
        axiosInstance.post(NAKDAN_SERVER + '/api', JSON.stringify(requestObj), { signal: controller.signal })
            .then((response) => {
                commit(StateChanges.MORPH_COMPLETE)
                commit(StateChanges.SET_READER_RESULTS, response.data)

            }).catch(error => {
                if (!axios.isCancel(error)) {
                    console.log(error)
                    if (error.code === 'ECONNABORTED') {
                        commit(StateChanges.MORPH_TIMED_OUT)
                    } else {
                        commit(StateChanges.MORPH_FAILED)
                    }
                } else {
                    console.log('cancel')
                }
            })

    },
    [Actions.GET_GROUPED_MORPHS]: function ({ state, commit }) {
        if (state.morphApiState === RunStates.RUNNING)
            controller.abort()
        commit(StateChanges.MORPH_RUNNING)
        const requestObj = {
            uid: state.uid,
            filters: state.filters,
            groupBy: 'morph',
            morphLang: state.morphLang,
            offset: state.offset,
            hideNames: state.hideShemot
        }
        axiosInstance.post(NAKDAN_SERVER + '/count', JSON.stringify(requestObj), { signal: controller.signal })
            .then((response) => {
                commit(StateChanges.MORPH_COMPLETE)
                commit(StateChanges.SET_GROUPED_MORPH_RESULTS, response.data)
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    console.log(error)
                    if (error.code === 'ECONNABORTED') {
                        commit(StateChanges.MORPH_TIMED_OUT)
                    } else {
                        commit(StateChanges.MORPH_FAILED)
                    }
                }

            })

    },
    [Actions.GET_ADJACENT]: function ({ state, commit, dispatch }) { //, dispatch
        if (state.morphApiState === RunStates.RUNNING)
            controller.abort()
        return new Promise((resolve, reject) => {
            commit(StateChanges.MORPH_RUNNING)
            const requestObj = {
                uid: state.uid,
                filters: state.filters,
                groupBy: state.selectedMode,
                offset: state.offset,
                dir: state.adjacentDirection,
                resultsPerPage: 20,
                hideNames: state.hideShemot
            }
            axiosInstance.post(NAKDAN_SERVER + '/countAdjacent', JSON.stringify(requestObj), { signal: controller.signal })
                .then((response) => {
                    commit(StateChanges.MORPH_COMPLETE)
                    if (state.adjacentDirection === 'prev') {
                        commit(StateChanges.SET_ADJACENT_PREV_RESULTS, response.data)
                        if (state.selectedMode === 'menukad') {
                            commit(StateChanges.SET_MODE, 'lexeme')
                            dispatch(Actions.GET_ADJACENT)
                        }
                        else {
                            if (state.selectedMode === 'lexeme') {
                                commit(StateChanges.SET_MODE, 'baseword')
                                dispatch(Actions.GET_ADJACENT)
                            }
                            else {
                                if (state.selectedMode === 'baseword') {
                                    commit(StateChanges.SET_MODE, 'menukad')
                                }
                            }
                        }
                        /*   state.adjacentDirection = 'next'
                          dispatch(Actions.GET_ADJACENT) */
                    } else {
                        commit(StateChanges.SET_ADJACENT_NEXT_RESULTS, response.data)
                        if (state.selectedMode === 'menukad') {
                            commit(StateChanges.SET_MODE, 'lexeme')
                            dispatch(Actions.GET_ADJACENT)
                        }
                        else {
                            if (state.selectedMode === 'lexeme') {
                                commit(StateChanges.SET_MODE, 'baseword')
                                dispatch(Actions.GET_ADJACENT)
                            }
                            else {
                                if (state.selectedMode === 'baseword') {
                                    commit(StateChanges.SET_MODE, 'menukad')
                                }
                            }
                        }
                        //state.adjacentDirection = 'prev'
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error)
                    if (!axios.isCancel(error)) {
                        console.log(error)
                        if (error.code === 'ECONNABORTED') {
                            commit(StateChanges.MORPH_TIMED_OUT)
                        } else {
                            commit(StateChanges.MORPH_FAILED)
                        }
                    }
                })
        })
    },
    [Actions.GET_NGRAMS]: function ({ state, commit }) {
        if (state.morphApiState === RunStates.RUNNING)
            controller.abort()
        commit(StateChanges.MORPH_RUNNING)
        const requestObj = {
            uid: state.uid,
            filters: state.filters.filter(e => e.filterType === 'files'),
            offset: state.offset,
            groupBy: 'menukad',
            resultsPerPage: 20,
            nSize: state.ngramSize,
            hideNames: state.hideShemot
        }
        axiosInstance.post(NAKDAN_SERVER + '/ngrams', JSON.stringify(requestObj), { signal: controller.signal })
            .then((response) => {
                commit(StateChanges.MORPH_COMPLETE)
                commit(StateChanges.SET_NGRAM_RESULTS, response.data)
                if (process.env.VUE_APP_USER_NAME !== 'library' && process.env.VUE_APP_USER_NAME !== 'shas') {
                    if (router.currentRoute.name !== 'results')
                        router.push({ name: "results" })
                    window.localStorage.setItem('nikud-search-uid', state.uid)
                }

            }).catch(error => {
                if (!axios.isCancel(error)) {
                    console.log(error)
                    if (error.code === 'ECONNABORTED') {
                        commit(StateChanges.MORPH_TIMED_OUT)
                    } else {
                        commit(StateChanges.MORPH_FAILED)
                    }
                }
            })
    },
    [Actions.GET_MORPH_VALUES]: function ({ commit, state }) {
        commit(StateChanges.MORPH_RUNNING)

        const requestObj = {
            uid: state.uid
        }
        axiosInstance.post(NAKDAN_SERVER + '/listMorphOptions', JSON.stringify(requestObj), { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                commit(StateChanges.MORPH_COMPLETE)
                commit(StateChanges.SET_MORPH_VALUES, response.data.morphs)

            }).catch(error => {
                console.log(error)
                if (error.code === 'ECONNABORTED') {
                    commit(StateChanges.MORPH_TIMED_OUT)
                } else {
                    commit(StateChanges.MORPH_FAILED)
                }
            })
    },
    [Actions.LIST_FILES]: function ({ state, commit }) {
        if (state.morphApiState === RunStates.RUNNING)
            controller.abort()
        commit(StateChanges.MORPH_RUNNING)
        let fileFilters = state.filters.filter(e => e.filterType === 'files')
        let requestObj = {
            uid: state.uid
        }
        if (fileFilters && fileFilters.length > 0) {
            requestObj = {
                uid: state.uid,
                files: fileFilters[0].filter
            }
        }

        axiosInstance.post(NAKDAN_SERVER + '/listFiles', JSON.stringify(requestObj), { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                if (!fileFilters || fileFilters.length === 0) {
                    if (state.userFiles.length === 0)
                        commit(StateChanges.SET_USER_FILES, response.data)
                } else {
                    commit(StateChanges.SET_FILTERED_FILES, response.data)
                }
                commit(StateChanges.MORPH_COMPLETE)
               // dispatch(Actions.GET_READER)

            }).catch(error => {
                console.log(error)
                if (error.code === 'ECONNABORTED') {
                    commit(StateChanges.MORPH_TIMED_OUT)
                } else {
                    commit(StateChanges.MORPH_FAILED)
                }
            })
    }
}
