<template>
    <div>
        <div class="position-relative oc-table bg-white" :class="{ 'mx-3': useStopWords }" v-if="occurrences.length > 0">
            <b-table :sticky-header="useStopWords ? '325px' : '382px'" ref="wordTable" :items="occurrences"
                :fields="useStopWords ? swfields : fields" :selectable="useStopWords" @row-selected="onRowSelected">
                <template #head(selected) v-if="useStopWords">
                    <template v-if="selected.length === occurrences.length">
                        <i class="fas fa-check-square text-primary" @click="clearSelected" style="cursor:pointer"></i>
                    </template>
                    <template v-else>
                        <i class="far fa-square" @click="selectAllRows" style="cursor:pointer"></i>
                    </template>
                </template>
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(selected)="data" v-if="useStopWords">
                    <template v-if="selected.findIndex(item => item[selectedMode] === data.item['word']) > -1">
                        <i class="fas fa-check-square text-primary"></i>
                    </template>
                    <template v-else>
                        <i class="far fa-square"></i>
                    </template>
                </template>
                <template #cell(word)="data">
                    <span v-if="useStopWords">
                        {{ data.item['word'] }}
                    </span>
                    <span class="text-primary f-narkis" style="cursor: pointer;" v-else
                        @click="itemClickHandler(data.item['word'])">
                        {{ data.item['word'] }}
                    </span>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
//import { Actions } from '@/store/stateChanges'
export default {
    name: 'OccurrenceTable',
    props: [ 'useStopWords', 'selectedMode', 'filteredOccurences'],
    data() {
        return {
            fields: [
                { key: 'index', class: 'index-col' },
                { key: 'word', class: 'word-col' },
                { key: 'count' },
            ],
            swfields: [
                { key: 'selected', label: '', sortable: false, tdClass: "select-cb" },
                { key: 'index', class: 'index-col' },
                { key: 'word', class: 'word-col' },
                { key: 'count' },

            ],
            selected: [],

        }
    },
    mounted() {
        if (this.useStopWords)
            this.selectSavedItems()
    },
    methods: {
        itemClickHandler(word) {
            let wordParam = {
                'filterType': this.selectedMode !== 'baseWord' ? this.selectedMode : 'word',
                'filter': this.selectedMode !== 'baseWord' ? [word] : [word.replace(/[\u0591-\u05C7]/g, '')]
            }

            let newParams = this.filters.slice()
            let index = newParams.findIndex(x => x.filterType === this.selectedMode !== 'baseWord' ? this.selectedMode : 'word')
            if (index > -1) {
                newParams.splice(index, 1)
            }
            newParams.push(wordParam)
            let newUrl = encodeURIComponent(JSON.stringify(newParams))
            window.open(window.location.origin + '/filter-results?search=' + newUrl, '_blank');
        },
        selectSavedItems() {
            this.$nextTick(() => {
                if (this.filteredOccurences && this.filteredOccurences.length > 0 && this.occurrences) {
                    this.filteredOccurences.map(item => {
                        const index = this.occurrences.map(e => e[this.selectedMode]).indexOf(item)
                        if (index >= 0)
                            this.selectRow(index)
                    })
                }
            })
        },
        selectAllRows() {
            if (this.$refs.wordTable)
                this.$refs.wordTable.selectAllRows()
        },
        selectRow(index) {
            this.$refs.wordTable.selectRow(index)
        },
        clearSelected() {
            this.$refs.wordTable.clearSelected()
        },
        onRowSelected(items) {
            this.selected = items
            this.$emit('selected-rows', this.selected)
        },
    },
    computed: {
        filters() {
            return this.$store.state.filters
        },
        settings() {
            return this.$store.state.cloudSettings
        },
        wordOccurrences() {
            return this.$store.state.wordCountItems
        },
        lexOccurrences() {
            return this.$store.state.lexCountItems
        },
        baseOccurrences() {
            return this.$store.state.baseWordCountItems
        },
        occurrenceTableData() {
            return this.selectedMode === 'menukad' ? this.wordOccurrences : this.selectedMode === 'lexeme' ? this.lexOccurrences : this.baseOccurrences
        },
        occurrences() {
            //const hebrewRegex = /[^א-ת]/g; 

            const newArray = this.occurrenceList.map(obj => ({
                count: obj.count,
                word: obj[this.selectedMode].replace(/&quot;/g, '"')//.replace(/&#[\w]+;/g, '').replace(hebrewRegex, '') // remove non-Hebrew letters
            }));

            return newArray
        },
        occurrenceList() {
            let temp = JSON.parse(JSON.stringify(this.occurrenceTableData))
            if (!this.settings.showSingleResult) {
                temp = temp.filter(item => { return item.count > 1 })
            }
            if (this.settings.showTopWordsOnly) {
                temp = temp.slice(0, parseInt(this.settings.numberToDisplay))
            }
            return temp
        },      
    },
    watch: {
   /*      wcData() { //for sw modal, when number is updated
            this.selectSavedItems()
        } */
    }
}
</script>
<style lang="scss">
.table.b-table>tbody>.table-active,
.table.b-table>tbody>.table-active>th,
.table.b-table>tbody>.table-active>td,
.table.b-table>tbody>.table-active {
    background-color: transparent !important;
}

.oc-table {
    border-radius: 8px;
    border: solid 1px #000;
    overflow-x: hidden;

    .table td,
    .table th {
        padding: 0.34rem 0.75rem;
        vertical-align: middle;
        border-left: 1px solid #979797;
    }

    .select-cb {
        width: 20px;
    }

    .index-col {
        width: 35px;
    }

    .word-col {
        width: 100px;

    }
}
</style>