<template>
  <div class="typeahead" :class="customClass" >
    <div>
      <div class="spinner-wrap position-absolute" v-if="loading">
        <Spinner></Spinner>
      </div>
      <b-form-group class="mb-0 bg-white overflow-hidden" ref="formWrap">
        <b-form-input v-model="searchWord" :id="searchId" class="search-input-dd d-inline f-narkis w-100 border-0 pt-0"
          autocomplete="off" @click="inputClicked" @keydown.esc="showTypeaheads = false" @input="runTypeAhead()"
          @keydown="onKeyDownDropdown()" @keydown.down="arrowPress('down')" @keydown.up="arrowPress('up')"
          @keydown.backspace="$emit('backspace')"
          @keyup.enter="runSearchFromDropDown()" @blur="onFocusOut">
        </b-form-input>
        <b-button          
          variant="link" @click="searchClicked()" :disabled="(loading && showTypeaheads) || searchWord === ''"
          class="ta-search-btn border-none shadow-none d-inline bg-white px-2 w-25 text-left">
          <i class="fas fa-search"></i>
        </b-button>
<!--         <b-btn @click="searchWord = ''; $emit('ta-cleared')" variant="link"
          class="clear-ta py-0 px-1 mt-n1 border-none shadow-none bg-transparent text-muted position-absolute text-left d-none"
          v-if="searchWord !== '' && customClass !== 'morph-search-ta'">
          &times;
        </b-btn> -->
      </b-form-group>
      <div class="mb-n3 not-in-list" v-if="showNotInList">
        <small v-if="customClass === 'morph-search-ta'">
          {{ hebrew? 'מורפולוגיה לא קיימת ברשימה': 'Morpholgy not in list' }}
        </small>
        <small v-else>
          {{ hebrew? 'מילה לא נמצאה ברשימה': 'Word not in list' }}
        </small>
      </div>
      <ul id="search-typeaheads" ref="searchTypeaheads" tabindex="0"
        v-show="typeAheads && typeAheads.length > 0 && showTypeaheads"
        class="autocomplete-results position-absolute rounded bg-white px-0">
        <li v-html="word" v-for="(word, i) in typeAheads" tabindex="0" ref="listItem" :key="i" @click="setInput(word)"
          :class="{ 'is-active': i === activeIndex }" class="autocomplete-result px-2 pb-1 f-narkis position-relative">          
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
let previousRequestCancellation = null
// this variable holds a function that allows us to cancel the last request that we made
// if it's null, there aren't any old requests
// otherwise, it's a function
// we call the function, and then the old request will no longer be able to call 'updateList'
// 'updateList' is the name of our function that actually sets the Vue variable that holds the list of options

const hebrewKeyboard = {
  'q': '\u002F', 'w': '\u0027',
  'e': '\u05E7', 'r': '\u05E8', 't': '\u05D0',
  'y': '\u05D8', 'u': '\u05D5', 'i': '\u05DF',
  'o': '\u05DD', 'p': '\u05E4', 'a': '\u05E9',
  's': '\u05D3', 'd': '\u05D2', 'f': '\u05DB',
  'g': '\u05E2', 'h': '\u05D9', 'j': '\u05D7',
  'k': '\u05DC', 'l': '\u05DA', ';': '\u05E3',
  'z': '\u05D6', 'x': '\u05E1', 'c': '\u05D1',
  'v': '\u05D4', 'b': '\u05E0', 'n': '\u05DE',
  'm': '\u05E6', ',': '\u05EA', '.': '\u05E5'
}
import Spinner from '@/components/spinner'
import { stringWithoutNikud } from '@/js/commonHebrew'
export default {
  name: 'TypeAhead',
  props: ['typeAheadFunc', 'searchTerm', 'forceHebrew', 'searchId', 'customClass', 'minimumLetters'],
  components: {
    Spinner
  },
  data() {
    return {
      searchWord: '',
      typeAheads: [],
      showTypeaheads: false,
      activeIndex: 0,
      showNotInList: false,
      loading: false
    }
  },
  mounted() {
    document.addEventListener('click', this.hideList)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideList)
  },
  methods: {
    onFocusOut() {
      this.$emit('focus-out')
      if (this.$refs.formWrap) {
        this.$refs.formWrap.$el.classList.add('border-dark')
        this.$refs.formWrap.$el.classList.remove('border-primary')
      }
    },
    inputClicked() {
      this.$emit('focus-in')
      if (this.$refs.formWrap) {
        this.$refs.formWrap.$el.classList.remove('border-dark')
        this.$refs.formWrap.$el.classList.add('border-primary')
      }
      /*   if (this.searchWord !== '' && !this.showNotInList) {
          this.getUpdatedList(this.searchWord)
        }  */
    },
    // this function is a wrapper for the function the user supplies as a prop
    // it only adds the ability to cancel the effects of the call
    // (the call itself will continue, of course)
    getUpdatedList(tip) {
      const tipLength = (this.forceHebrew ? stringWithoutNikud(tip) : tip).length
      if (tipLength < this.minimumLetters) return
      // flag, used to check if a cancellation has been requested
      // note that there's a new copy of cancelled each time we call getUpdatedList()
      // so setting it to true from a previous call doesn't affect the current call
      let cancelled = false
      this.loading = true
      // call the user's function. It must return a promise, and the promise must resolve to a list of words
      // that we can display in our list
      const promise = this.typeAheadFunc(tip)
      // wait for the user's function to give us a list, but then check `cancelled` before doing anything with it
      // if the function errors, do nothing
      promise.then(list => {
        if (!cancelled)
          this.updateList(list)
      }).catch(this.loading = false)
      return () => cancelled = true
    },
    updateList(list) {
      this.loading = false
      this.typeAheads = list
      this.showNotInList = false
      if (list.length > 0 && Array.isArray(list)) {
        this.activeIndex = 0
        this.showTypeaheads = true
        this.$nextTick(function () {
          if (this.$refs.searchTypeahead) this.$refs.searchTypeaheads.scrollTop = 0
        })
      }
      else {
        this.showTypeaheads = false
        this.showNotInList = this.searchWord !== ''        
      }
    },
    arrowPress(dir) { //navigate typeahead results with keys        
      if (dir == 'down') {
        if (this.activeIndex < this.typeAheads.length - 1) {
          this.activeIndex++
          if (this.$refs.listItem[this.activeIndex].offsetTop >= 260)
            this.$refs.searchTypeaheads.scrollTop = this.$refs.listItem[this.activeIndex].offsetTop - 290
        }
        else {
          this.activeIndex = 0
          this.$refs.searchTypeaheads.scrollTop = 0
        }
      } else {
        if (this.activeIndex > 0) {
          this.activeIndex--
          if (this.$refs.listItem[this.activeIndex].offsetTop >= 260)
            this.$refs.searchTypeaheads.scrollTop = this.$refs.listItem[this.activeIndex].offsetTop - 250
          else
            this.$refs.searchTypeaheads.scrollTop = this.$refs.listItem[this.activeIndex].offsetTop - 290
        }
        else {
          this.activeIndex = this.typeAheads.length - 1
          this.$refs.searchTypeaheads.scrollTop = this.typeAheads.length * 37
        }
      }
    },
    hideList() {
      this.showTypeaheads = false
    },
    hasNikud(str) {
      return str.length > str.replace(/[^א-ת]/g, '').length
    },
    searchClicked() {
      if (this.searchWord !== '') { //search button clicked
        this.$emit('run-search-clicked', { searchWord: this.searchWord, onList: !this.showNotInList })
      }
    },
    runSearchFromDropDown() {
      if (this.showTypeaheads) { //enter pressed when dropdown open
        this.searchWord = this.typeAheads[this.activeIndex]
        this.$emit('run-search', this.searchWord) //emit call to tool's search api
        this.showTypeaheads = false
      } else {
        this.searchClicked() //search anyway
      }
    },
    setInput(word) { //when a word from list is clicked on       
      this.searchWord = word
      this.showTypeaheads = false
      this.$emit('run-search', this.searchWord)//emit call to tool's search api
    },
    onKeyDownDropdown() {
      if (this.forceHebrew)
        this.englishKeyEventToHebrew(event, this.searchId)
    },
    runTypeAhead() {
      this.$emit('word-typed', this.searchWord)
      this.typeAheads = []
      // this is the function that is called by the typeahead when it wants more words. Probably, the user typed a key.
      if (previousRequestCancellation)
        previousRequestCancellation()
      // call the actual function to get results, but save the cancellation function that it returns
      if (this.searchWord !== '') {
        previousRequestCancellation = this.getUpdatedList(this.searchWord) 
      } else {
        this.showNotInList = false
      }  
    },
    englishKeyEventToHebrew(keyEvt, input) {
      input = document.getElementById(input)
      // if this is a regular English key that could be a Hebrew letter
      if (!(keyEvt.metaKey || keyEvt.shiftKey || keyEvt.ctrlKey || keyEvt.altKey) && /^[A-Za-z;,.]$/.test(keyEvt.key)) {
        keyEvt.preventDefault()
        // someone may be typing in the middle of the input box
        const startpos = input.selectionStart || 0
        const endpos = input.selectionEnd || startpos
        // replace the selection (in the case of a cursor, it's the same as a selection of 0 characters)
        input.value = input.value.substring(0, startpos) + hebrewKeyboard[keyEvt.key.toLowerCase()] + input.value.substring(endpos)
        // put the cursor back where the user is expecting it
        input.setSelectionRange(startpos + 1, startpos + 1)
        // trigger an input event so Vue will update v-model
        input.dispatchEvent(new Event('input', { bubbles: true }))
        // return true in case the caller wants to test to see if this function handled the key
        return true
      }
      return false
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
/*     keymap() {
      return {
        'backspace': () => {
          let inputValue = document.getElementById(this.searchId).value
          if (this.hasNikud(inputValue)) {
            let lastChar = inputValue[inputValue.length - 1]
            if (this.hasNikud(lastChar)) {
              document.getElementById(this.searchId).value = inputValue.slice(0, -1);
            }
          } 
        }
      }
    }, */
  },
  watch: { //If url had search parameter update search input
    searchTerm() {
      this.searchWord = this.searchTerm
    }
  }
}
</script>
<style scoped lang="scss">
.form-control {
  font-size: 25px;
  height: auto;
  line-height: 34px;
  min-height: 30px;
}

.autocomplete-results {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .3);
  z-index: 9;
  border: 1px solid #eeeeee;
  max-height: 330px;
  overflow: auto;
  text-align: right;
  right: 0;
  width: 100%;
  margin-top: 3px;
}

.autocomplete-result {
  cursor: pointer;

  &:focus {
    border: 1px solid red;
  }
}

.autocomplete-result.is-active {
  background-color: #d6ecff !important;

  /* important to override hover color */
  &::after {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 8px;
    left: 10px;
  }
}

.autocomplete-result:hover {
  background-color: #f6f6f6;
}

li {
  font-size: 22px;
}
</style>
<style lang="scss">
/* .word-search-ta {
  .form-group {
    border-radius: 18px;
    padding: 1px 8px;
    height: 35px;
    .form-control {
      line-height: 1.5;
      font-size: 19px;
      border-bottom-right-radius: 20px;
    }
  }
} */
.menukad-search-ta,
.morph-search-ta,
.word-search-ta {
  .form-group {

    .ta-search-btn {
      display: none !important;
    }

    .clear-ta {
      display: inline-block !important;
      font-size: 30px;
      left: 10px;
      top: -2px;
    }
  }
}
.word-search-ta,
.morph-search-ta,
.menukad-search-ta
 {
  .form-control {
    font-size: 18px;
    height: auto;
    line-height: 18px;
  }
  .autocomplete-result {
    font-size: 16px;
    &.is-active {
      &::after {
        top: 4px;
      }
    }
  }
  .not-in-list {
    position: absolute;
    left: 5px;
    bottom: -4px;
  }
}
.menukad-search-ta {
  .form-control {
    line-height: 23px;
  }
}
.typeahead {
  .spinner-wrap {
    width: 30px;
    left: 30px;

    .v-spinner .v-clip {
      width: 25px;
      height: 25px;
      margin-top: 8px;
    }
  }
}
</style>