<template>
    <b-modal id="file-modal" v-model="show" centered size="lg" :ok-title="hebrew ? 'שמירה' : 'Save'"
        :cancel-title="hebrew ? 'ביטול' : 'Cancel'" 
        :title="hebrew ? 'סינון קבצים' + selectedCount: 'Files' + selectedCount "
        header-class="d-flex justify-content-between" body-class="px-0 bg-light" @show="selectSavedFiles"
        @ok="$emit('update-selected-files', selected.map(item=>{return item.filename}))">
        <div class="file-settings">
            <div class="px-3 pb-3">                
                <div class="position-relative document-table overflow-hidden bg-white">
                    <b-table sticky-header="182px" ref="filesTable" class="mb-0" :items="files ? files : []"
                        :fields="fields" :selectable="true" @row-selected="onRowSelected">
                        <template #head(selected)>
                            <template v-if="selected.length === files.length">
                                <i class="fas fa-check-square text-primary" @click="clearSelected"
                                    style="cursor:pointer"></i>
                            </template>
                            <template v-else>
                                <i class="far fa-square" @click="selectAllRows" style="cursor:pointer"></i>
                            </template>
                        </template>
                        <template #cell(selected)="data">
                            <template v-if="selected.indexOf(data.item) > -1">
                                <i class="fas fa-check-square text-primary"></i>
                            </template>
                            <template v-else>
                                <i class="far fa-square"></i>
                            </template>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
//import { Actions } from '@/store/stateChanges'
export default {
    name: 'FileSettingsModal',
    components: {  },
    props: ["value", "savedFiles"],
    data() {
        return {
            fields: [
                { key: 'selected', label: '', sortable: false, tdClass: "select-cb" },
                { key: 'filename', label: 'שם המסמך', },
            ],
            selected: [],
            selectAllClicked: false
        }
    },
    methods: {
        selectSavedFiles() {
            this.$nextTick(() => {
                if (this.savedFiles && this.savedFiles.length > 0) {
                    this.savedFiles.map(item => {
                        let index = this.files.map(function (e) { return e.filename; }).indexOf(item)
                        if (index > -1)
                            this.$refs.filesTable.selectRow(index)
                    })
                } else if(this.$store.state.filters.filter(e => e.filterType === 'files').length === 0) {
                    this.selectAllRows() //no files selected - select all files
                }
            })

        },
        selectAllRows(fromClick) {
            this.selectAllClicked = fromClick
            if (this.$refs.filesTable)
                this.$refs.filesTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.filesTable.clearSelected()
        },
        selectRow(index) {
            if (this.$refs.filesTable)
                this.$refs.filesTable.selectRow(index)
        },
        onRowSelected(items) {
            this.selected = items
        },
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit("input", val)
            },
        },
        hebrew() {
            return this.$settings.hebrew
        },
        files() {
            return this.$store.state.userFiles
        },
        selectedCount() {
            return this.selected.length > 0 ? ' ('+this.selected.length+')' : ''
        }
    }
}    
</script>
<style lang="scss">
.document-table {
    border: 1px solid black;
    border-radius: 8px;

    .table {
        thead th {
            border: none;
        }

        td,
        thead th {
            padding-top: 7px;
            padding-bottom: 7px;
            border-bottom: none;
            font-size: 14px;


            &:first-child {
                width: 35px;
                border-left: 1px solid #979797;
            }

            i {
                font-size: 18px;
            }
        }
    }
}
</style>