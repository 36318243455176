<template>
    <b-modal id="sw-modal" v-model="show" centered size="lg" :ok-title="hebrew ? 'שמירה' : 'Save'"
        :cancel-title="hebrew ? 'ביטול' : 'Cancel'" :title="hebrew ? 'סינון מילים' : 'StopWords'"
        header-class="d-flex justify-content-between" body-class="px-0 pt-3 bg-light" @ok="saveSettings">
        <div class="select-mode mb-3 mx-3">
            <strong>{{ hebrew ? 'מספר מילים להצגה' : 'Number of Stop words' }}:</strong>
            <b-form-radio-group  id="sw-num" v-model="stopWordsNum" :options="numOptions"
                name="radio-sw-options">
            </b-form-radio-group>
        </div>  
        <occurrence-table :wcData="occurrences" :useStopWords="true" :selectedMode="mode" :filteredOccurences="savedItems"
            @selected-rows="(rows) => selected = rows" ></occurrence-table>
    </b-modal>
</template>
<script>
//limit by number of words
//hide single

//choose colors
import OccurrenceTable from '@/components/OccurrenceTable'
export default {
    name: 'StopWordsModal',
    components: { OccurrenceTable },
    props: ["value", "mode", "savedItems"],
    data() {
        return {
            selected: [],
            stopWordsNum: 50,
            numOptions: [50, 100, 150, 200]
        }
    },
    methods: {
        saveSettings(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.$emit('sw-items', this.selected.map(item=>{return item[this.mode]}))    
            this.show = false
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit("input", val)
            },
        },
        hebrew() {
            return this.$settings.hebrew
        },
        allUnfilteredoccurrences() {
            return this.mode === 'menukad' ? this.alloccurrences : this.allLexoccurrences
        },
        alloccurrences() {
            return this.$store.state.unfilteredCountItems
        },
        allLexoccurrences() {
            return this.$store.state.unfilteredLexCountItems
        },
        occurrences() {
            let temp = JSON.parse(JSON.stringify(this.allUnfilteredoccurrences))
            temp = temp.slice(0, this.stopWordsNum)                     
            return temp
        },        
    }

}
</script>
<style lang="scss">
.modal-header {
    .close {
        display: contents;
        font-size: 27px;
    }
}
</style>