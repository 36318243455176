<template>
    <b-nav vertical class="p-0 text-center">
        <b-nav-item to="/" exact exact-active-class="active">
            <span class="text-dark" id="enter-text">
                <i class="fas fa-edit"></i>
                <small class="d-block">{{ this.hebrew ? 'תיבת טקסט' : 'Enter text' }}</small>
            </span>          
        </b-nav-item>
        <b-nav-item to="/upload-analysis" exact exact-active-class="active">
            <span class="text-dark" id="bgu-text">
                <i class="fas fa-file-archive"></i>
                <small class="d-block">{{ this.hebrew ? 'קובץ מנותח' : 'Import results file' }}</small>
            </span>            
        </b-nav-item>
        <b-nav-item to="/library" exact exact-active-class="active" v-if="morphTool">
            <span class="text-dark" id="library">
                <i class="fa-solid fa-book"></i>
                <small class="d-block">{{ this.hebrew ? 'ספריה' : 'Library' }}</small>
            </span>
        </b-nav-item>        
<!--         <b-nav-item to="/upload-zip" exact exact-active-class="active">
            <span class="text-dark" id="zip-text">
                <i class="fas fa-file-archive"></i>
                <small class="d-block">{{ this.hebrew ? 'ייבוא זיפ' : 'Import zip' }}</small>
            </span>
            
        </b-nav-item>
        <b-nav-item to="/import-corpus" exact exact-active-class="active">
            <span class="text-dark" id="import-text">
                <i class="fas fa-book-open"></i>
                <small class="d-block">{{ this.hebrew ? 'ייבוא טקסט' : 'Import text' }}</small>
            </span>
          
        </b-nav-item>
       <b-nav-item to="/upload-file" exact exact-active-class="active">
            <span class="text-dark" id="upload-file">
                <i class="fas fa-file-upload"></i>
                <small class="d-block">{{ this.hebrew ? 'העלאת קובץ BGU' : 'Upload BGU file' }}</small>
            </span>
        </b-nav-item>
        <b-nav-item to="/library" exact exact-active-class="active">
            <span class="text-dark" id="library">
                <i class="fa-solid fa-book"></i>
                <small class="d-block">{{ this.hebrew ? 'חיפוש בספריה' : 'Library Search' }}</small>
            </span>
        </b-nav-item> -->
         <!--    
      
         <b-nav-item to="/analyze-corpus" exact exact-active-class="active">
            <span class="text-dark" id="analyze-corpus">
                <i class="fas fa-book-open"></i>
                <small class="d-block">{{ this.hebrew ? 'ניתותח קורפוס' : 'Analyze Bible' }}</small>
            </span>
          
        </b-nav-item> -->
    </b-nav>
</template>
<script>
export default {
    name: 'NavBar',
    data() {
        return {
            morphTool: process.env.VUE_APP_TOOL_NAME === 'morph-search'
        }
    },
    mounted() { },
    computed: {
        hebrew() {
            return this.$settings.hebrew
        }
    }
}
</script>
<style scoped lang="scss">
.nav {
    min-height: calc(100vh - 120px);

    a {
        padding: 1rem 0.5rem;
    }

    i {
        font-size: 27px;
        margin: 6px 0;
    }

    .active {
        background: white;
        border-right: 5px solid var(--primary);
        .text-dark {
            color: var(--primary) !important;
        }
    }
}
</style>