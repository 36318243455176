<template>
  <div class="set-ngram d-inline-block ml-2">
    <div class="d-flex justify-content-end" :dir="hebrew ? 'rtl' : 'ltr'">
      <small class="">{{ hebrew? 'ביטויים': 'nGrams' }} ({{ ngramSize }})</small>
      <div class="slider-wrap d-inline-block position-relative" style="top:-3px">
        <vue-slider v-model="ngramSize" @drag-end="setSize(ngramSize)" :contained="true" :enable-cross="true"
          :tooltip="'active'" :dragOnClick="true" :min="2" :max="5" :height="9" :dotSize="20" :data="[2, 3, 4, 5]"
          :marks="false">
        </vue-slider>
      </div>

    </div>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { Actions } from '@/store/stateChanges'

export default {
  name: 'nGramSlider',
  components: { VueSlider },
  data() {
    return {
      ngramSize: this.$store.state.ngramSize
    }
  },
  methods: {
    setSize(val) {
      this.$store.commit('SET_NGRAM_SIZE', val)
      this.$store.commit('SET_PAGE_NUMBER', 0)
      this.$store.dispatch(Actions.GET_NGRAMS)
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    }
  },

}
</script>    

<style lang="scss">
.set-ngram {
  .vue-slider-dot-tooltip-top {
    top: -5px;

    .vue-slider-dot-tooltip-inner {
      padding: 0;
      border-radius: 2px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.19);
      border: solid 1px #cfcfcf;
      background-color: #fafafa;
      color: #000;

      &::after {
        display: none;
      }
    }
  }

  .vue-slider-rail {
    background: white;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
  }

  .vue-slider-process {
    // background-color: var(--primary);
    background-color: white;
  }

  .vue-slider-dot-handle {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .25);
  }

  .slider-wrap {
    width: 140px;
  }
}
</style>
