import { StateChanges, BlankState } from './stateChanges'
import { RunStates } from './runStates'
import JSZip from 'jszip'


//import _ from 'lodash'

//import router from '../router'

const basicMutations = {
    [StateChanges.MORPH_RUNNING](state) {
        state.morphApiState = RunStates.RUNNING
    },
    [StateChanges.MORPH_COMPLETE](state) {
        //setTimeout(() => {
        state.morphApiState = RunStates.COMPLETE
    },
    [StateChanges.MORPH_NOT_RUN](state) {
        state.morphApiState = RunStates.NOT_RUN
    },
    [StateChanges.MORPH_FAILED](state) {
        state.morphApiState = RunStates.FAILED
    },
    [StateChanges.MORPH_TIMED_OUT](state) {
        state.morphApiState = RunStates.TIMED_OUT
    },
    [StateChanges.SET_MODE]: function (state, mode) {
        state.selectedMode = mode
    },
    [StateChanges.SET_FILE_TYPE]: function (state, val) {
        state.fileType = val
    },
    [StateChanges.SET_FILE_FOR_API_CALL]: function (state, uploadedFile) {
        if (uploadedFile.fileName) {
            var zip = new JSZip()
            zip.file(uploadedFile.fileName, uploadedFile.fileText)
            zip.generateAsync({ type: "blob" }).then(function (blob) { // 1) generate the zip file
                var file = new File([blob], uploadedFile.fileName+"results.zip")
                state.fileForApi = file
            }, function (err) {
                console.log(err)
            });
        } else {
            state.fileForApi = uploadedFile
        }
    },
    [StateChanges.SET_FREQUENCY_RESULTS]: function (state, data) {
        state.frequencyContent = data
    },
    [StateChanges.SET_READER_RESULTS]: function (state, data) {
        state.readerContent = data
    },
    [StateChanges.SET_SERVER_RESULTS]: function (state, data) {
        state.morphTaggingData = data
    },
    [StateChanges.SET_NGRAM_RESULTS]: function (state, data) {
        state.ngramData = data
    },
    [StateChanges.SET_GROUPED_MORPH_RESULTS]: function (state, data) {
        state.groupedMorphData = data.matches
    },
    [StateChanges.SET_ADJACENT_PREV_RESULTS]: function (state, data) {
        state.adjacentPrevData[state.selectedMode] = data
    },
    [StateChanges.SET_ADJACENT_NEXT_RESULTS]: function (state, data) {
        state.adjacentNextData[state.selectedMode] = data
    },  
    [StateChanges.SET_MORPH_LANG]: function (state, lang) {
        state.morphLang = lang
    }, 
    [StateChanges.SET_MORPH_VALUES]: function (state, morphs) {
        state.morphValues = morphs
    },
    [StateChanges.SET_LEX_COUNT_RESULTS]: function (state, occurences) {
        state.unfilteredLexCountItems = occurences.matches
        state.lexCountItems = occurences.matches
    },
    [StateChanges.SET_ALL_WORD_COUNT_RESULTS]: function (state, occurences) {
        if (state.selectedMode === 'word' || state.selectedMode === 'menukad') {
            state.unfilteredCountItems = occurences.matches
            state.wordCountItems = occurences.matches
            state.totalWordCountItems = occurences.total
        }    
        else {
            if (state.selectedMode === 'lexeme') {
                state.unfilteredLexCountItems = occurences.matches
                state.lexCountItems = occurences.matches
                state.totalLexCountItems = occurences.total
            }
        }
    },
    [StateChanges.SET_COUNT_RESULTS]: function (state, occurences) {
        if (state.selectedMode === 'menukad') {
            state.wordCountItems = occurences.matches
            state.totalWordCountItems = occurences.total
        }    
        else if (state.selectedMode === 'lexeme') {
            state.lexCountItems = occurences.matches
            state.totalLexCountItems = occurences.total
        }    
        else if (state.selectedMode === 'baseword') {
            state.baseWordCountItems = occurences.matches
            state.totalBaseCountItems = occurences.total
        }    
    },
    [StateChanges.SET_ADJACENT_DIRECTION]: function (state, dir) {
        state.adjacentDirection = dir
    },
    [StateChanges.SET_PAGE_NUMBER]: function (state, num) {
        state.offset = num
    },
    [StateChanges.SET_POLLING_PARAMS]: function (state, data) {
        state.invalidFiles = data.invalidFiles
        state.noFiles = data.noFiles
        state.processId = data.processId
        state.uid = data.uid
    },
    [StateChanges.SET_ALL_FILTERS]: function (state, filters) {
        state.filters = filters
        const morphFilter = filters.filter(item => item.filterType === 'morphology')
        if (morphFilter[0] && morphFilter[0].filter.morphLang === 'he') {
            state.morphLang = 'he'
        }
    },

    [StateChanges.SET_NGRAM_SIZE]: function (state, val) {
        state.ngramSize = val
    },
    [StateChanges.SET_USER_FILES]: function (state, fileObj) {
        state.userFiles = fileObj.files
        state.filteredFiles = []
        state.fileWordsAvg = fileObj.wordsAvg
        state.fileWordsTotal = fileObj.wordsTotal
    },
    [StateChanges.SET_FILTERED_FILES]: function (state, fileObj) {  
        state.filteredFiles = fileObj.files
        state.fileWordsAvg = fileObj.wordsAvg
        state.fileWordsTotal = fileObj.wordsTotal
    },    
    [StateChanges.SET_FILE_INDEX]: function (state, newIndex) {
        state.selectedFileIndex = newIndex
    },    
    [StateChanges.SAVE_CLOUD_SETTINGS]: function (state, obj) {
        state.cloudSettings = obj
    },    
    [StateChanges.CLEAR_RESULTS]: function (state) {
        Object.assign(state, JSON.parse(JSON.stringify(BlankState)))
        if (localStorage.getItem('nikud-search-uid')) {
            localStorage.removeItem('nikud-search-uid')
        }
    },

}
export default basicMutations