<template>
  <div class="results pt-4">
    <div class="position-absolute w-100 h-50 text-center" v-if="running">
      <spinner></spinner>
    </div>
    <b-container>
      <!-- <morph-search-settings v-if="morphTool"></morph-search-settings> -->
      <analytics-search-settings @search-values-changed="searchUpdated" :tab="tabIndex"></analytics-search-settings>
    </b-container>

    <!-- <div v-if="morphTool">
      <results-table :tableData="morphTaggingTableItems" :fields="morphTaggingFields"
        :totalResults="morphTaggingTotalResults" @get-data="getServerData(true)"></results-table>
    </div>
 -->
    <div class="border-top mt-5">
      <b-tabs nav-class="container" active-nav-item-class="bg-white border-right-0 border-bottom-0 border-left-0"
        content-class="bg-white pt-4 pb-5" @input="searchUpdated" v-model="tabIndex">
        <b-tab :title="hebrew ? 'מקראה' : 'Reader'">
          <b-container>
            <reader @get-data="getServerData()"></reader>
          </b-container>
        </b-tab>
        <b-tab :title="hebrew ? 'גרפים' : 'Frequencies'" disabled><!-- disabled -->
          <b-container>
            <reader @get-data="getServerData()" :graphTab="true"></reader>
          </b-container>
        </b-tab>
        <b-tab :title="hebrew ? 'שכיחות' : 'Prevalence'">
          <b-container><display-settings></display-settings></b-container>
        </b-tab>
        <b-tab :title="hebrew ? 'הקשרים' : 'Context'">
          <b-container>
            <results-table :tableData="contextTableItems" :fields="contextFields" :totalResults="morphTaggingTotalResults"
              :contextDisplay="true" @get-data="getServerData()">
            </results-table>
          </b-container>
        </b-tab>
        <b-tab title="פירוט מורפולוגי">
          <b-container>
            <results-table :tableData="morphTaggingTableItems" :fields="morphTaggingFields"
              :totalResults="morphTaggingTotalResults" @get-data="getServerData()">
            </results-table>
          </b-container>
        </b-tab>
        <b-tab :title="hebrew ? 'סיכום מורפולוגי' : 'Grouped morphs'">
          <b-container>
            <results-table :tableData="groupedMorphItems" :fields="groupedMorphFields"
              :totalResults="groupedMorphsTotalResults" :groupDisplay="true" @get-data="getServerData()">
            </results-table>
          </b-container>
        </b-tab>
        <b-tab title="ערך קודם">
          <b-container>
            <div class="select-mode mb-3 px-3">
              <strong>צורת מילה:</strong>
              <b-form-group>
                <b-form-radio-group id="radio-mode-ajd-prv" v-model="selectedMode" :options="modeOptions"
                  name="radio-mode-options-adj-prev">
                </b-form-radio-group>
              </b-form-group>
            </div>
            <results-table :tableData="adjacentPrevTableItems" :fields="adjacentPrevFields" :adjacentDisplay="true"
              :totalResults="adjacentPrevTotalResults" @get-data="getServerData()">
            </results-table>
          </b-container>
        </b-tab>
        <b-tab title="ערך הבא">
          <b-container>
            <div class="select-mode mb-3 px-3">
              <strong>צורת מילה:</strong>
              <b-form-group>
                <b-form-radio-group id="radio-mode-ajd-next" v-model="selectedMode" :options="modeOptions"
                  name="radio-mode-options-adj-next">
                </b-form-radio-group>
              </b-form-group>
            </div>
            <results-table :tableData="adjacentNextTableItems" :fields="adjacentNextFields" :adjacentDisplay="true"
              :totalResults="adjacentNextTotalResults" @get-data="getServerData()">
            </results-table>
          </b-container>
        </b-tab>
        <b-tab title="סיכום">
          <Summary></Summary>
        </b-tab>
        <b-tab>
          <template #title>
            <i class="fas fa-info position-relative px-1" style="top:-2px;"></i>
            {{ hebrew ? 'ביטויים נפוצים' : 'nGrams' }}
      </template>
          <b-container>
            <results-table :tableData="ngramTableItems" :fields="nGramFields" :totalResults="nGramTotalResults"
              :nGramsDisplay="true" @get-data="getServerData()">
            </results-table>
          </b-container>
        </b-tab>        
      </b-tabs>
    </div>
  </div>
</template>
<script>


import { Actions } from '@/store/stateChanges'
import Spinner from '@/components/spinner'
import AnalyticsSearchSettings from '@/components/AnalyticsSearchSettings'
//import MorphSearchSettings from '@/components/MorphSearchSettings'
import ResultsTable from '@/components/ResultsTable'
import DisplaySettings from '@/components/DisplaySettings'
import Reader from '@/components/Reader'
import Summary from '@/components/Summary'
import { RunStates } from '@/store/runStates'

export default {
  name: 'Results',
  components: {
    AnalyticsSearchSettings, ResultsTable, DisplaySettings, Reader, Summary, Spinner
  },
  data() {
    return {
      tabIndex: 0,
      selectedMode: 'menukad',
      modeOptions: [
        { text: 'מילה', value: 'menukad' },
        { text: 'לקסמה', value: 'lexeme' },
        { text: 'מילה ללא תחילית', value: 'baseword' }
      ],
      morphTaggingFields: [
        { key: 'menukad', label: 'מילה', stickyColumn: false, isRowHeader: false, tdClass: 'f-narkis word-cell' },
        { key: 'morphs', label: 'מורפולוגיות', stickyColumn: false, isRowHeader: false, thClass: 'morph-th' },
      ],
      adjacentPrevFields: [
        { key: 'pre', label: 'ערך קודם', stickyColumn: false, isRowHeader: false, tdClass: 'f-narkis word-cell' },
        { key: 'count', label: 'שכיחות', stickyColumn: false, isRowHeader: false, tdClass: 'w-25' }
      ],
      adjacentNextFields: [
        { key: 'post', label: 'ערך הבא', stickyColumn: false, isRowHeader: false, tdClass: 'f-narkis word-cell' },
        { key: 'count', label: 'שכיחות', stickyColumn: false, isRowHeader: false, tdClass: 'w-25' }
      ],
      nGramFields: [
        { key: 'expression', label: 'ביטוי', stickyColumn: false, isRowHeader: false, tdClass: 'pr-1 f-narkis' },
        { key: 'count', label: 'שכיחות', stickyColumn: false, isRowHeader: false, tdClass: 'w-50' },
        //{ key: 'length', label: 'אורך', stickyColumn: false, isRowHeader: false },
      ],
      groupedMorphFields: [
        { key: 'count', label: 'שכיחות', stickyColumn: false, isRowHeader: false },
        { key: 'morphs', label: 'מורפולוגיה', stickyColumn: false, isRowHeader: false },
        // { key: 'length', label: 'אורך', stickyColumn: false, isRowHeader: false },
      ],
      contextFields: [
        { key: 'show_details', label: '', thClass: 'border-left-0', tdClass: 'border-left-0 pr-2 plus-icon' },
        { key: 'pre', label: 'לפני', stickyColumn: false, isRowHeader: false, tdClass: 'f-narkis context-field' },
        { key: 'menukad', label: 'מונח', stickyColumn: false, isRowHeader: false, tdClass: 'f-narkis context-field' },
        { key: 'post', label: 'אחרי', stickyColumn: false, isRowHeader: false, tdClass: 'f-narkis context-field' },
        { key: 'source', label: 'מקור', stickyColumn: false, isRowHeader: false, tdClass: 'text-small' },
        // { key: 'length', label: 'אורך', stickyColumn: false, isRowHeader: false },
      ],
      savedMorphFilter: [],
      libraryUser: process.env.VUE_APP_USER_NAME === 'library',
      shasUser: process.env.VUE_APP_USER_NAME === 'shas'
    }
  },
  props: [],
  mounted() {
    this.$nextTick(() => {
      this.loadAnalysisResults()
    })
  },
  methods: {
    loadAnalysisResults() {
      //get all words for stop words and word cloud, get all files, ignore filters
      if (!this.uid && (this.libraryUser || this.shasUser || localStorage.getItem('nikud-search-uid'))) { //Bible Search, or saved uer ID, or user refreshed
        this.$store.commit('SET_POLLING_PARAMS', { uid: this.libraryUser ? 'library' : this.shasUser ? 'shas' : localStorage.getItem('nikud-search-uid') })
      }
      this.$store.dispatch(Actions.GET_OCCURENCES)
      this.$store.dispatch(Actions.GET_MORPH_VALUES)
      this.$store.dispatch(Actions.LIST_FILES)
    },

    searchUpdated() {
      this.$store.commit('SET_PAGE_NUMBER', 0)
      this.getServerData()
    },
    getServerData() {
      switch (this.tabIndex) {
        case 0:
        case 1:  
          this.$store.dispatch(Actions.LIST_FILES)
        //  this.$store.dispatch(Actions.GET_FREQUENCY)
          this.$store.dispatch(Actions.GET_READER)
          break;
        case 2:
          this.$store.dispatch(Actions.GET_OCCURENCES)
          break;
        case 3:
        case 4:
          this.$store.dispatch(Actions.GET_MORPH_TAGGING)
          break;
        case 5:
          this.$store.dispatch(Actions.GET_GROUPED_MORPHS)
          break;
        case 6:
          this.$store.commit('SET_ADJACENT_DIRECTION', 'prev')
          this.$store.dispatch(Actions.GET_ADJACENT)
          break;
        case 7:
          this.$store.commit('SET_ADJACENT_DIRECTION', 'next')
          this.$store.dispatch(Actions.GET_ADJACENT)
          break;
        case 8:
          this.$store.dispatch(Actions.LIST_FILES)
          break;
        case 9:
          this.$store.dispatch(Actions.GET_NGRAMS)
          break;          
      }
    }

  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    groupedMorphServerData() {
      return this.$store.state.groupedMorphData ? this.$store.state.groupedMorphData : null
    },
    groupedMorphItems() {
      let arr = []
      if (this.groupedMorphServerData.length > 0) {
        this.groupedMorphServerData.map(morphItem => {
          const rowObj = {
            morphs: morphItem.morph,
            count: morphItem.count,
            //length: wordItem.words.map(item=>{return item.menukad}).length
          }
          arr.push(rowObj)

        })
      }
      return arr
    },
    adjacentNextServerData() {
      return this.$store.state.adjacentNextData ? this.$store.state.adjacentNextData[this.selectedMode].matches : []
    },
    adjacentNextTableItems() {
      let arr = []
      if (this.adjacentNextServerData) {
        this.adjacentNextServerData.map(item => {
          const rowObj = {
            post: this.selectedMode === 'baseword' ? item['baseWord'] :item[this.selectedMode],
            count: item.count
          }
          arr.push(rowObj)
        })
      }
      return arr
    },
    adjacentPrevServerData() {
      return this.$store.state.adjacentPrevData ? this.$store.state.adjacentPrevData[this.selectedMode].matches : []
    },
    adjacentPrevTableItems() {
      let arr = []

      if (this.adjacentPrevServerData) {
        this.adjacentPrevServerData.map(item => {
          const rowObj = {
            count: item.count,
            pre: this.selectedMode === 'baseword' ? item['baseWord'] :item[this.selectedMode]
          }
          arr.push(rowObj)
        })
      }
      return arr
    },
    ngramServerData() {
      return this.$store.state.ngramData ? this.$store.state.ngramData.matches : []
    },
    ngramTableItems() {
      let arr = []
      if (this.ngramServerData) {
        this.ngramServerData.map(wordItem => {
          const rowObj = {
            _showDetails: false,
            expression: wordItem.words.map(item => { return item.menukad }).join(' '),
            count: wordItem.count
          }
          arr.push(rowObj)
        })
      }
      return arr
    },
    morphTaggingServerData() {
      return this.$store.state.morphTaggingData ? this.$store.state.morphTaggingData.matches : []
    },
    morphTaggingTableItems() {
      //this.loading = true
      let arr = []
      let punctuation = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
      let index = 0
      // const METEG = 'ֽ'
      if (this.morphTaggingServerData) {
        this.morphTaggingServerData.map(item => {
          //let morphArr = Object.keys(item).filter(e => ['base','context','filename','lexeme','menukad','prefix','word','wordNumber','xmlId'].indexOf(e) === -1)
          if ((item.menukad) || (item.sep && item.word.trim().length > 0 && !item.word.match(punctuation) && this.filters.length === 0)) {
            const rowObj = {
              index: index,
              menukad: item.menukad,
              word: item.word,
              lexeme: item?.lexeme,
              baseWord: item?.base,
              sentence: item.context,
              wordNumber: item.wordNumber,
              morphs: item.morph ? item.morph : '',
              fileName: item.filename,
              _showDetails: true
            }
            arr.push(rowObj)
          }
        })
      }
      //this.loading = false
      return arr
    },
    contextTableItems() {
      //this.loading = true
      let arr = []
      let punctuation = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
      let index = 0
      // const METEG = 'ֽ'
      if (this.morphTaggingServerData) {
        this.morphTaggingServerData.map(item => {
          //let morphArr = Object.keys(item).filter(e => ['base','context','filename','lexeme','menukad','prefix','word','wordNumber','xmlId'].indexOf(e) === -1)
          if ((item.menukad) || (item.sep && item.word.trim().length > 0 && !item.word.match(punctuation) && this.filters.length === 0)) {
            const rowObj = {
              index: index,
              menukad: item.menukad,
              word: item.word,
              pre: item.context.substring(0, item.context.indexOf('<b>')),
              post: item.context.substring(item.context.indexOf("</b>") + 4),
              expression: item.context,
              fullExpression: item.contextFull,
              source: item.filename + ', ' + item.wordNumber
            }
            arr.push(rowObj)
          }
        })

      }
      //this.loading = false
      return arr
    },
    filters() {
      return this.$store.state.filters
    },  
    uid() {
      return this.$store.state.uid
    },
    groupedMorphsTotalResults() {
      return this.$store.state.groupedMorphData.length
    },
    morphTaggingTotalResults() {
      return this.$store.state.morphTaggingData.total
    },
    nGramTotalResults() {
      return this.$store.state.ngramData.total
    },
    adjacentPrevTotalResults() {
      return this.$store.state.adjacentPrevData[this.selectedMode] ? this.$store.state.adjacentPrevData[this.selectedMode].total : 0
    },
    adjacentNextTotalResults() {
      return this.$store.state.adjacentNextData[this.selectedMode] ? this.$store.state.adjacentNextData[this.selectedMode].total : 0
    },
    files() {
      return this.$store.state.userFiles
    },
    running() {
      return this.$store.state.morphApiState === RunStates.RUNNING
    }

  },
  watch: {
    files(data) {
      if (data.length)
        setTimeout(() => {
          if (this.$route.query.search) { // reload page with saved search params, filter data
            let filterParams = JSON.parse(decodeURIComponent(this.$route.query.search))
            if (filterParams.length > 0 && this.filters.length === 0) {
              this.$store.commit('SET_ALL_FILTERS', filterParams)
            }
          }
          if (this.$route.query.tab) {
            this.tabIndex = JSON.parse(decodeURIComponent(this.$route.query.tab))
          }
          this.getServerData()          
        }, 150)
    }
  }
}
</script>  
<style lang="scss">
.filter-results {
  .tab-content {
    //height: 50vh;
  } 
  .nav-tabs {
    border-bottom: none;
    
    .nav-link {
      color: black;
      font-size: 18px;
      &.disabled {
        display: none;
      }
      &.active {
        border-color: var(--primary);
        border-width: 3px;
        color: black;
      }
    }
  }
}
</style>
