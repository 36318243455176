import Vue from 'vue'
import Vuex from 'vuex'
import basicMutations from './store/basicMutations'
import { BlankState } from './store/stateChanges'
import searchActions from './store/searchActions'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: JSON.parse(JSON.stringify(BlankState)),
  mutations: Object.assign(
    { ...basicMutations },
  ),
  actions: Object.assign(
    { ...searchActions },

  )

})



export default store
