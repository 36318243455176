import backendRedirect from '../../backendRedirect'
const SOUNDPLAY_SERVER = backendRedirect.spApiTrafficManager + '/tipsoundplay'

const serverRoot = '/api'
const serverUrl = backendRedirect.apiTrafficManager
const nakdanServerUrl = backendRedirect.nakdanApiTrafficManager

// const serverUrl = serverRoot
//const visualizerUrl = '/visualize'


export const StoreConfig = {
  serverRoot: serverRoot,
  serverUrl: serverUrl,
  NAKDAN_SERVER: nakdanServerUrl,
  SOUNDPLAY_SERVER: SOUNDPLAY_SERVER,
}
