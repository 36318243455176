
function arrayToCsv(data){
    return data.map(row =>
      row
      .map(String)  // convert every value to String
      .map(v => v.replaceAll('"', '""'))  // escape double colons
      .map(v => `"${v}"`)  // quote it
      .join(',')  // comma-separated
    ).join('\r\n')  // rows starting on new lines
  }
  
  export function downloadBlob(content ) {
    var BOM = "\uFEFF"; 

    let filename = 'export'+ (new Date()).getTime() + '.csv'
    let contentType = 'text/csv;charset=utf-8;'
    let csv = BOM + arrayToCsv(content)
    // Create a blob
    var blob = new Blob([csv], { type: contentType })
    var url = URL.createObjectURL(blob);
  
    // Create a link to download it
    var pom = document.createElement('a')
    pom.href = url;
    pom.setAttribute('download', filename)
    pom.click();
  }
  