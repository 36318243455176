import openXml from 'openxml'
import base64js from 'base64-js'

let READABLE_TYPES = {
    text: 'text/plain',
    json: 'application/json',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    png: 'image/png',
    zip: 'application/x-zip-compressed',
    csv: 'text/csv'
}

const READABLE_TYPE_NAMES = new Map(Object.keys(READABLE_TYPES).map(key => [READABLE_TYPES[key], key]))

// extend with synonyms
Object.assign(READABLE_TYPES, {
    txt: 'text/plain'
})

export function loadWordDocFromArrayBuffer(contents) {
    const doc = new openXml.OpenXmlPackage(contents)
    const encoded = base64js.fromByteArray(new Uint8Array(contents))
    // console.log('encoded:')
    // console.log(encoded)
    const source = {
        fileBase64Contents: encoded,
        type: 'docx',
        runArray: doc.mainDocumentPart().getXDocument().descendants(openXml.W.r).toArray().map(o => o.value),
        wordToRunAndPosMap: []
    }
    const docText = doc.mainDocumentPart().getXDocument().descendants(openXml.W.p).toJoinedString('\n', x => x.value)
    return { source: source, text: docText }
}



export default async function ({ file, name, type }) {

    const f = file
    let ftype = type
    if (f && !ftype) {
        // if system does not identify a "type", try to use last "part" of file name
        const fnameParts = name.split('.')
        const fnameLastPart = fnameParts[fnameParts.length - 1]
        if (Object.keys(READABLE_TYPES).includes(fnameLastPart)) {
            ftype = READABLE_TYPES[fnameLastPart]
        }
    }
    // if there is a file available and it is a type we support
    if (f && (Object.values(READABLE_TYPES).includes(ftype))) {
        // File or Blob objects read files asynchronously. It returns a promise that is resolved when the file is read.
        // arrayBuffer is used for Word files
        const reader = (ftype === READABLE_TYPES.text || ftype === READABLE_TYPES.json) ? f.text() : f.arrayBuffer()

        return reader.then(contents => {
            let docText, source
            if (ftype === READABLE_TYPES.docx) {
                // console.log('binary contents:')
                // console.log(contents)
                const results = loadWordDocFromArrayBuffer(contents)
                docText = results.text
                source = results.source
            }
            else {
                docText = contents
                source = {
                    fileBase64Contents: null,
                    type: READABLE_TYPE_NAMES.get(ftype),
                    runArray: [],
                    wordToRunAndPosMap: []
                }
            }
            return { source: source, docText: docText }
        })
    } else {
        alert("Failed to load file")
        return Promise.reject('Failed to load file.')
    }
}