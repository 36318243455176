<template>
    <b-container class="files-summary">
        <h5 class="summary-heading mb-3" style="font-size: 18px;">
            {{ hebrew ? 'סיכום מסמכים' : 'Files summary' }} <span v-if="filteredFiles.length > 0">({{ filteredFiles.length
            }}/{{ allUserFiles.length }})</span>
        </h5>
        <div class="summary-text p-3">
            <p class="mb-2">
                <strong>{{ hebrew ? 'מסמכים שנכללו' : 'Included files' }} ({{ filteredFiles.length > 0 ? filteredFiles.length : allUserFiles.length }}):</strong>
                {{ fileNames }}
            </p>
            <p class="mb-2">
                <strong>{{ hebrew ? 'מספר מילים סך הכל' : 'Sum of words' }}:</strong>
                {{ wordSum }}
            </p>
            <p class="mb-2">
                <strong>{{ hebrew ? 'מספר מילים ממוצע למסמך' : 'Average number of words' }}:</strong>
                {{ wordAvg }}
            </p>
            <p class="mb-2">
                <strong>{{ hebrew ? 'מילים נפוצות' : 'Common words' }}: </strong>
                <span v-for="(item, index) in commonWords" :key="index">
                    <span style="cursor: pointer;" @click="itemClickHandler(item.menukad)" 
                    class="text-primary text-underline" v-html="item.menukad"></span>
                    ({{ item.count }})<span v-if="index < commonWords.length - 1">, </span>
                </span>
            </p>
            <small v-if="filesNotIncluded.length > 0">
                **{{ hebrew ? 'מסמכים שלא נכללו' : 'Files not searched' }}:
                {{ filesNotIncluded }}
            </small>
        </div>
    </b-container>
</template>
<script>
function getDifference(array1, array2) {
    return array1.filter(object1 => {
        return !array2.some(object2 => {
            return object1.filename === object2.filename;
        })
    })
}
export default {
    name: 'Summary',
    props: [],
    data() {
        return {

        }
    },
    methods: {
        itemClickHandler(word) {
            let nikudRegex = /[\u0591-\u05BD\u05BF-\u05C2\u05C4\u05C5\u05C7]/g;

            let newParams = [{
                'filterType': 'word',
                'filter': [word.replace(/"/g, '&quot;').replace(nikudRegex, '')]
            }]
            let newUrl = encodeURIComponent(JSON.stringify(newParams))
            window.open(window.location.origin + '/filter-results?search=' + newUrl, '_blank');
        },
    },
    computed: {
        hebrew() {
            return this.$settings.hebrew
        },
        allUserFiles() {
            return this.$store.state.userFiles
        },
        filteredFiles() {
            return this.$store.state.filteredFiles
        },
        fileList() {
            return this.filteredFiles.length > 0 ? this.filteredFiles : this.allUserFiles
        },
        fileNames() {
            return this.fileList.map(item => item.filename).join(", ")
        },
        filesNotIncluded() {
            return this.filteredFiles.length > 0 ? getDifference(this.allUserFiles, this.filteredFiles).map(item => item.filename).join(", ") : ''
        },
        wordSum() {
            return this.$store.state.fileWordsTotal
        },
        wordAvg() {
            return Math.floor(this.$store.state.fileWordsAvg)
        },
        commonWords() {
            return this.$store.state.wordCountItems.slice(0, 3)
        }
    }
}
</script>
<style scoped lang="scss">
.summary-text {
    border-radius: 6px;
    border: solid 1px #d8d8d8;
    min-height: 300px;
}
</style>