<template>
  <div class="filter-results">
    <toggle-header class="mb-n3"/>
    <results></results>
    <canvas id="canvas" width="954" height="370" class="d-none"></canvas>
  </div>
</template>
<script>
import Results from '@/components/Results'
import ToggleHeader from "@/components/toggleHeader"
//import _ from 'lodash'
export default {
  name: 'FilterResults',
  components: { Results, ToggleHeader },
  data() {
    return {
    }
  },
  methods: {

  },
  computed: {
 
  }
}
</script> 
<style lang="scss">

</style>