<template>
  <div class="morph-filter">
    <div ref="filterWrap" class="search-input-wrap position-relative border border-dark bg-white d-flex"
      @click="setFocus">
      <span class="position-absolute settings-btn " v-if="showMorphSettings" @click="$emit('morph-settings-clicked')">
        <i class="fas fa-cog"></i>
      </span>     
      <span class="position-absolute upload-btn" v-if="showUploadBtn">
        <text-editor :hideTextArea="true" :filterFile="true" :fileID="'file-'+filterID"  @file-text="(text) => filterTextFromFile(text)"></text-editor>
      </span>
      <b-btn class="clear-btn position-absolute bg-transparent border-0 shadow-none text-muted px-3"
        :class="{ 'upload-btn-visible': showUploadBtn || showMorphSettings }" v-if="morphSelectedTerms.length > 0 || searchTerm !== ''"
        @click="clearList">&times;
      </b-btn>
      <ul class="d-flex flex-wrap w-auto p-0 m-0" id="term-list" style="max-width: 725px;">
        <li class="d-flex w-auto rounded-pill px-2 my-1 mr-1 bg-secondary" v-for="item in morphSelectedTerms" :key="item">
          <small v-html="item"></small>
          <span @click="removeItem(item)" class="pr-2">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </li>
        <div class="overflow-hidden" :class="{ 'mr-2': !filtered || filtered.length === 0 }"
          style="vertical-align: middle;border-radius: 18px;">
          <MainSearch :taOptions="morphOptions" :searchId="filterID" :menukadSearch="menukadSearch" :savedSearchWord="searchTerm"
            :forceHebrew="forceHebrew" :lexSearch="lexSearch" @word-typed="(w) => searchTerm = w"
            @word-selected="(w) => addMorphTerm(w)" @backspace="removeLastItem" @focus-in="inputClicked" @focus-out="onFocusOut" />
        </div>
      </ul>

    </div>
  </div>
</template>
<script>
import MainSearch from '@/components/MainSearch'
import morphologyMixins from '@/mixins/morphologyMixins'
import TextEditor from '@/components/TextEditor'

export default {
  name: "morph-filter",
  mixins: [morphologyMixins],
  components: {
    MainSearch, TextEditor
  },
  props: ['filtered', 'morphOptions', 'forceHebrew', 'filterID', 'menukadSearch', 'lexSearch', 'showUploadBtn', 'showMorphSettings'],
  data() {
    return {
      morphSelectedTerms: [],
      searchTerm: ''
    }
  },
  mounted() {
    if (this.filtered) {
      this.morphSelectedTerms = this.filtered
    }
  },
  methods: {
    setFocus() {
      document.getElementById(this.filterID).focus()
      this.inputClicked()
    },
    filterTextFromFile(txt) {
      let textValues = txt.split(/[\r\s\n,]/).filter(item=>item.trim().length > 0)           
      textValues = textValues.concat(this.morphSelectedTerms)
      this.$emit('toFilter', [...new Set(textValues)])
    },
    onFocusOut() {
      if (this.$refs.filterWrap) {
        this.$refs.filterWrap.classList.add('border-dark')
        this.$refs.filterWrap.classList.remove('border-primary')
      }
    },
    inputClicked() {
      if (this.$refs.filterWrap) {
        this.$refs.filterWrap.classList.remove('border-dark')
        this.$refs.filterWrap.classList.add('border-primary')
      }
    },
    addMorphTerm(selected) {
      if (this.morphOptions && this.morphSelectedTerms.indexOf(selected) < 0 && this.morphOptions.indexOf(selected) > -1) {
        this.morphSelectedTerms.push(selected)
        this.$emit('toFilter', this.morphSelectedTerms)
      }
      else if (this.morphSelectedTerms.indexOf(selected) < 0) {
        this.$emit('addFilterItem', selected)
      }
      setTimeout(() => {
        document.getElementById(this.filterID).value = ''
        this.searchTerm = ''
      }, 10)

    },
    removeItem(item) {
      var index = this.morphSelectedTerms.indexOf(item)
      this.morphSelectedTerms.splice(index, 1)
      this.$emit('toFilter', this.morphSelectedTerms)
    },
    removeLastItem() {
      if (this.searchTerm == '' && this.morphSelectedTerms.length > 0)
        this.morphSelectedTerms.pop()
      this.$emit('toFilter', this.morphSelectedTerms)
    },
    clearList() {
      setTimeout(() => {
        document.getElementById(this.filterID).value = ''
        this.searchTerm = ''
      }, 10)
      if (this.morphSelectedTerms.length > 0) {
        this.morphSelectedTerms = []
        this.$emit('toFilter', this.morphSelectedTerms)
      }
    }
  },
  computed: {
/*     keymap() {
      var keymapObj = {
        'backspace': () => {
          this.removeLastItem()
        }
      }
      return keymapObj
    } */
  },
  watch: {
    filtered(val) {
      if (val.length === 0)
        this.morphSelectedTerms = []
      else
        this.morphSelectedTerms = val
    }

  }
}
</script>

<style lang="scss">
.search-input-wrap {
  cursor: text;
  //height: 35px;
  border-radius: 18px;
  padding: 1px 3px;
  max-width: 790px;


  li {
    //cursor: auto !important;
    small {
      line-height: 23px;
    }
  }
}

.morph-filter {

  i {
    cursor: pointer
  }
}

.settings-btn,
.upload-btn {
  left: 3px;
  height: 20px;
  margin-right: 3px;
  top: 6px;
  width: 30px;

  i {
    padding: 0 6px;
    border-right: solid 1px #979797;
  }
}

.clear-btn {
  left: 0;
  &.upload-btn-visible {
    left: 22px;
  }

  font-size: 30px;
  line-height: 26px;
  top:-1px;
}
</style>
