<template>
    <div class="search-settings">
        <div class="mb-2 toggle-settings">
            <b-form-checkbox v-model="showSearchSettings" :indeterminate="!showSearchSettings">
                <span>{{ hebrew ? 'הגדרות חיפוש' : 'Search settings' }} ({{ filterArr.length }})</span>
            </b-form-checkbox>
        </div>
        <div v-if="showSearchSettings">
            <div v-for="(filterItem, index) in filterArr" :key="index"
                class="d-flex align-items-top mb-3 position-relative search-item">
                <i class="fas fa-minus-circle text-secondary d-flex align-items-center position-relative"
                    style="font-size: 20px;cursor:pointer; right:-27px;" @click="removeFilterItem(index)"></i>

                <div class="pt-3 mr-n3">
                    <dicta-dropdown style="width: 90px;" class="mx-3" :class="{'disabled':filterItem.filterType==='files'}"
                        :value="filterItem.negate ? filterActions[filterActions.map(e => e.value).indexOf(filterItem.negate)] : filterActions[0]"
                        :options="filterActions" @change="filterValue => filterItem.negate = filterValue.value"
                        display-field="display" />

                    <dicta-dropdown @change="filterValue => updateFilterType(filterValue.value, index)"
                        display-field="display"
                        :value="filterOptions[filterOptions.map(e => e.value).indexOf(filterItem.filterType)]"
                        :options="filterOptions" class="d-dropdown ml-3" style="width: 140px;">
                    </dicta-dropdown>
                </div>
                <div class="flex-grow-1 bg-white px-3 fiter-wrap-item">

                    <morph-filter :filterID="'word-search' + index"
                        v-if="filterItem.filterType === 'word' || filterItem.filterType === 'menukad' || filterItem.filterType === 'lexeme'"
                        :filtered="filterItem.filter" :menukadSearch="filterItem.filterType === 'menukad'"
                        :lexSearch="filterItem.filterType === 'lexeme'" :forceHebrew="true" :showUploadBtn="true"
                        @toFilter="(arr) => filterItem.filter = arr"
                        @addFilterItem="(item) => filterItem.filter.push(item)">
                    </morph-filter>
                    <div v-if="filterItem.filterType === 'morphology'">
                        <morph-filter :filterID="'morph-search' + index" :morphOptions="filterMorphOptions"
                            :filtered="filterItem.filter.morphs" 
                            :showMorphSettings="!libraryUser && !corpusUser"
                            @toFilter="(arr) => filterItem.filter.morphs = arr"
                            @morph-settings-clicked="showMorphSettings=true"
                            >
                        </morph-filter>
                        <div class="d-flex mt-2">
                            <b-form-checkbox v-model="filterItem.filter.mode" value="partial" unchecked-value="any" name="check-button" switch>
                                <small class="">כלול את הפריטים באותה מחרוזת מורפולוגית</small>
                            </b-form-checkbox>
                            <b-form-checkbox class="mr-3" v-model="filterItem.filter.mode" value="exact" unchecked-value="any" name="check-button" switch >
                                <small class="">מורפולוגיה מדוייקת</small>
                            </b-form-checkbox>
                        </div>                    
                        <b-modal  id="morph-lang-modal" v-model="showMorphSettings" centered size="md" title="הגדרות" body-class="px-3" hide-footer>
                            <div class="font-weight-bold">
                                השפה בה יוצג הפריט המורפולוגי
                            </div>
                            <b-form-radio-group  id="morph-lang" class="mx-n3 my-3" v-model=" filterItem.filter.morphLang" :options="morphLangOptions" name="morph-lang-options" @change="switchMorphLanguage(filterItem.filter.morphLang)"></b-form-radio-group>                           
                        </b-modal>
                        <!-- filterItem.filter.morphLang -->
                    </div>
                    <div class="position-relative" v-if="filterItem.filterType === 'pattern'">

                        <template>
                            <div class="pattern-actions position-absolute mt-1"
                                style="left:0;border-right: 1px solid #979797;">
                                <b-btn @click="showPatternPopup = true" variant="link" size="lg"
                                    class="px-2 bg-transparent shadow-none d-block border-0">
                                    <i class="fas fa-cog"></i>
                                </b-btn>
                                <b-btn class="shadow-none py-0 px-2 bg-transparent d-block" variant="link"
                                    :disabled="filterItem.filter.trim().length < 1" style="font-size:30px;line-height: 1;"
                                    @click="clearFilter(index)">&times;</b-btn>
                            </div>
                            <search-pattern :savedTokens="filterItem.tokens" :readOnly="true"></search-pattern>
                        </template>
                        <search-pattern-modal v-if="showPatternPopup" v-model="showPatternPopup"
                            @search-object="(obj) => { filterItem.filter = buildRegex(obj.patternObj); filterItem.tokens = obj.tokens }"
                            @clear-pattern="clearFilter(index)" :savedTokens="filterItem.tokens">
                        </search-pattern-modal>

                    </div>
                    <div class="position-relative" v-if="filterItem.filterType === 'files'">
                        <file-settings-modal v-model="showFilesPopup"
                            @update-selected-files="(arr) => filterItem.filter = arr" :savedFiles="filterItem.filter">
                        </file-settings-modal>
                        <b-form-tags @click="showFilesPopup = true" tag-pills no-outer-focus size="sm" placeholder=""
                            v-model="filterItem.filter">
                        </b-form-tags>
                    </div>

                    <div class="position-relative" v-if="filterItem.filterType === 'stopMenukad'">

                        <StopWordsModal v-model="showStopWordsPopup" :mode="'menukad'" :savedItems="filterItem.filter"
                            @sw-items="(arr) => filterItem.filter = arr"></StopWordsModal>
                        <b-form-tags @click="showStopWordsPopup = true" tag-pills no-outer-focus size="sm" placeholder=""
                            v-model="filterItem.filter">
                        </b-form-tags>
                    </div>

                    <div class="position-relative" v-if="filterItem.filterType === 'stopLexeme'">

                        <StopWordsModal v-model="showLexPopup" :mode="'lexeme'" :savedItems="filterItem.filter"
                            @sw-items="(arr) => filterItem.filter = arr"></StopWordsModal>
                        <b-form-tags @click="showLexPopup = true" tag-pills no-outer-focus size="sm" placeholder=""
                            v-model="filterItem.filter">
                        </b-form-tags>
                    </div>

                    <b-btn
                        v-if="filterItem.filter.length > 0 && (filterItem.filterType === 'files' || filterItem.filterType === 'stopMenukad' || filterItem.filterType === 'stopLexeme')"
                        class="shadow-none p-0 bg-transparent position-absolute text-muted" variant="link"
                        style="left:32px;top:17px;font-size:30px;line-height: 0;z-index: 1;"
                        @click="clearFilter(index)">&times;</b-btn>

                </div>
            </div>
            <div class="d-flex justify-content-between">
                <span style="cursor: pointer;" class="text-primary" @click="addLocalFilterItem">
                    <i class="fas fa-plus" style="font-size: 12px;"></i>
                    {{ hebrew ? 'הוספת שורת חיפוש' : 'Add search item' }}
                </span>
                <div v-if="filterArr.length > 0">
                    <b-btn variant="link" size="lg" class="text-underline text-primary bg-transparent px-3"
                        @click="clearAllFilters">
                        {{ hebrew ?
                            'איפוס חיפוש' : 'Clear' }}
                    </b-btn>
                    <b-btn variant="primary" size="lg" class="" @click="getFilteredData">{{ hebrew ?
                        'חיפוש' : 'Search' }}
                    </b-btn>
                </div>
            </div>
        </div>
        <div v-if="filterSummary.length > 0" class="filter-summary mt-3 py-1 px-3 border border-secondary" v-html="filterSummary"></div>
    </div>
</template>
<script>
import SearchPatternModal from '@/components/SearchPatternModal'
import StopWordsModal from '@/components/StopWordsModal'
import SearchPattern from '@/components/SearchPattern'
import MorphFilter from '@/components/MorphFilter'
import FileSettingsModal from '@/components/FileSettingsModal'
import { buildRegex } from '@/js/buildRegex'
//import { Actions } from '@/store/stateChanges'
export default {
    name: 'AnalyticsSearchSettings',
    components: { SearchPatternModal, SearchPattern, MorphFilter, FileSettingsModal, StopWordsModal },
    props: ['tab'],
    data() {
        return {
            filterArr: [],
            filterOptions: [{
                display: 'מילה',
                value: 'word'
            },
            {
                display: 'מילה מנוקדת',
                value: 'menukad'
            },
            {
                display: 'לקסמה',
                value: 'lexeme'
            },
            {
                display: 'מורפולוגיה',
                value: 'morphology'
            },
            {
                display: 'תבנית ניקוד',
                value: 'pattern'
            },
            /* {
                display: 'Stop Word',
                value: 'stopMenukad'
            },

            {
                display: 'Stop Lexeme',
                value: 'stopLexeme'
            }, */
            {
                display: 'מסמך',
                value: 'files'
            }
            ],
            filterActions: [{
                display: 'AND',
                value: false
            },
            {
                display: 'NOT',
                value: true
            }],
            morphLangOptions: [
                { text: 'אנגלית', value: 'en' },
                { text: 'עברית', value: 'he' }
            ],
            showSearchSettings: false,
            showPatternPopup: false,
            showFilesPopup: false,
            showStopWordsPopup: false,
            showLexPopup: false,
            showMorphSettings: false,
            libraryUser: process.env.VUE_APP_USER_NAME === 'library' || process.env.VUE_APP_USER_NAME === 'shas'
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.search)
                this.loadResults()
        })

    },
    methods: {
        switchMorphLanguage(lang) {
            let orgLang = lang === 'he' ? 'en' : 'he'
            this.$store.commit('SET_MORPH_LANG', lang)
            this.filterArr.map(obj => {
                if (obj.filterType === 'morphology') {
                    obj.filter.morphs.map((morph, index) => {     
                        let morphIndex = this.morphOptions.findIndex(item => item[orgLang] === morph)
                        if (morphIndex > -1)
                            obj.filter.morphs[index] = this.morphOptions[morphIndex][lang]
                    })
                }
            })
            
        },
        loadResults() {
            let filterParams = JSON.parse(decodeURIComponent(this.$route.query.search))
            if (filterParams.length > 0) {
                this.filterArr = filterParams
                this.showSearchSettings = true
            }
        },
        buildRegex(obj) {
            return buildRegex(obj)
        },
        removeFilterItem(index) {
            this.filterArr.splice(index, 1)
            if (this.filterArr.length === 0)
                this.getFilteredData()
        },
        updateFilterType(val, index) {
            this.filterArr[index].filterType = val
            if (val === 'files') {
                this.filterArr[index].negate = false
            }
            if (val === 'morphology') {
                this.filterArr[index].filter = {
                    morphs: [],
                    mode: 'partial',
                    morphLang: JSON.parse(JSON.stringify(this.$store.state.morphLang))
                }
            }
            else if (val === 'pattern') {
                this.filterArr[index].filter = ''
                this.filterArr[index].tokens = []
            }
            else {
                this.filterArr[index].filter = []
            }


        },
        addLocalFilterItem() {
            this.filterArr.push({
                filterType: 'word',
                negate: false,
                filter: []
            })
        },
        clearFilter(index) {
            this.filterArr[index].filter = []
        },
        clearAllFilters() {
            this.filterArr = []
            this.getFilteredData()
        },
        getFilteredData() {
            //clear empty filter items
            this.filterArr = this.filterArr.filter(el => ((el.filterType === 'morphology' && el.filter.morphs.length > 0) || Array.isArray(el.filter) && el.filter.length > 0) || (typeof el.filter === 'string' && el.filter.trim().length > 0))
            //merge filter values
            this.filterArr = Object.values(this.filterArr.reduce((acc, curr) => {
                const key = curr.filterType + curr.negate;
                if (acc[key]) {
                    acc[key].filter = acc[key].filter.concat(curr.filter)
                    acc[key].filter = [...new Set(acc[key].filter)]
                } else {
                    acc[key] = { ...curr };
                }
                return acc;
            }, {}));
            this.$store.commit('SET_ALL_FILTERS', JSON.parse(JSON.stringify(this.filterArr)))
            let filterParam = encodeURIComponent(JSON.stringify([...this.filterArr]))
            this.$router.replace({ query: { search: filterParam, tab: this.tab } })
            this.$emit('search-values-changed')
        }
    },
    computed: {
        hebrew() {
            return this.$settings.hebrew
        },
        corpusUser() {
            return this.$store.state.uid === 'library' || this.$store.state.uid === 'shas'
        },
        filterSummary() {
            if (this.$store.state.filters.length > 0) {
                let filterVals = this.$store.state.filters.map(item => {
                    let summaryText = item.filterType === 'pattern' ? 'תבנית מילה' : item.filterType === 'morphology' ? item.filter.morphs.join(", ") : Array.isArray(item.filter) ? item.filter.join(", ") : item.filter
                    return item.negate ? '!' + summaryText : '' + summaryText
                })
                return filterVals.join(', ')
            } return ''
        },
        morphLanguage() {
            return this.$store.state.morphLang  
        },
        morphOptions() {
            return this.$store.state.morphValues
        },
        filterMorphOptions() {
            return this.morphOptions.length > 0 ? this.morphOptions.map(el =>  {return el[this.morphLanguage]}) : []
        }
    }
}
</script>
<style lang="scss">
.search-settings {
    .toggle-settings {
        .custom-control-label {
            font-size: 19px;
            width: 100%;

            span {
                font-size: 19px;
                line-height: 1;
                position: relative;
                top: -2px;
            }
        }

        .custom-control-input:checked~.custom-control-label:before,
        .custom-control-input:not(:disabled):active~.custom-control-label:before,
        .custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
            border-color: #000;
            background-color: #000;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .custom-checkbox .custom-control-input:checked~.custom-control-label:after {
            color: white;
            content: "\f067";
            font-weight: 900;
            font-family: "Font Awesome 5 Free";
            background-image: none;
            position: absolute;
            top: 4px;
            right: -21px;
            font-size: 12px;
        }
    }

    .search-item {
        border-radius: 8px;
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #aaa;

        .fiter-wrap-item {
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
            padding: 17px 0;
        }
    }

    .b-form-tags-input {
        cursor: default;
    }

    .b-form-tags-list {
        padding-right: 0;
        max-width: 725px;
    }

    .b-form-tags {
        border-radius: 18px;
        min-height: 36px;
    }

    .btn.b-form-tags-button,
    .b-form-tag>button.b-form-tag-remove {
        height: 17px;
        opacity: 1 !important;
        font-size: 23px;
        line-height: 17px;
        font-weight: 100;
        margin: 0 3px 0 0;
        text-shadow: none;
    }

    .form-control-sm .b-form-tag {
        font-size: 14px;
        line-height: 1;
        margin-top: 5.2px;
        margin-bottom: 1.2px;
        margin-right: 0;
        margin-left: 4px;
        align-items: center !important;


        .text-truncate {
            display: inline-block;
            line-height: 17px;
        }
    }

    .dicta-dropdown {
        &.disabled {
            opacity: 0.6;
            pointer-events: none;
            button {
                background-color: #cecccc;
            }
        }
        button {
            &.dropdown-toggle {
                border-radius: 18px;
            }
        }
    }

    .filter-summary {
        border-radius: 6px;
        background-color: #FEFDEC;
    }
}
</style>