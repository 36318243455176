<template>
    <div class="upload-zip h-100">
        <zip-files :bgu="true"></zip-files>
    </div>
</template>
<script>
import ZipFiles from '@/components/ZipFiles.vue'
import { RunStates } from '@/store/runStates'
export default {
    name: 'UploadAnalysis',
    components: {ZipFiles},
    data () {
        return {
        }
    },

    computed: {
        hebrew () {
            return this.$settings.hebrew
        },
        running() {
            return this.$store.state.morphApiState === RunStates.RUNNING
        }        
    }
  
}
</script>      