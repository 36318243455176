import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import dictaVueComponents from 'dicta-vue-components'
import VueHotkey from 'v-hotkey'
//import 'mutationobserver-shim'


Vue.config.productionTip = false
Vue.use(dictaVueComponents, { hebrew: true, useBodyClass: true })
Vue.use(VueHotkey)

//dropboxInitialize(store)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
