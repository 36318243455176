<template>
  <div class="enter-text" :class="{ 'p-3': !hideTextArea }">
    <div class="position-absolute h-100 w-100" v-if="running && !filterFile">
      <spinner></spinner>
    </div>
    <form ref="fileForm" v-if="hideTextArea">
      <label v-if="!filterFile" for="main-file-input" class="bottom-button btn btn-secondary">
        <i-file></i-file> {{ hebrew ? 'העלה קובץ' : 'Upload file' }}
      </label>
      <label v-else :for="fileID"><i-upload></i-upload></label>
      <input type="file" accept=".txt, .docx" name="name" :id="filterFile ? fileID : 'main-file-input'" class="inputfile inputfile-2 d-none"
        ref="mainInputEl" @change="loadInputtedFile">
    </form>
    <div v-else class="text-editor position-relative border rounded border-dark': !hideTextArea" ref="textAreaWrap"
      v-hotkey="keymap">
      <div class="erase position-absolute p-2" id="del-btn" v-if="nikudText && nikudText !== ''" @click="clearText">
        <span aria-hidden="true">&times;</span>
      </div>
      <textarea ref="textArea" id="textEntryArea" @focus="focusedEvent" @focusout="focusedOutEvent" dir="rtl"
        class="f-narkis w-100 p-2 pl-5 rounded text-right border-0"
        :placeholder="hebrew ? 'הזינו טקסט כאן' : 'Enter Hebrew text'" v-model="nikudText"></textarea>
      <div class="position-absolute d-flex justify-content-between w-100 p-3 action-btns">
        <form ref="fileForm" class="d-flex align-items-end">
          <label for="main-file-input" class="bottom-button load-button text-underline mb-0">
            {{ hebrew ? 'העלה קובץ' : 'Upload file' }}
          </label>
          <input type="file" accept=".txt, .docx" name="name" id="main-file-input" class="inputfile inputfile-2 d-none" ref="mainInputEl"
            @change="loadInputtedFile">
        </form>
        <button :disabled="nikudText === ''" class="run-btn btn btn-primary d-block" @click="goToFilter">{{ hebrew ?
          'המשך' :
          'Continue'
        }}
        </button>
      </div>
      <b-modal v-model="showTextExceeded" centered id="text-exceeded" hide-header hide-footer
        body-class="py-3 px-4 text-center content-error">
        <b-btn @click="showTextExceeded=false" class="position-absolute bg-transparent close-modal p-0" variant="link">&times;</b-btn>
        <div class="mb-2 mt-3 modal-heading">{{ hebrew ? 'התקבלה הודעת שגיאה' : 'An error occured' }}</div>
        <div v-if="hebrew">
          <p>אי אפשר להעלות קובץ של מעל 5000 תווים.</p>
          <p>
            תחילה יש לערוך את הקובץ בכלי "ניתוח מורפולוגי" ולאחר מכן יש להעלות את הטקסט המנותח תחת הלשונית "קובץ מנותח".
          </p>
        </div>
        <div v-else>
          <p>The tool does not support files with over 5,000 characters</p>
          <p>Please run your files through Dicta's Morph Analysis tool and then upload the results in the 'Import results
            file' tab.</p>
        </div>
        <a class="btn btn-primary mt-3 mb-4 btn-lg px-5" href="https://morph-analysis.dicta.org.il?tab=upload">
          {{ hebrew ? 'למעבר לכלי לחצו כאן' : 'Morph Analysis' }}
        </a>
      </b-modal>
    </div>
  </div>
</template>
<script>
import readFile from '@/js/readFile'
import { Actions } from '@/store/stateChanges'
import Spinner from '@/components/spinner'
import { RunStates } from '@/store/runStates'


export default {
  name: 'TextEditor',
  props: ["hideTextArea", "filterFile", "fileID"],
  components: { Spinner },
  data() {
    return {
      nikudText: '',
      entryName: 'EnteredText.txt',
      showTextExceeded: false
    }
  },
  methods: {
    focusedEvent() {
      this.$refs.textAreaWrap.classList.remove('border-dark')
      this.$refs.textAreaWrap.classList.add('border-primary')
    },
    focusedOutEvent() {
      this.$refs.textAreaWrap.classList.remove('border-primary')
      this.$refs.textAreaWrap.classList.add('border-dark')
    },
    clearText() { //value former param
      //this.$store.commit("SET_TEXT_FOR_API_CALL", "");
      this.nikudText = "";
      if (!this.hideTextArea)
        this.$refs.textArea.focus();
    },
    goToFilter() {
      this.$store.commit('SET_FILE_FOR_API_CALL', this.hideTextArea ? this.$refs.mainInputEl.files[0] : { fileText: this.nikudText, fileName: this.entryName.replace(/\.[^/.]+$/, ".txt") })
      if (this.hideTextArea || this.nikudText.length <= 5000) {
        setTimeout(() => {
          this.$store.dispatch(Actions.RUN_NAKDAN)
        }, 150)
      } else {
        this.showTextExceeded = true
      }
    },
    loadInputtedFile() {
      if (this.hideTextArea && !this.filterFile) {
        this.goToFilter()
      } else {

        const f = this.$refs.mainInputEl.files[0]
        const ftype = f ? f.type : null
        this.entryName = f.name
        readFile({
          file: f,
          name: f.name,
          type: ftype
        }).then(results => {
          this.nikudText = results.docText
          this.$emit('file-text', results.docText)

        })
      }
      this.$refs.fileForm.reset()
    }
  },
  computed: {
    running() {
      return this.$store.state.morphApiState === RunStates.RUNNING
    },
    keymap() {
      return {
        'ctrl+enter': () => {
          this.goToFilter()
        }
      }
    },
    hebrew() {
      return this.$settings.hebrew
    }
  }
}  
</script>  
<style lang="scss">
.content-error {
  .close-modal {
    top: 0;
    right: 0;
    font-size: 30px;
    line-height: 30px;
    width: 40px;
  }

  .modal-heading {
    font-size: 26px;
  }
}

</style>
<style scoped lang=scss>


.erase {
  left: 5px;
  top: 0;
  font-size: 20px;
  cursor: pointer;

  @media only screen and (max-width: 991px) {
    span {
      font-size: 34px;
    }
  }
}

.text-editor {
  height: calc(100vh - 150px);

  textarea {
    direction: rtl;
    font-size: 25px;
    overflow-y: auto;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    height: calc(100% - 60px);

    /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      /* Chrome/Safari/Webkit */
    }

    resize: none;

    &:focus {
      outline: none;
    }
  }

  .rounded {
    border-radius: 4px !important;
  }

  .action-btns {
    bottom: 0;
    left: 0;

    .run-btn {
      height: 36px;
      font-size: 16px;
      width: 120px;
    }

    form {
      height: 36px;
      label {
        cursor: pointer;
      }
    }

  }
}</style>
  