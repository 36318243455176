<template>
    <div class="reader-display position-relative">
        <div class="d-flex justify-content-between mb-2">
            <div style="font-size:18px;" :dir="hebrew ? 'rtl' : 'ltr'" v-if="graphTab">
                <span v-if="totalResults > 0">
                    {{ hebrew ? 'מציג' : 'Displaying' }}
                    {{ totalResults }}
                    {{ hebrew ? 'תוצאות' : 'results' }}
                </span>
            </div>
            <div style="font-size:18px;" :dir="hebrew ? 'rtl' : 'ltr'" v-if="!graphTab">
                <span>
                    {{ hebrew ? 'מציג' : 'Displaying' }}
                    {{ reader.matches }}
                    <span v-if="totalResults > 0">
                        {{ hebrew ? 'מתוך' : 'of' }}
                        {{ totalResults }}
                    </span>
                    {{ hebrew ? 'תוצאות' : 'results' }}
                </span>
            </div>
            <div class="text-left" v-if="files && files.length > 1 && !graphTab">
                <!--                 <b-btn variant="link" class="download-btn bg-transparent py-0 px-2" @click="download">
                    <i class="fa-solid fa-download"></i>
                </b-btn> -->
                <b-btn class="bg-transparent border-0 shadow-none px-2" variant="link" :disabled="currentFileIndex === 0"
                    @click="newFile('prev')"><i class="fa fa-angle-right" aria-hidden="true"></i></b-btn>

                <b-dropdown id="file-dropdown" class="m-2">
                    <template #button-content>
                        <span>{{ files[currentFileIndex] }}</span>
                    </template>
                    <b-dropdown-item :class="{ 'bg-secondary': currentFileIndex === index }" :id="'popover-target-' + index"
                        @click="currentFileIndex = index" v-for="(file, index) in files" :key="index">
                        <span
                            :class="{ 'text-primary': allFrequencies.pages && allFrequencies.pages[file] && allFrequencies.pages[file].length > 0 }">{{
                                file
                            }}</span>
                        <b-popover v-if="file.length > 19" :target="'popover-target-' + index" triggers="hover"
                            placement="top">
                            {{ file }}
                        </b-popover>
                    </b-dropdown-item>


                </b-dropdown>

                <b-btn :disabled="currentFileIndex === files.length - 1" class="bg-transparent border-0 shadow-none px-2"
                    variant="link" @click="newFile('next')"><i class="fa fa-angle-left" aria-hidden="true"></i></b-btn>
            </div>
        </div>
        <div v-if="graphTab" style="max-height: 500px;" class="overflow-auto">
            <div v-if="allFrequencies.frequency && searchFilters.length > 0">
                <div v-for="(file, index) in files" :key="index">
                    {{ file }}
                    <histogram :frequencies="allFrequencies.frequency[file]"></histogram>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="reader-text">
                <div v-if="fileName" class="reader-heading py-1 px-3"><span v-if="fileName !== 'EnteredText.txt'">{{
                    fileName.replace(/\.[^/.]+$/, "") }}</span></div>
                <span class="f-narkis px-5 pt-4 d-block" dir="rtl"
                    :class="{ filtered: searchFilters.length > 0 && !running && reader.matches <= totalResults && reader.matches < readerLength }"
                    v-html="reader.text"></span>
                <histogram v-if="reader.frequency && searchFilters.length > 0" :frequencies="reader.frequency"></histogram>


            </div>
            <div class="text-center pagination-wrap">
                <b-pagination v-if="filePageLength > 0" @change="newPage()" hide-ellipsis hide-goto-end-buttons
                    prev-text="לעמוד  הקודם" next-text="לעמוד הבא" next-class="text-btn" prev-class="text-btn" limit="25"
                    v-model="currentPage" :total-rows="filePageLength" :per-page="$store.state.readerContentLength">
                    <template #page="scope">

                        <span
                            :class="{ 'has-results': searchFilters.length > 0 && allFrequencies.pages && allFrequencies.pages[files[currentFileIndex]].indexOf(scope.index) > -1 }">{{
                                scope.page }}</span>
                    </template>
                </b-pagination>
            </div>
        </div>

    </div>
</template>
<script>

import { RunStates } from '@/store/runStates'
import Histogram from '@/components/Histogram'

export default {
    name: 'Reader',
    components: { Histogram },
    props: ['graphTab'],
    data() {
        return {
            currentPage: 1
        }
    },
    methods: {
        newFile(direction) {
            if (direction === 'next') {
                this.$store.commit('SET_FILE_INDEX', this.currentFileIndex + 1)
            } else {
                this.$store.commit('SET_FILE_INDEX', this.currentFileIndex - 1)
            }
            this.currentPage = 1
            this.$emit('get-data')
        },
        newPage() {
            setTimeout(() => {
                this.$store.commit('SET_PAGE_NUMBER', this.currentPage - 1)            
                this.$emit('get-data')}
            , 20)
        }
    },
    computed: {
        hebrew() {
            return this.$settings.hebrew
        },
        reader() {
            return this.$store.state.readerContent
        },
        allFrequencies() {
            return this.$store.state.frequencyContent
        },
        totalResults() {
            return this.allFrequencies.total
        },
        allFiles() {
            return this.$store.state.userFiles
        },
        searchFilters() {
            return this.$store.state.filters.filter(e => e.filterType !== 'files' && e.filterType !== 'stopMenukad' && e.filterType !== 'stopLexeme' && !e.negate)
        },
        fileFilters() {
            return this.$store.state.filters.filter(e => e.filterType === 'files')
        },
        files() {
            return this.fileFilters.length > 0 ? this.fileFilters[0].filter : this.allFiles.map(el => el.filename)
        },
        /*      ddFiles() {
                 return this.files.map((item, index)=>{return '<span class="text-primary">'+item+index+'</span>'})
             }, */
        currentFileIndex: {
            get() {
                return this.$store.state.selectedFileIndex
            },
            set(val) {
                this.currentPage = 1
                this.$store.commit('SET_FILE_INDEX', val)
                this.$emit('get-data')
            },
        },
        fileName() {
            return this.files[this.currentFileIndex] ? this.files[this.currentFileIndex] : ''
        },
        filePageLength() {
            let selectedFile = this.allFiles[this.currentFileIndex]
            //if file filter, get correct file wordNum from file list
            if (this.fileFilters.length > 0) {
                selectedFile = this.allFiles.filter(e => e.filename === this.fileName)[0]
            }
            return selectedFile ? selectedFile.wordCount : 0//Math.ceil(selectedFile.wordCount / this.$store.state.readerContentLength) -1 

        },
        readerLength() {
            return this.$store.state.readerContentLength
        },
        running() {
            return this.$store.state.morphApiState === RunStates.RUNNING
        }


    }
}
</script>    
<style  lang="scss">
.reader-display {
    /*     .download-btn {
        border-left: solid 1px #979797;
        height: 20px;
        margin-left: 3px;
    } */

    .dropdown-menu.show {
        max-height: 400px;
        overflow-y: auto;
        top: 3px !important;
        border-radius: 4px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
        width: 200px;
    }

    .btn.dropdown-toggle {
        height: 28px;
        border-radius: 4px;
        background-color: white !important;
        border-color: black !important;
        width: 200px;
        text-align: right;
        overflow: hidden;
        span {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .dropdown-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 15px;
        padding-right: 15px;
    }

    .dropdown-toggle:after {
        content: "\f107";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        border: none !important;
        position: absolute;
        left: 5px;
    }


    b {
        font-weight: normal !important;
    }

    .filtered {
        b {
            background-color: #fee764;
        }
    }

    .reader-heading {
        background-color: #d6ecff;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        height: 32px;
    }

    .reader-text {
        border-radius: 6px;
        border: solid 1px #d8d8d8;
        min-height: 500px;
    }


}


.pagination-wrap {
    .b-pagination {
        direction: rtl;
        text-align: center;
        display: block;
        margin: 0;
        padding: 0 0 0.5rem 0;

        li {
            display: inline-block;
            margin: 0;

            &.text-btn {
                padding: 0.6rem 0.6rem 0;

                button {
                    background-color: #e3e3e3;
                    color: black;
                    font-size: 14px;
                    padding: 7px;
                }

                span {
                    display: none;
                }
            }

            &.page-item {
                .page-link {
                    background: none;
                    border-color: transparent;
                    color: black;
                    padding: 0.3rem;
                    font-size: 14px;

                    .has-results {
                        font-weight: bold;
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }



                &.active {
                    .page-link {
                        color: #9a9a9a;
                        text-decoration: underline;
                    }
                }
            }

        }
    }
}
</style>