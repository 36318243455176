<template>
  <div class="download-results d-inline-block">
    <b-button id="download-results-btn" class="bg-transparent border-0 shadow-none p-0" @click="downloadResults">
      <i class="fa fa-download" aria-hidden="true"></i>
    </b-button>
  </div>
</template>

<script>
import { downloadBlob } from '@/js/printCSV'
export default {
  name: 'DownloadResults',
  props: ['items', 'fields', 'nikud'],
  data() {
    return {

    };
  },
  computed: {
  },
  methods: {
    downloadResults() {
      let headers = []
      let csvItems = []
      this.fields.map(el => {
        if(el.label && el.label.length > 0)
          headers.push(el.label)
      })
      csvItems.push(headers)
        this.items.map(item => {
          let arr = []
          this.fields.map(el => {
            if(el.key !== 'show_details')
            if (this.nikud)
              arr.push(item[el.key])
            else  
              arr.push(item[el.key].replace(/[\u0591-\u05C7]/g, ''))
        })
        csvItems.push(arr)
      })
      downloadBlob(csvItems)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
