
<template>
    <div class="zip-files h-100">
        <div class="position-absolute h-100 w-100" v-if="running">
            <spinner></spinner>
        </div>
        <div class="main d-flex h-100 align-items-center justify-content-center flex-grow-1 text-center p-3">
            <div class="dropzone-container h-100 w-100" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <input type="file" name="file" id="fileInput" class="hidden-input overflow-hidden position-absolute"
                    @change="onChange" ref="file" accept=".zip" />
                <label for="fileInput" class="file-label d-block h-75 w-100">
                    <div class="h-100  d-flex align-items-center" :class="{ 'dragging': isDragging }">
                        <div class="circle mx-auto">
                            <h2 v-if="bgu" class="mb-4">{{ hebrew ? 'העלו לכאן קובץ מנותח' : 'Upload analyzed file' }}</h2>
                            <h2 v-else class="mb-4">{{ hebrew ? 'העלאת קובץ' : 'Upload file' }}</h2>
                            <h2 v-if="hebrew" class="font-weight-bold mb-0">
                                גרור או
                                <span class="text-primary text-underline">בחר</span>
                            </h2>
                            <h2 class="font-weight-bold mb-0" v-else>
                                Drop files here or <span class="text-primary text-underline">choose files</span></h2>
                            <div class="text-muted">
                                {{ hebrew ? 'ניתן להעלות קבצי zip' : 'zip files are supported' }}
                            </div>
                        </div>
                    </div>
                </label>
                <div class="morph-analysis" v-if="bgu">
                    <span v-if="hebrew">
                        "קובץ מנותח" הוא קובץ שעובר ניתוח מורפולוגי בכלי ייעודי<br>
                        למעבר לכלי לניתוח מורפולוגי
                        <a href="https://morph-analysis.dicta.org.il?tab=upload"><u>לחץ כאן</u></a>
                    </span>
                    <span v-else>
                        An analyzed file, is a file that has been processed by Dicta's
                        <a href="https://morph-analysis.dicta.org.il?tab=upload"><u>Morph Analysis</u></a> tool
                    </span>
                </div>

                <!--                 <div class="preview-container" v-if="files.length">
                    <div v-for="file in files" :key="file.name" class="preview-card d-flex border">
                        <div>
                            <p>
                                {{ file.name }}
                            </p>
                        </div>
                        <div>
                            <button class="ml-2" type="button" @click="remove(files.indexOf(file))" title="Remove file">
                                <b>×</b>
                            </button>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
        <b-modal v-if="invalidFiles.length > 0" v-model="showUploadError" centered id="upload-error" hide-header hide-footer
            body-class="pt-4 pb-5 px-4 text-center content-error">
            <b-btn @click="showUploadError = false" class="position-absolute bg-transparent close-modal p-0"
                variant="link">&times;</b-btn>
            <div class="mb-2 mt-3 modal-heading">{{ hebrew ? 'שגיאה בקריאת הקבצים' : 'An error occured' }}</div>
            <div>
                <p v-if="hebrew">הקבצים הבאים אינם תואמים את הפורמט של הכלי:</p>
                <p v-else>
                    The following files do not match the tool's format:
                </p>
                <p class="overflow-auto" style="max-height: 200px;">{{ invalidFiles.join(', ') }}</p>
                <div class="pt-3">
                    <b-btn class="mx-1" variant="secondary" @click="clearFiles">
                    {{ hebrew ? 'טען קבצים מחדש' : 'Reloadfiles' }}</b-btn>
                    <b-btn class="mx-1" v-if="!noProccessedFiles" variant="primary" @click="runPoll">
                    {{ hebrew ? 'דילוג על  קבצים אלו' : 'Ignore files' }}</b-btn>
                </div>
            </div>
        </b-modal>

    </div>
</template>
<script>
import { Actions } from '@/store/stateChanges'
import { RunStates } from '@/store/runStates'
import Spinner from '@/components/spinner'
//import readZipContentsWithLoadAsync from '@/js/readZipFile.js'

export default {
    name: 'ZipFiles',
    components: { Spinner },
    props: ['bgu'],
    data() {
        return {
            isDragging: false,
            files: [],
            showUploadError: true
        }
    },
    methods: {
        onChange() {
            this.files = [...this.$refs.file.files];
            this.$store.commit('SET_FILE_TYPE', this.bgu ? 'zipBgu' : 'zip')
            this.$store.commit('SET_FILE_FOR_API_CALL', this.files[0])
            this.$store.dispatch(Actions.RUN_NAKDAN)
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        remove(i) {
            this.files.splice(i, 1);
        },
        clearFiles() {
            this.showUploadError = false
            this.$refs.file = null
            this.files = []
        },
        runPoll() {
            this.showUploadError = false
            this.$store.dispatch(Actions.RUN_POLL)
        }
        /*         loadInputtedFile() {
                    const zipFile = this.$refs.mainInputEl.files[0]
                    this.$store.commit('SET_FILE_TYPE', this.bgu ? 'zipBgu' : 'zip')
                    this.$store.commit('SET_FILE_FOR_API_CALL', zipFile)
                    this.$store.dispatch(Actions.RUN_NAKDAN)
                }, */
    },
    computed: {
        hebrew() {
            return this.$settings.hebrew
        },
        running() {
            return this.$store.state.morphApiState === RunStates.RUNNING
        },
        invalidFiles() {
            return this.$store.state.invalidFiles
        },
        noProccessedFiles() {
            return this.$store.state.noFiles
        }
    }

}
</script>

<style lang="scss" scoped>
.zip-files {
    .v-spinner {
        margin-right: -34px;
    }
}

.hidden-input {
    opacity: 0;
    width: 1px;
    height: 1px;
}

.file-label {
    cursor: pointer;

    // padding-top: 6%;
    //margin-bottom: 3%;
    .dragging {
        opacity: 0.7;
        border: 1px dashed var(--primary)
    }

    .circle {
        width: 335px;
        height: 335px;
        padding: 115px 63px 120px 65px;
        background-color: rgba(214, 236, 255, 0.2);
        border-radius: 50%;
    }
}

.morph-analysis {
    font-size: 18px;
}
</style>