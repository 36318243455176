<template>
    <div class="upload-zip h-100">
       <div class="position-absolute h-100 w-100" v-if="running">
            <spinner ></spinner>
        </div> 
        <zip-files></zip-files>
    </div>
</template>
<script>
import ZipFiles from '@/components/ZipFiles.vue'
import Spinner from '@/components/spinner'
import { RunStates } from '@/store/runStates'
export default {
    name: 'UploadZip',
    components: {ZipFiles, Spinner},
    data () {
        return {
        }
    },

    computed: {
        hebrew () {
            return this.$settings.hebrew
        },
        running() {
            return this.$store.state.morphApiState === RunStates.RUNNING
        }        
    }
  
}
</script>      