<template lang="html">
  <section class="main-search" id="main-search" :class="{'embedded': embedded}">
    <div class="search-input-wrap" :class="{'pt-1 pb-3 mb-3': embedded}">
      <TypeAhead ref="taComponent" @clear-search="$emit('clear-search')" @not-in-list="reset" @run-search="searchForWord"
        @word-typed="(w) => $emit('word-typed', w)" @ta-cleared="searchWord=''" @focus-in="$emit('focus-in')" @focus-out="$emit('focus-out')"
        @backspace="$emit('backspace')"
        :label="hebrew ? 'מילת חיפוש' : 'Search word'"
        :typeAheadFunc="taOptions ? getSoundPlayTypeAheads : embedded ? getSoundPlayTypeAheadsFromServer : getMorphTypeAheadsFromServer"
        :searchTerm="searchWord"  :searchId="searchId" :forceHebrew="forceHebrew" :customClass="!embedded && !taOptions ? menukadSearch ? 'menukad-search-ta' : 'word-search-ta' : taOptions ? 'morph-search-ta' : ''">
      </TypeAhead>
    </div>

  </section>
</template>

<script>
import TypeAhead from '@/components/TypeAhead'
import axios from 'axios'
import { StoreConfig } from '@/store/storeConfig'
//import {stringWithoutNikud} from '@/js/commonHebrew'

const SOUNDPLAY_SERVER = StoreConfig.SOUNDPLAY_SERVER
const NAKDAN_SERVER = StoreConfig.NAKDAN_SERVER

axios.defaults.headers = {
  'Content-Type': 'text/plain;charset=UTF-8'
}
const axiosSearchInstance = axios.create({ timeout: 130000 })

export default {
  name: 'MainSearch',
  components: {
    TypeAhead
  },
  props: ["savedSearchWord", "embedded", "taOptions", "menukadSearch", "lexSearch", "searchId", "forceHebrew"],
  data() {
    return {
      searchWord: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.savedSearchWord) {
        this.searchWord = this.savedSearchWord
      }
    })
  },
  methods: {
    searchForWord(selectedWord) {
      this.$emit('word-selected', selectedWord)
    },
    reset() {
      this.$emit('word-not-found')
    },
    clearSearchTerm() {
    },
    getSoundPlayTypeAheads: async function (wordSoFar) { 
      this.searchWord = wordSoFar
      if (wordSoFar.length > 0 && this.taOptions) {
        this.loading = true
        var arr = []
        //arr = this.taOptions.filter(function (item) { return item.replace(/[^א-ת]/g, '').startsWith(wordSoFar.trim()) })
        arr = this.taOptions.filter(function (item) { return item.toLowerCase().includes(wordSoFar.toLowerCase().trim()) })
        this.loading = false
        if (arr.length > 0) {
          return arr
        } else {
          this.loading = false
          return []
          //return this.getSoundPlayTypeAheadsFromServer(entireString, index)

        }
      }

    },
    getSoundPlayTypeAheadsFromServer: async function (wordSoFar) {
      const result = await axiosSearchInstance.post(
        SOUNDPLAY_SERVER, JSON.stringify({
          w: wordSoFar
        })
      )
      return result.data
    },
    getMorphTypeAheadsFromServer: async function (wordSoFar) {
    //let mode = this.$store.state.selectedMode
    const requestObj = {
        uid: this.$store.state.uid,
        filters: [{
          filterType: this.lexSearch ? 'typeAheadLex' : 'typeAhead',
          filter: wordSoFar,
        }]
        ,
        offset: 0,
        resultsPerPage: 20,
        contextLength: 0,
        groupBy: this.lexSearch ? 'lexeme' : this.menukadSearch ? 'menukad' : 'word'
      }
      const result = await axiosSearchInstance.post(
        NAKDAN_SERVER + '/count', JSON.stringify(requestObj), { headers: { 'Content-Type': 'application/json' } })
      let matches = result.data.matches.map(item => {
        //return mode === 'word' ? item.menukad : mode === 'lex' ? item.lexeme : item.base
        return this.lexSearch ? item.lexeme : this.menukadSearch ? item.menukad : item.word
      })
      return [...new Set(matches)]
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    nakdanList() {
      return this.$store.state.soundplayResults.error == null ? this.$store.state.soundplayResults : ''
    },
  },
  watch: {
    savedSearchWord() {
      this.searchWord = this.savedSearchWord
      if (this.savedSearchWord.length === 0)
        this.$refs.taComponent.showNotInList = false
    }
  }

}
</script>

<style lang="scss" scoped>
.main-search.embedded {
  background-color: #bfe1fe;
  border-radius: 4px;
  margin-top: 40px;
}

.mobile-main-search {
  padding: 12px 15px;
  margin: 0 -15px !important;
  background-color: #bfe1fe;

  .mode-btn {
    border-radius: 6px 0 0 6px;
    left: 15px;
    top: 12px;
    height: 48px;
    z-index: 2;
    font-size: 16px;
  }
}
</style>
<style lang="scss">
.mobileSearchTypeAhead {
  label {
    display: none !important;
  }
}
</style>