<template>
  <div id="app" class="h-100" :class="currentRouteName">
    <Header class="site-header" :class="{ 'd-none d-md-block': !opener }" />
    <search-pattern v-if="opener"></search-pattern>
    <div v-if="!opener" class="pt-3 bg-background d-none d-md-block" :class="{ 'h-100': $route.name === 'home' }">
      <ServerFailedPopup v-model="failed" v-if="failed" />

      <b-container v-if="$route.name !== 'results' && !shasCorpus && !bibleCorpus">
        <b-row class="wrapper border mx-0">
          <b-col cols="1" class="bg-lb px-0">
            <nav-bar></nav-bar>
          </b-col>
          <b-col cols="11" class="p-0">
            <div class="bg-white position-relative h-100">
              <router-view></router-view>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div v-else>
        <router-view></router-view>
      </div>
    </div>
    <div v-if="!opener"
      class="d-flex flex-column justify-content-center d-md-none bg-primary h-100 text-center text-white">
      <div class="logo-div">
        <div class="d-inline-block mb-4" >
          <img src="@/assets/logo.png" alt="logo" style="max-width: 150px;" />
        </div>  
        <div class="xd-inline-block d-none text-white mx-2">
          <div class="tool-name" v-if="shasCorpus">{{ hebrew ? 'דיקטה אנליטיקס לש"ס' : 'Dicta Analytics for Shas' }}</div>
          <div class="tool-name" v-else-if="bibleCorpus">{{ hebrew ? 'דיקטה אנליטיקס לתנ״ך' : 'Dicta Analytics for Bible'
          }}</div>
          <div class="tool-name" v-else>{{ hebrew ? 'דיקטה אנליטיקס' : 'Dicta Analytics' }}</div>
          <small class="d-none dicta">{{ hebrew ? 'גרסת אלפא' : 'Alpha Version' }}</small>
        </div>
      </div>
      <h2 class="w-100 text-center">הכלי אינו זמין בנייד</h2>
    </div>
  </div>
</template>
<script>

import Header from "@/components/Header"
//import Spinner from '@/components/spinner'
import SearchPattern from '@/components/SearchPattern'
import NavBar from '@/components/NavBar'
import { RunStates } from '@/store/runStates'
import ServerFailedPopup from '@/components/ServerFailedPopup'

export default {
  name: 'app',
  components: {
    Header,
    SearchPattern,
    NavBar,
    ServerFailedPopup
  },
  data() {
    return {
      opener: null,
      bibleCorpus: process.env.VUE_APP_USER_NAME === 'library',
      shasCorpus: process.env.VUE_APP_USER_NAME === 'shas'
    }
  },
  /*   destroyed() {
      let el = document.querySelector("link[href='https://www.dropbox.com/static/api/2/dropins.js']")
      if(el) { el.remove() }
    },  
    created () { 
      let dropboxScript = document.createElement('script')
      dropboxScript.setAttribute('src', 'https://www.dropbox.com/static/api/2/dropins.js')
      dropboxScript.setAttribute('id', 'dropboxjs')
      dropboxScript.setAttribute('data-app-key', '3ombss9ontxlzxa')
      document.head.appendChild(dropboxScript)
    },  */

  mounted() {
    this.opener = window.parent.location !== window.location
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    failed: {
      get() {
        return this.$store.state.morphApiState === RunStates.FAILED || this.$store.state.morphApiState === RunStates.TIMED_OUT
      },
      set() {
        this.$store.commit('MORPH_NOT_RUN')
      },
    },
    currentRouteName() {
      return this.$route.name;
    }
    /*  running() {
       return this.$store.state.morphApiState === RunStates.RUNNING
     }, */
  }
}
</script>

<style lang="scss">

.d-header-none .results header {
  display: none!important;
}

@font-face {
  font-family: 'mft_narkisclassic';
  src: url('assets/fonts/mft_narkisclassic-regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'mft_narkisclassic';
  src: url('assets/fonts/MFTW_NarkisClassicBold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

.f-narkis {
  font-family: Roboto, mft_narkisclassic, sans-serif;
  line-height: 1.5;
}

.embedded {
  overflow: hidden;
  background: #d6ecff;

  #app {
    overflow: hidden;
  }
}

div.tooltip {
  bottom: 0;
  font-size: 16px !important;
  padding: 5px 10px !important;
  line-height: 19px !important;
  direction: ltr;
  text-transform: capitalize;
}

.wrapper {
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
}

/* 
.b-tooltip {
  width: auto;
  height: auto;
  padding: inherit !important;
  font: inherit;
  font-size: 14px !important;
  line-height: 20px !important;
  background: transparent !important;

} */</style>
