<template>
    <b-modal @show="setDefaultValues" id="settings-modal" v-model="show" centered size="lg"
        :ok-title="hebrew ? 'שמירה' : 'Save'" :cancel-title="hebrew ? 'ביטול' : 'Cancel'"
        :title="hebrew ? 'הגדרות תצוגה' : 'Display settings'" header-class="d-flex justify-content-between"
        body-class="p-0 bg-light" @ok="saveSettings">
        <section class="px-3 border-bottom mb-2 pt-4 pb-3">
            <h3>{{ hebrew? 'מספר נתונים להצגה': 'Number of items to show' }}:</h3>
            <b-form-radio-group  id="cloud-num" v-model="numberToDisplay" :options="wordNumOptions"
                name="radio-cloud-options">
            </b-form-radio-group>
        </section>
        <section class="px-3 border-bottom mb-2 pb-3" :class="{'text-muted': !wordCloudDisplay}">
            <h3>{{ hebrew? 'בחירת צבע': 'Choose colors' }}:</h3>
            <b-form-radio v-model="cloudColors" value="all" :disabled="!wordCloudDisplay">{{
                hebrew? 'צבעוני':
                    'Multi-colors'
            }}</b-form-radio>
            <b-form-radio v-model="cloudColors" value="blue" :disabled="!wordCloudDisplay">{{
                hebrew? 'גווים של כחול':
                    'Blues'
            }}</b-form-radio>
        </section>
        <section class="px-3 border-bottom mb-2 pb-3 d-none">
            <b-form-checkbox v-model="showSingleResult" switch>
                {{ hebrew? 'הצגת נתונים עם מופע יחיד': 'Display results with single occurence' }}
            </b-form-checkbox>
        </section>
    </b-modal>
</template>
<script>
//limit by number of words
//hide single

//choose colors
export default {
    name: 'CloudSettingsModal',
    props: ["value","wordCloudDisplay"],
    data() {
        return {
            showSingleResult: false,
            cloudColors: "all",
            showTopWordsOnly: false,
            wordNumOptions: [25, 50, 100, 150],
            numberToDisplay: 50,
            tabIndex: 0,
            selected: [],
   
        }
    },
    methods: {
        setDefaultValues() {
            let settings = JSON.parse(JSON.stringify(this.settings))
            this.showSingleResult = settings.showSingleResult
            this.cloudColors = settings.cloudColors
            this.showTopWordsOnly = settings.showTopWordsOnly
            this.numberToDisplay = settings.numberToDisplay
        },
        saveSettings(bvModalEvent) {
            bvModalEvent.preventDefault()
            let settings = {
                'showSingleResult': this.showSingleResult,
                'cloudColors': this.cloudColors,
                'showTopWordsOnly': this.showTopWordsOnly,
                'numberToDisplay': this.numberToDisplay
            }
            this.$store.commit('SAVE_CLOUD_SETTINGS', settings)
            this.$emit('settings-changed')
            this.show = false
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit("input", val)
            },
        },
        hebrew() {
            return this.$settings.hebrew

        },
        settings() {
            return this.$store.state.cloudSettings
        }
    }

}
</script>
<style lang="scss">
#settings-modal {
    .close {
        display: contents;
        font-size: 27px;
    }
}
</style>