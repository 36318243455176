<template>
  <b-modal id="search-nikud-modal" v-model="show" centered hide-footer size="md" :title="hebrew ? 'תבנית ניקוד' : 'Search Pattern'">
    <search-pattern @search-object="(val) => {show=false;$emit('search-object', val)}" @clear-pattern="$emit('clear-pattern')"  @tokens="(arr) => $emit('tokens', arr)"
      :wordForPattern="wordForPattern" :taOptions="taOptions" :savedTokens="savedTokens" >
    </search-pattern>
  </b-modal>
</template>    
<script>
import SearchPattern from '@/components/SearchPattern'
export default {
  name: 'SearchPatternModal',
  components: { SearchPattern },
  props: ["value", "wordForPattern", "taOptions", "savedTokens"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    hebrew() {
      return this.$settings.hebrew
    }
  }
}    
</script>