<template>
    <div class="position-relative wc-wrap">
        <wordcloud :key="componentKey" style="cursor:pointer;" :data="occurrences"
            nameKey="word" valueKey="count" :showTooltip="true"
            :color="settings.cloudColors === 'blue' ? blueCloudColors : 'Accent'" :fontSize="[30, 80]"
            :rotate="{ from: -10, to: 10, numOfOrientation: 5 }" :wordClick="itemClickHandler">
        </wordcloud>

    </div>
</template>

<script>

import wordcloud from 'vue-wordcloud'

export default {
    name: 'WordCloud',
    components: { wordcloud },
    props: ['wcData', 'selectedMode'],
    data() {
        return {
            blueCloudColors: ['#1f77b4', '#629fc9', '#94bedb', '#c9e0ef'],
            componentKey: 0,
        }
    },
    methods: {
        itemClickHandler(name) {
            let newParams = [{
                'filterType': this.selectedMode === 'baseWord' ? 'word' : this.selectedMode,
                'filter': [name]
            }]
            let newUrl = encodeURIComponent(JSON.stringify(newParams))
            window.open(window.location.origin + '/filter-results?search=' + newUrl, '_blank');
        },
        downloadImage() {
            var canvas = document.getElementById('canvas')
            var svg = document.querySelector('svg')
            svg.setAttribute("height", svg.clientHeight)
            svg.setAttribute("width", svg.clientWidth)
            var ctx = canvas.getContext('2d');
            var data = (new XMLSerializer()).serializeToString(svg)
            var DOMURL = window.URL || window.webkitURL || window;

            var img = new Image();
            var svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' })
            var url = DOMURL.createObjectURL(svgBlob);

            img.onload = function () {
                ctx.drawImage(img, 0, 0, svg.clientWidth, svg.clientHeight)
                DOMURL.revokeObjectURL(url);

                var imgURI = canvas
                    .toDataURL('image/png')
                    .replace('image/png', 'image/octet-stream')

                var evt = new MouseEvent('click', {
                    view: window,
                    bubbles: false,
                    cancelable: true
                });

                var a = document.createElement('a')
                a.setAttribute('download', 'Cloud_Image.png')
                a.setAttribute('href', imgURI)
                a.setAttribute('target', '_blank')

                a.dispatchEvent(evt)

            }

            img.src = url;
        }
    },
    computed: {
        hebrew() {
            return this.$settings.hebrew
        },
        settings() {
            return this.$store.state.cloudSettings
        },
        wordOccurrences() {
            return this.$store.state.wordCountItems
        },
        lexOccurrences() {
            return this.$store.state.lexCountItems
        },
        baseOccurrences() {
            return this.$store.state.baseWordCountItems
        },
        occurrenceTableData() {
            return this.selectedMode === 'menukad' ? this.wordOccurrences : this.selectedMode === 'lexeme' ? this.lexOccurrences : this.baseOccurrences
        },

        occurrences() {
            //const hebrewRegex = /[^א-ת]/g; 

            const newArray = this.occurrenceList.map(obj => ({
                count: obj.count,
                word: obj[this.selectedMode].replace(/&quot;/g, '"')//.replace(/&#[\w]+;/g, '').replace(hebrewRegex, '') // remove non-Hebrew letters
            }));

            return newArray
        },
        occurrenceList() {
            let temp = JSON.parse(JSON.stringify(this.occurrenceTableData))
            if (!this.settings.showSingleResult) {
                temp = temp.filter(item => { return item.count > 1 })
            }
            if (this.settings.showTopWordsOnly) {
                temp = temp.slice(0, parseInt(this.settings.numberToDisplay))
            }
            return temp
        },        

        /* occurrences() {
            const hebrewRegex = /[^א-ת]/g; 

            const newArray = this.wcData.map(obj => ({
                count: obj.count,
                word: obj[this.selectedMode].replace(/&#?\w+;/g, '').replace(hebrewRegex, '') // remove non-Hebrew letters
            }));

            return newArray
        }, */
    }
}
</script>
<style lang="scss">
.wordCloud {
    border: 1px solid #000;
    border-radius: 8px;
    // width: 435px !important;
    // height: 200px !important;
    display: block !important;
    background-color: white;
}

.text {
    font-family: Roboto, mft_narkisclassic, sans-serif !important;
}

.wc-btns {
    left: 10px;
    bottom: 0;
    z-index: 10;
}

.clicked-word {
    bottom: 0;
    right: 0;
    font-weight: bold;
}
</style>