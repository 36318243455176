/**
 * @typedef {Object} LetterInfo
 * @prop {boolean} [wildcard]
 * @prop {boolean} [anyletter]
 * @prop {string} [letter]
 * @prop {boolean} [anynikud]
 * @prop {string[]} [nikud]
 * @prop {'ׂ'|'ׁ'} [shin]
 * @prop {'Y'|'M'|'N'} [dag]
 * @prop {'Y'|'M'|'N'} [rootletter]
 */

/* String.prototype.normalize() Sorts characters in this order:
    Nikud, dagesh, right shin, left shin, ta'am.
    Letters act as separators, and are not sorted */

// Any character in the Hebrew Unicode block except makaf
const hebrewChar = '\u0591-\u05bd\u05bf-\u05f2'

const hebrewLetter = '\u05d0-\u05f2'
const dagesh = '\u05bc'
const nikudChar = '\u05b0-\u05bb\u05bf\u05c4\u05c5\u05c7'
const teʿamim = '\u0591-\u05af\u05bd'
const rightShin = '\u05c1'
const leftShin = '\u05c2'
//const makafCharacter = '\u002d\u2212\u2010\u05be'

/** @type {`(?![${nikudChar}])`} */
const noNikudRegex = `(?![${nikudChar}])`

/**
 * @param {LetterInfo} letter
 * @returns {string}
 */
function buildLetterRegex(letter) {
    if (letter.wildcard) return '[' + hebrewChar + ']*'

    const isNoNikud = letter.nikud.includes('!')
    const nikudRegex = letter.anynikud ?
        '[' + nikudChar + ']*' :
        '[' + letter.nikud.join('').replace('!', '') + ']'

    let letterRegex = letter.anyletter ?
        '[' + hebrewLetter + ']' :
        letter.letter

    if (letter.nikud.length === 1 && isNoNikud) {
        // The letter should not have nikud
        letterRegex += noNikudRegex
    } else {
        // The letter might/should have nikud
        letterRegex += '(?:' + nikudRegex

        // The letter might not have nikud
        if (isNoNikud) letterRegex += '|' + noNikudRegex

        letterRegex += ')'
    }

    // Should the letter have a dagesh?
    if (letter.dag === 'Y') { // Yes
        letterRegex += dagesh
    } else if (letter.dag === 'M') { // Maybe
        letterRegex += dagesh + '?'
    } else { // No
        letterRegex += `(?!${dagesh})`
    }

    if (letter.shin) {
        letterRegex += letter.shin
    } else if (letter.letter === 'ש' || letter.anyletter) {
        // The letter is / might be Shin
        letterRegex += '[' + rightShin + leftShin + ']?'
    }

    // Ignore te'amim
    letterRegex += '[' + teʿamim +']*'

    return letterRegex
}

/**
 * Creates a regex that matches strings specified by the data provided
 * - Only use this regex on strings after calling `normalize()` on them to avoid sorting issues
 * @param {LetterInfo[]} lettersInfo
 * @returns {string} A **string representation** of the regular expression
 */
export function buildRegex(lettersInfo) {
    // If the input is only wildcards, return a regexp that will never match to avoid infinite matches
    if (lettersInfo.length === lettersInfo.filter(letter => letter.wildcard).length) {
        return '^\b$' // https://stackoverflow.com/a/2302992
    }

    let regexString = ''
    for (const letter of lettersInfo) {
        regexString += buildLetterRegex(letter)
    }

    /* Scan for a word-boundary before and after the match, unless a wildcard was specified.
        Note that \b won't work here. Credit: Mariia Abramyk - https://stackoverflow.com/a/63391493 */
    if (!lettersInfo[0].wildcard) regexString = '(?<![' + hebrewChar + '])' + regexString
    if (!lettersInfo.at(-1).wildcard) regexString += '(?![' + hebrewChar+ '])'

    return regexString
}