import { RunStates } from './runStates'
export const StateChanges = {
    MORPH_RUNNING: 'MORPH_RUNNING',
    MORPH_COMPLETE: 'MORPH_COMPLETE',
    MORPH_NOT_RUN: 'MORPH_NOT_RUN',
    MORPH_FAILED: 'MORPH_FAILED',
    MORPH_TIMED_OUT: 'MORPH_TIMED_OUT',
    SET_MODE: 'SET_MODE',
    SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
    SET_FILE_INDEX: 'SET_FILE_INDEX',
    SET_FILE_TYPE: 'SET_FILE_TYPE',
    SET_FILE_FOR_API_CALL: 'SET_FILE_FOR_API_CALL',
    SET_POLLING_PARAMS: 'SET_POLLING_PARAMS',
    ADD_MENUKAD_TEXT: 'ADD_MENUKAD_TEXT',
    REMOVE_MENUKAD_TEXT: 'REMOVE_MENUKAD_TEXT',
    SET_ALL_FILTERS: 'SET_ALL_FILTERS',
    SET_NGRAM_SIZE: 'SET_NGRAM_SIZE',
    SET_ADJACENT_DIRECTION: 'SET_ADJACENT_DIRECTION',
    SET_READER_RESULTS: 'SET_READER_RESULTS',
    SET_FREQUENCY_RESULTS: 'SET_FREQUENCY_RESULTS',
    SET_SERVER_RESULTS: 'SET_SERVER_RESULTS',
    SET_NGRAM_RESULTS: 'SET_NGRAM_RESULTS',
    SET_ADJACENT_PREV_RESULTS: 'SET_ADJACENT_PREV_RESULTS',
    SET_ADJACENT_NEXT_RESULTS: 'SET_ADJACENT_NEXT_RESULTS',
    SET_COUNT_RESULTS: 'SET_COUNT_RESULTS',
    SET_ALL_WORD_COUNT_RESULTS: 'SET_ALL_WORD_COUNT_RESULTS',
    SET_LEX_COUNT_RESULTS: 'SET_LEX_COUNT_RESULTS',
    //SET_UNIQUE_RESULTS: 'SET_UNIQUE_RESULTS',
    SET_MORPH_VALUES: 'SET_MORPH_VALUES',
    SET_MORPH_LANG: 'SET_MORPH_LANG',
    SET_GROUPED_MORPH_RESULTS: 'SET_GROUPED_MORPH_RESULTS',
    SET_USER_FILES: 'SET_USER_FILES',
    SET_FILTERED_FILES: 'SET_FILTERED_FILES',
    SAVE_CLOUD_SETTINGS: 'SAVE_CLOUD_SETTINGS',
    CLEAR_RESULTS: 'CLEAR_RESULTS'
}
export const Actions = {
    RUN_TEXT_MORPH: 'RUN_TEXT_MORPH',
    RUN_NAKDAN: 'RUN_NAKDAN',
    RUN_POLL: 'RUN_POLL',
    GET_MORPH_TAGGING: 'GET_MORPH_TAGGING',
    GET_OCCURENCES: 'GET_OCCURENCES',
    GET_FREQUENCY: 'GET_FREQUENCY',
    GET_READER: 'GET_READER',
    GET_MORPH_VALUES: 'GET_MORPH_VALUES',
    LIST_FILES: 'LIST_FILES',
    GET_NGRAMS: 'GET_NGRAMS',
    GET_GROUPED_MORPHS: 'GET_GROUPED_MORPHS',
    GET_ADJACENT: 'GET_ADJACENT'

}
export const BlankState = {
    morphApiState: RunStates.NOT_RUN,
    selectedMode: 'menukad',
    fileForApi: null,
    fileType: 'zip',
    processId: null,
    uid: null, //7c7e53c4-d661-4a20-8439-c83a54a3cd27
    noFiles: false,
    invalidFiles: [],
    selectedFileIndex: 0,
    readerContent: {},
    frequencyContent: {},
    readerContentLength: 1000,
    histogramResolution: 50,
    adjacentDirection: 'prev',
    filters: [],
    offset: 0,
    morphTaggingData: [],
    ngramData: [],
    adjacentPrevData: {menukad: [], lexeme:[], baseword: []},
    adjacentNextData: {menukad: [], lexeme:[], baseword: []},
    groupedMorphData: [],
    dataArr: [],
    inputtedTxt: [],
    textForApiCall: '',
    occDisplayCount: 200,
    wordCountItems: [],
    lexCountItems: [],
    baseWordCountItems: [],
    unfilteredCountItems: [],
    unfilteredLexCountItems: [],
    totalWordCountItems: 0,
    totalLexCountItems: 0,
    totalBaseCountItems: 0,
    morphResults: [],
    morphValues: [],
    userFiles: [],
    filteredFiles: [],
    fileWordsAvg: 0,
    fileWordsTotal: 0,
    ngramSize: 3,
    morphLang: 'en',
    hideShemot: process.env.VUE_APP_USER_NAME === 'library',
    cloudSettings: {
        "showSingleResult": true, "cloudColors": "blue", "showTopWordsOnly": true, "numberToDisplay": 50
    },
}

