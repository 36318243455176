<template>
  <div>
    <ag-charts-vue  style="width: 100%; height: 150px;" :options="options"></ag-charts-vue>
  </div>
</template>
<script>
import { AgChartsVue } from 'ag-charts-vue'
export default {
  name: 'Histogram',
  props: ["value", "frequencies"],
  data() {
    return {
      options: null
    }
  },
  components: {
    AgChartsVue
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      let histogramData = []
      this.frequencies.map((index, item) => {
        histogramData.push({
          value: item,
          key: index
        })
      })
      this.options = {
        data: histogramData,
        series: [
          {
            type: 'line',
            xKey: 'value',
            yKey: 'key',
            stroke: '#000000',
            marker: {
              stroke: '#000000',
              fill: '#000000',
            },

          },
        ],
        legend: {
          enabled: false,
        },
        axes: [
          {
            type: 'number',
            position: 'bottom',
            title: { text: '' },
            label: {
              formatter: () => {
                return '';
              },
            },
          },
          {
            type: 'number',
          },
        ]
      }
    }

  },
  computed: {

    hebrew() {
      return this.$settings.hebrew
    },

  },
  watch: {
    frequencies() {
      this.getData()
    }
  }
}
</script>