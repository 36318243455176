<template>
  <div id="search-nikud-popup" centered hide-footer size="md">
    <div class="pattern" ref="patternWrap" v-hotkey.prevent="keymap" :class="{'read-only px-2':readOnly, 'pt-1 pb-2 px-3 mb-2':!readOnly}">
      <b-modal v-if="showImportWord" v-model="showImportWord" hide-backdrop :static="true" :lazy="true" id="import-word"
        size="sm" header-class="bg-background py-1" footer-class="pt-0" :title="hebrew ? 'ייבוא מילה' : 'Import word'"
        @show="onImportShow" @hidden="importFocusOut, wordSelectedFromDD = false, notInList = false"
        :ok-disabled="disableImportOk" @ok="setPattern" :ok-title="hebrew ? 'השתמש' : 'Import'"
        :cancel-title="hebrew ? 'סגור' : 'Cancel'">
        <small>{{ hebrew ? 'הזן מילת חיפוש' : 'Enter search word' }}</small>
        <MainSearch ref="mainSearch" v-if="embedded" :embedded="true" :taOptions="taOptions" 
          :forceHebrew="true"
          @word-selected="importedWordSelected" 
          @word-not-found="notInList = true" @word-typed="(w) => searchWordInput = w" />

        <MainSearch v-else ref="mainSearch" 
          :menukadSearch="true" :forceHebrew="true"
          @word-selected="importedWordSelected" 
          :searchId="'patternTA'"
            />
        <b-form-checkbox v-show="!disableImportOk" class="" id="checkbox-import" v-model="importNikudOnly"
          name="checkbox-import">
          <small>{{ hebrew ? 'התייחס רק לניקוד' : 'Ignore typed letters' }}</small>
        </b-form-checkbox>
      </b-modal>
      
      <div class="word-wrap">
        <div class="d-flex justify-content-between mb-2" :class="{'mt-2':!readOnly}">
          <!-- <div class="my-1" v-if="!readOnly">{{ hebrew ? 'תבנית חיפוש' : 'Search Pattern' }}</div> -->
          <b-btn class="bg-transparent text-primary import-btn mb-1 shadow-none d-none"
            @click="showImportWord = true">
            {{ hebrew ? 'ייבוא מילה' : 'Import word' }}
          </b-btn>
        </div>

        <label v-if="!readOnly" :class="{ 'ltr': !hebrew }">{{ hebrew ? 'אות' : 'Letter' }}</label>
        <span v-for="(n, i) in 8" :key="i" :class="{
          'ltr': !hebrew
        }" class="input-wrap d-inline-block position-relative">
          <input :disabled="readOnly" class="bg-white f-narkis text-center border-0 p-0 text-center" :class="{
            'index-selected': i == currentIndex && wordFocused,
            'word-index-selected': i == currentIndex && !wordFocused && !readOnly,
            'mid-text': tokens[i].letter === '?',
            'text-white': tokens[i].letter.length < 1
          }" maxlength=1 ref="wordPattern" @focus="setCurrentLetter(i); wordFocused = true" @keydown="onWordInput"
            @click="currentIndex = i; setCurrentLetter(i)" @keydown.right="rightArrowPress(i)"
            @keydown.left="leftArrowPress(i)" @keydown.up="upDownArrowPress(i)" @keydown.down="upDownArrowPress(i)"
            :value="tokens[i].letter" />
        </span>
      </div>

      <div class="nikud-wrap" :class="{ 'mt-1': readOnly }">
        <label v-if="!readOnly" :class="{ 'ltr': !hebrew }">{{ hebrew ? 'ניקוד' : 'Nikud' }}</label>
        <span v-for="(n, i) in 8" :key="i" :class="{
          'ltr': !hebrew,
          'dagesh-letter': tokens[i].dagesh === dageshRootOptions.WITH,
          'not-dagesh-letter': tokens[i].dagesh === dageshRootOptions.NONE,
          'bg-white': tokens[i].letter !== '*',
          'index-selected': i == currentIndex && !wordFocused && !readOnly,
          'word-index-selected': i == currentIndex && wordFocused,
          'bg-background': tokens[i].letter === '*'
        }" class="input-wrap d-inline-block position-relative">
          <input ref="nikudPattern" class="d-block text-center border-0 text-center position-relative bg-transparent"
            :class="{
              'position-absolute p-0': isSafari,
              'f-narkis': patternLength(i) !== nikudVals.length && tokens[i].pattern[0] !== nikudVals[0],
              'nikud': patternLength(i) == 1
                && tokens[i].pattern[0] && tokens[i].pattern[0].split('').indexOf('ש') === -1
                && Object.keys(syllables).indexOf(tokens[i].pattern[0]) == -1
                && tokens[i].pattern[0] !== nikudVals[0]
                && tokens[i].pattern[0] !== shuruk
                && tokens[i].pattern[0] !== holamMaleh
                && tokens[i].pattern[0] !== hirikMaleh
                && tokens[i].pattern[0] !== '?',
              'mid-text': tokens[i].pattern[0] && tokens[i].pattern[0].split('').indexOf('ש') === -1,
              'hirik': patternLength(i) == 1 && tokens[i].pattern[0] === hirik,
              'holam': patternLength(i) == 1 && (tokens[i].pattern[0] === holam),
              'small-txt': patternLength(i) === nikudVals.length || tokens[i].pattern[0] === nikudVals[0]
            }" :disabled="readOnly || tokens[i].letter === '*'" :value="patternLength(i) === nikudVals.length ? hebrew ? 'הכל' : 'All' :
  patternLength(i) > 1 ? patternLength(i) :
tokens[i].pattern[0] === nikudVals[0] ? hebrew ? 'ללא' : 'Off' : tokens[i].pattern[0]" @focus="nikudPatternClicked(i)"
            @input="nikudPatternInput" @click="nikudPatternClicked(i)"
            @keydown.right="rightArrowPress(i, 'nikudPattern')" 
            @keydown.left="leftArrowPress(i, 'nikudPattern')"
            @keydown.up="upDownArrowPress(i)" 
            @keydown.down="upDownArrowPress(i)" @keydown.backspace="deletePattern(i)"
            @keydown.delete="deletePattern(i)" />
        </span>
      </div>
      <span v-if="!readOnly">
        <div class="btn-wrap bg-white px-3 py-2 my-2">
          <div class="controls">
            <div class="xborder-bottom mb-0 pb-1">
              <small>{{ hebrew ? 'רצף אותיות כלשהו, יש לסמן ב' : 'Any letter/nikud' }}</small>
              <div class="bg-lb rounded d-inline-block text-center mx-1">*</div>
              <br>
              <small>{{ hebrew ? 'אין חשיבות לאות, יש לסמן ב' : 'Ignore letters' }}</small>
              <div class="bg-lb rounded d-inline-block text-center question mx-1">?</div>
            </div>
            <!--<div class="root-wrap d-none" @click="rootAreaClicked">
          <b-form-group class="mb-0" :disabled="currentLetter==='*'" >
            <b-form-radio  class="d-inline-block" v-model="tokens[currentIndex].rootLetter" name="root-letter-radios" size="sm" :value="dageshRootOptions.BOTH">
              {{hebrew ? 'אות שורשית/שאינה' : 'Root/Standard letter'}}
            </b-form-radio>         
            <b-form-radio class="d-inline-block"  v-model="tokens[currentIndex].rootLetter" name="root-letter-radios" size="sm" :value="dageshRootOptions.WITH">
              {{hebrew ? 'שורשית' : 'Root letter'}}
            </b-form-radio>
            <b-form-radio class="d-inline-block" v-model="tokens[currentIndex].rootLetter" name="root-letter-radios" size="sm" :value="dageshRootOptions.NONE">
              {{hebrew ? 'לא-שורשית' : 'Standard letter'}}
            </b-form-radio>         
          </b-form-group>        
        </div>-->
          </div>
        </div>
        <div class="btn-wrap bg-white px-3 pt-3 pb-2 mt-2 mb-2">
          <div class="nikud-btns ">
            <b-btn :disabled="currentLetter === '*'" variant="background" class="px-0 text-center"
              :class="{ 'selected-syllable': tokens[currentIndex].anynikud, 'ltr': !hebrew }" @click="allClicked"> <span
                class="mt-n1 d-block">{{ hebrew ? 'הכל' : 'All' }}</span>
            </b-btn>
            <b-btn v-for="(item, i) in nikudVals" :key="i" :disabled="currentLetter === '*'" variant="background"
              class="p-0 text-center" :class="{
                'ltr': !hebrew,
                'nikud-btn': i > 0 && item !== holamMaleh && item !== shuruk && item !== hirikMaleh,
                'f-narkis': i > 0,
                'hirik': item === hirik,
                'holam': item === holam,
                'selected-syllable':
                  (selectedNikud.length > 0 && selectedNikud.indexOf(item) > -1)
                  || disabledNikud.indexOf(item) > -1 || tokens[currentIndex].anynikud
              }" @click="addSelectedNikud(item)"><span class="d-block h-100"
                :class="{ 'mt-n2': i > 0 && item !== holam && item !== hirik && item !== hirikMaleh && item !== shuruk && item !== holamMaleh }">
                {{ item === nikudVals[0] ? hebrew ? 'ללא' : 'Off' : item }}
              </span>
            </b-btn>
            <br>
            <b-btn :disabled="!currentLetter.includes('ש') && !selectedNikud.join('').indexOf('שׁ') > -1"
              variant="background" class="f-narkis p-0 mr-0 text-center mb-0" :class="{
                'selected-syllable': selectedNikud.length > 0 && selectedNikud.indexOf('שׁ') > -1,
                'ltr': !hebrew
              }" @click="addSelectedNikud('שׁ')"> <span class="mt-n1 d-block">שׁ</span>
            </b-btn>
            <b-btn :disabled="!currentLetter.includes('ש') && !selectedNikud.join('').includes('ש')"
              variant="background" @click="addSelectedNikud('שׂ')" :class="{
                'selected-syllable': selectedNikud.length > 0 && selectedNikud.indexOf('שׂ') > -1,
                'ltr': !hebrew
              }" class="f-narkis p-0 text-center mb-0"> <span class="mt-n1 d-block">שׂ</span>
            </b-btn>
          </div>
          <div class="syllables mt-2 border-bottom mb-2 pb-2">
            <small class="d-block mb-1">{{ hebrew ? 'צלילים' : 'Syllables' }}</small>
            <b-btn v-for="item in Object.keys(syllables)" :key="item" variant="background"
              :disabled="currentLetter === '*'" class="p-0 text-center" :class="{
                'selected-syllable': selectedNikud.includes(item)
                  || selectedNikud.length == nikudVals.length
                  || syllables[item].every(v => selectedNikud.includes(v)),
                'ltr': !hebrew
              }" @click="addSelectedNikud(item)"> {{ item }}
            </b-btn>
          </div>
          <div class="dagesh pl-3" @click="dageshAreaClicked">
            <b-form-group class="mb-0" :disabled="currentLetter === '*'">
              <b-form-radio class="d-inline-block" v-model="tokens[currentIndex].dagesh" name="dagesh-radios" size="sm"
                :value="dageshRootOptions.BOTH">
                {{ hebrew ? 'עם/ללא דגש' : 'Dagesh/No' }}
              </b-form-radio>
              <b-form-radio class="d-inline-block" v-model="tokens[currentIndex].dagesh" name="dagesh-radios" size="sm"
                :value="dageshRootOptions.WITH">
                {{ hebrew ? 'עם דגש' : 'Dagesh' }}
              </b-form-radio>
              <b-form-radio class="d-inline-block" v-model="tokens[currentIndex].dagesh" name="dagesh-radios" size="sm"
                :value="dageshRootOptions.NONE">
                {{ hebrew ? 'ללא דגש' : 'Without' }}
              </b-form-radio>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex justify-content-end pt-1">
          <b-btn id="clear-btn" class="bg-transparent text-underline border-0 text-primary mb-1 clear-btn"
            :disabled="JSON.stringify(tokens) === JSON.stringify(blankTokens)" variant="link" @click="clearData">
            {{ hebrew ? 'ניקוי' : 'Clear' }}
          </b-btn>
          <b-btn id="search-btn" variant="primary" class="mb-1" @click="searchClicked" :disabled="searchDisabled()">
            {{ hebrew ? 'חיפוש' : 'Search' }}
          </b-btn>
        </div>
      </span>
    </div>
  </div>
</template>
<script>
const hebrewKeyboard = {
  'e': '\u05E7', 'r': '\u05E8', 't': '\u05D0',
  'y': '\u05D8', 'u': '\u05D5', 'i': '\u05DF',
  'o': '\u05DD', 'p': '\u05E4', 'a': '\u05E9',
  's': '\u05D3', 'd': '\u05D2', 'f': '\u05DB',
  'g': '\u05E2', 'h': '\u05D9', 'j': '\u05D7',
  'k': '\u05DC', 'l': '\u05DA', ';': '\u05E3',
  'z': '\u05D6', 'x': '\u05E1', 'c': '\u05D1',
  'v': '\u05D4', 'b': '\u05E0', 'n': '\u05DE',
  'm': '\u05E6', ',': '\u05EA', '.': '\u05E5',
}
import { NIKUD, stringWithoutNikud }
  from "@/js/commonHebrew"
import MainSearch from '@/components/MainSearch'
//import _ from "lodash"

let opener = null
export default {
  name: 'SearchPattern',
  components: { MainSearch },
  props: ["wordForPattern", "taOptions", "savedTokens", "readOnly"],
  data() {
    return {
      embedded: false,
      showImportWord: false,
      importNikudOnly: true,
      wordSelectedFromDD: false,
      searchWordInput: '',
      searchedWord: '',
      notInList: false,
      dageshRootOptions: {
        BOTH: 'M',
        WITH: 'Y',
        NONE: 'N'
      },
      hirik: NIKUD.HIRIQ,
      hirikMaleh: NIKUD.HIRIQ_MALEH,
      holam: NIKUD.HOLAM,
      holamMaleh: NIKUD.HOLAM_MALEH,
      shuruk: NIKUD.SHURUK,
      currentLetter: '',
      nikudVals: [
        'X',
        NIKUD.QAMATZ,
        NIKUD.QAMATZ_QATAN,
        NIKUD.PATAH,
        NIKUD.TSERE,
        NIKUD.SEGOL,
        NIKUD.HIRIQ,
        //NIKUD.HIRIQ_MALEH,
        NIKUD.SHEVA,
        NIKUD.HOLAM,
        //NIKUD.HOLAM_MALEH,
        //NIKUD.SHURUK,
        NIKUD.QUBUTZ,
        NIKUD.HATAF_QAMATZ,
        NIKUD.HATAF_PATAH,
        NIKUD.HATAF_SEGOL,
      ],
      syllables: {
        'A': [
          NIKUD.HATAF_PATAH,
          NIKUD.PATAH,
          NIKUD.QAMATZ,
        ],
        'E': [
          NIKUD.HATAF_SEGOL,
          NIKUD.TSERE,
          NIKUD.SEGOL
        ],
        'I': [NIKUD.HIRIQ],
        'O': [
          NIKUD.HOLAM,
          NIKUD.QAMATZ_QATAN,
          NIKUD.HATAF_QAMATZ
        ],
        'U': [NIKUD.QUBUTZ]
      },
      disabledNikud: [],
      currentIndex: 0,
      selectedNikud: [],
      notAllowedDagesh: ['ח', 'ע', 'ם', 'ן', 'ץ'],    //:disabled="notAllowedDagesh.indexOf(currentLetter)>-1"  
      blankTokens: [],
      tokens: [],
      wordFocused: false,
      isSafari: false
    }
  },
  created() {
    for (let i = 0; i < 8; i++) {
      this.tokens.push({ letter: '', pattern: [], dagesh: this.dageshRootOptions.BOTH, anynikud: false });
    }
    this.blankTokens = JSON.parse(JSON.stringify(this.tokens))
    window.addEventListener('beforeunload', this.closeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.embedMessageHandler)
  },
  mounted() {
    this.$nextTick(() => {
      if (this.wordForPattern && this.wordForPattern !== '') {
        this.searchedWord = this.wordForPattern
        this.setPattern()
      } 
      this.applySavedTokens()
    })
    opener = window.opener || window.parent
    if (opener) {
      window.addEventListener('message', this.embedMessageHandler)
      opener.postMessage({ message: 'NIKUD_SEARCH_LOADED' }, '*')
    }
  },
  methods: {
    applySavedTokens() {
      if (this.savedTokens && this.savedTokens.length > 0) {
        setTimeout(() => {
          this.tokens = this.savedTokens       
        this.$refs['wordPattern'][this.currentIndex].focus()
        }, 10)
        
      }        
      else {
        this.tokens = this.blankTokens
      }
    },
    embedMessageHandler(event) { //event param
      if (event.data.message === 'NIKUD_SEARCH_DATA') {
        this.embedded = true
        document.body.classList.add('embedded')
        //var links= document.getElementsByTagName('a')
        //for (var i=0; i<links.length; i++) {
        //  links[i].setAttribute('target', '_blank') //open links in new window
        //}
      }
      if (event.data.message === 'NIKUD_SEARCH_DATA' && event.data.data.data) {
        let blankTokens = JSON.parse(JSON.stringify(this.blankTokens))
        let receivedData = JSON.parse(JSON.stringify(event.data.data.data))
        receivedData.map((item, index) => {
          let obj = item
          obj.dagesh = this.dageshRootOptions.BOTH
          if (item.wildcard) {
            obj.letter = '*'
            obj.pattern = []
            obj.anynikud = false
          } else {
            obj.anynikud = item.anynikud
            obj.dagesh = item.dag
            obj.letter = item.letter
            obj.pattern = obj.nikud
          }
          if (item.anyletter)
            obj.letter = '?'
          if (obj.pattern.indexOf('!') >= 0) {
            obj.pattern.splice(obj.pattern.indexOf('!'), 1)
            obj.pattern.push(this.nikudVals[0])
          }
          if (item.anynikud) {
            obj.pattern = this.nikudVals.slice()
          }
          if (item.shin === '\u05C1')
            obj.pattern.push('שׁ')
          if (item.shin === '\u05C2')
            obj.pattern.push('שׂ')
          if (item.shin)
            obj.letter = 'ש'
          //letter: '', pattern: [], dagesh: this.dageshRootOptions.BOTH, anynikud: false
          delete obj.dag
          delete obj.anyletter
          delete obj.nikud
          blankTokens[index] = obj
        })
        setTimeout(() => {
          this.$refs.wordPattern[0].focus()
        }, 50)
        this.tokens = JSON.parse(JSON.stringify(blankTokens))
      }
    },
    searchDisabled() {
      let letters = this.tokens.filter(item => {
        return (item.letter !== '' && item.letter !== '*') ||
          item.pattern.length > 0 ||
          item.dagesh !== 'M' ||
          //item.rootLetter !== 'M' ||
          item.anynikud
      })
      return letters.length === 0
    },
    closeHandler() {
      if (this.embedded) {
        //close embedded popup mode
        opener.postMessage({ message: 'NIKUD_SEARCH_CLOSE' }, '*')
      }
    },
    importedWordSelected(val) {
      this.searchedWord = val
      this.wordSelectedFromDD = true
      this.notInList = false
    },
    setPattern() {
      this.tokens = JSON.parse(JSON.stringify(this.blankTokens))
      this.selectedNikud = []
      this.disabledNikud = []
      this.currentLetter = ''
      this.currentIndex = 0
      let searchedWord = this.searchedWord
      let shinReplacement = this.importNikudOnly ? '' : 'ש'
      if (searchedWord && searchedWord !== '') { //this.searchedWord
        let splitWord = searchedWord.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ|])/)
        splitWord.forEach((item, index) => {
          if (item.includes('שׁ')) {
            //  this.tokens[index].pattern.push ('שׁ')
            item = item.replace('שׁ', shinReplacement)
          }
          if (item.includes('שׂ')) {
            //  this.tokens[index].pattern.push ('שׂ')
            item = item.replace('שׂ', shinReplacement)
          }
          if (this.importNikudOnly) {
            if (item.length >= 1 && item.includes('ו')) {
              //  this.$refs.wordPattern[index].value = 'ו'
              this.tokens[index].letter = 'ו'
            }
            if (item.includes('י')) {
              //this.$refs.wordPattern[index].value = 'י'
              this.tokens[index].letter = 'י'
            }
          } else {
            //this.$refs.wordPattern[index].value = stringWithoutNikud(item)
            this.tokens[index].letter = stringWithoutNikud(item)
          }
          let itemArr = item.split('')
          let dageshIndex = itemArr.indexOf(NIKUD.DAGESH)
          if (dageshIndex >= 0) {
            this.tokens[index].dagesh = this.dageshRootOptions.WITH
            itemArr.splice(dageshIndex, 1)
          }
          item = itemArr.join('')
          if (item.replace(/[א-ת]/g, '').length == 0) {
            item = this.nikudVals[0]
          }

          let foundPatternIndex = this.nikudVals.indexOf(item.replace(/[א-ת]/g, ''))
          if (foundPatternIndex === -1)
            foundPatternIndex = 0
          this.tokens[index].pattern.push(this.nikudVals[foundPatternIndex])
        })
        this.$refs.wordPattern[this.currentIndex].focus()
      }
      //if (run && JSON.stringify(this.tokens) !== JSON.stringify(this.blankTokens)) {
      // this.searchClicked()
      //}     
    },
    onImportShow() {
      this.wordSelectedFromDD = false
      setTimeout(() => {
        if (document.getElementById('search-input-dd'))
          document.getElementById('search-input-dd').focus()
      }, 50)
    },
    importFocusOut() {
      this.$refs['wordPattern'][0].focus()
    },
    closeImportWord() {
      //this.setPattern()
      this.showImportWord = false
    },
    clearData() {
      //this.$store.commit('SAVE_FILTER', [])
      this.tokens = JSON.parse(JSON.stringify(this.blankTokens))
      this.selectedNikud = []
      this.disabledNikud = []
      this.currentLetter = ''
      this.$refs['wordPattern'][0].focus()
      for (let i = 0; i < 8; i++) {
        this.$refs.wordPattern[i].value = ''
      }
      if (this.embedded) {
        opener.postMessage({ message: 'NIKUD_CLEAR_PATTERN' }, '*')
      } else {
        this.$emit('clear-pattern')
      }
    },
    dageshAreaClicked() {
      setTimeout(() => {
        this.$refs['wordPattern'][this.currentIndex].focus()
      }, 150)
    },
    patternLength(i) {
      let disabledNikud = []
      Object.keys(this.syllables).map(item => {
        if (this.tokens[i].pattern.indexOf(item) > -1) {
          disabledNikud = disabledNikud.concat(this.syllables[item])
        }
      })
      let selectedPattern = Array.from(new Set(this.tokens[i].pattern.concat(disabledNikud)))
      selectedPattern = selectedPattern.filter(e => { return Object.keys(this.syllables).indexOf(e) == -1 })
      if (this.tokens[i].pattern.length === 1 &&
        Object.keys(this.syllables).indexOf(this.tokens[i].pattern[0]) > -1) {
        return 1
      }
      return selectedPattern.length
    },
    searchClicked() {
      //this.$store.commit('SAVE_FILTER', this.tokens)
      let tokensReversed = [...this.tokens].reverse()
      let i = 0
      while (
        tokensReversed[i].pattern.length == 0
        && tokensReversed[i].letter.length == 0
        //&& tokensReversed[i].rootLetter === this.dageshRootOptions.BOTH
        && tokensReversed[i].dagesh === this.dageshRootOptions.BOTH) {
        i++;
      }
      let indexToFill = this.tokens.length - i
      let searchItems = []
      this.tokens.map((obj, index) => {
        if (index < indexToFill) {
          if (obj.letter.length === 0) {
            obj.letter = '?'
            //this.$refs.wordPattern[index].value = '?'
          }
          if (obj.pattern.length === 0 && obj.letter !== '*') {
            this.tokens[index].pattern = this.nikudVals.slice()
            this.tokens[index].anynikud = true
          }

          let searchItem = {}
          if (obj.letter === '*') {
            if ((this.tokens[index - 1] && this.tokens[index - 1].letter !== '*') || index == 0)
              searchItem.wildcard = true
          } else {
            if (obj.letter === '?') {
              searchItem.letter = ''
              searchItem.anyletter = true
            } else {
              searchItem.letter = obj.letter
            }
            searchItem.dag = obj.dagesh
            //  searchItem.rootLetter = obj.rootLetter
            searchItem.nikud = []
            if (obj.anynikud) {
              searchItem.anynikud = true
            } else {
              obj.pattern.map(nikudItem => {
                if (nikudItem === this.nikudVals[0])
                  searchItem.nikud.push('!')
                else if (Object.keys(this.syllables).indexOf(nikudItem) > -1)
                  searchItem.nikud = searchItem.nikud.concat(this.syllables[nikudItem])
                //TODO: handle shin/sin  
                else if (nikudItem === 'שׁ') {
                  if (obj.pattern.indexOf('שׂ') < 0)
                    searchItem.shin = "\u05C1"
                }
                else if (nikudItem === 'שׂ') {
                  if (obj.pattern.indexOf('שׁ') < 0)
                    searchItem.shin = "\u05C2"
                }
                else
                  searchItem.nikud.push(nikudItem)
              })
            }
          }
          searchItems.push(searchItem)
          searchItems = searchItems.filter(value => Object.keys(value).length !== 0)
        }
      })
      if (this.embedded) {
        opener.postMessage({ message: 'NIKUD_SEARCH_PATTERN', data: searchItems }, '*')
      } else {
        this.$emit('search-object', {patternObj: searchItems, tokens: this.tokens})      
      }
    },
    allClicked() {
      this.tokens[this.currentIndex].anynikud = !this.tokens[this.currentIndex].anynikud
      if (this.tokens[this.currentIndex].anynikud) {
        this.tokens[this.currentIndex].pattern = this.nikudVals.slice()
        // this.tokens[this.currentIndex].pattern.shift()        
      } else {
        this.tokens[this.currentIndex].pattern = []
      }
      this.selectedNikud = this.tokens[this.currentIndex].pattern
      this.updateDisabledNikud()
      this.$refs.nikudPattern[this.currentIndex].focus()
    },
    nikudPatternInput(event) {
      if (event.inputType !== 'deleteContentBackward'
        && event.inputType !== 'deleteContentForward'
      ) {
        //prevent input
        this.$refs.nikudPattern[this.currentIndex].value = this.$refs.nikudPattern[this.currentIndex].value.slice(0, -1)
      }
    },
    setCurrentLetter(index) {
      this.currentLetter = this.tokens[index].letter
      this.currentIndex = index
      this.selectedNikud = this.tokens[index].pattern
      this.updateDisabledNikud()
    },
    onWordInput(event) {
      if (!event.key.includes('Arrow')) {
        let index = this.currentIndex
        let typedLetter = ''
        if ((event.shiftKey && event.key === '?') || event.key === '?') {
          typedLetter = '?'
        }
        else if ((event.shiftKey && event.key === '*') || event.key === '*') {
          typedLetter = '*'
        }
        else if (!event.key.match(/[א-ת]/i)) {
          typedLetter = hebrewKeyboard[event.key.toLowerCase()]
          if (!typedLetter)
            typedLetter = ''
        }
        else if (event.key.match(/[א-ת]/i)) {
          typedLetter = event.key
        }
        if (event.key !== 'Backspace' && event.key !== 'Delete') {
          if (typedLetter !== '') {
            this.currentLetter = typedLetter
            this.tokens[index].letter = this.currentLetter
            this.$refs['wordPattern'][index].value = this.currentLetter
            if (this.currentLetter === '*') {
              this.tokens[index].pattern = []
              this.tokens[index].dagesh = this.dageshRootOptions.BOTH
              //  this.tokens[index].rootLetter = this.dageshRootOptions.BOTH
            }
          }
          if (typedLetter.length > 0) {
            //this.leftArrowPress()
          }
        }
        else {
          this.currentLetter = ''
          this.tokens[index].letter = ''
          this.$refs.wordPattern[index].value = ''
        }
      }
    },
    deletePattern(index) {
      this.tokens[index].pattern = []
      this.selectedNikud = []
      this.tokens[index].anynikud = false
      this.updateDisabledNikud()
    },
    leftArrowPress(index, nikudPattern) {
      if (!index) index = this.currentIndex
      let refObj = this.$refs.wordPattern
      if (nikudPattern)
        refObj = this.$refs.nikudPattern
      if (index < this.tokens.length - 1) {
        if (!nikudPattern || this.$refs.wordPattern[index + 1].value !== '*') {
          if (this.$refs.wordPattern[index + 1]) {
            refObj[index + 1].focus()
            this.currentIndex = index + 1
          }
        } else {
          let temp = index + 1
          while (this.$refs.wordPattern[temp].value === '*') {
            if (temp < this.tokens.length - 1)
              temp++
          }
          index = temp
          this.currentIndex = index
          refObj[index].focus()

        }
      }
    },
    rightArrowPress(index, nikudPattern) {
      let refObj = this.$refs.wordPattern
      if (nikudPattern)
        refObj = this.$refs.nikudPattern
      if (index > 0) {
        if (!nikudPattern || this.$refs.wordPattern[index - 1].value !== '*') {
          this.currentIndex = index - 1
        } else {
          let temp = index - 1
          while (this.$refs.wordPattern[temp].value === '*') {
            if (temp > 0)
              temp--
          }
          index = temp
          this.currentIndex = index
        }
        refObj[this.currentIndex].focus()
      }
    },
    upDownArrowPress(i) {
      if (this.wordFocused) {
        this.$refs.nikudPattern[i].focus()
      } else {
        this.$refs['wordPattern'][i].focus()
      }
    },
    nikudPatternClicked(i) {
      if (this.tokens[i].letter !== '*') {
        this.wordFocused = false
        this.currentIndex = i
        this.selectedNikud = this.tokens[this.currentIndex].pattern
        this.currentLetter = this.tokens[this.currentIndex].letter
        this.updateDisabledNikud()
      }
    },
    updateDisabledNikud() {
      this.disabledNikud = []
      Object.keys(this.syllables).map(item => {
        if (this.tokens[this.currentIndex].pattern.indexOf(item) > -1) {
          this.disabledNikud = this.disabledNikud.concat(this.syllables[item])
        }
      })
    },
    addSelectedNikud(val) {
      if (this.currentLetter !== '*') {
        this.$refs.nikudPattern[this.currentIndex].focus()
        let existingIndex = this.selectedNikud.indexOf(val)
        if (existingIndex > -1) { //if exists in pattern, remove
          this.tokens[this.currentIndex].pattern.splice(existingIndex, 1)
          this.updateDisabledNikud()
        }
        else {
          var syl = ''
          let indexFound = -1
          //added item that is part of a syllable
          Object.values(this.syllables).map((arr, index) => {
            if (arr.indexOf(val) > -1) {
              indexFound = arr.indexOf(val)
              syl = Object.keys(this.syllables)[index]
            }
          })
          if (indexFound > -1 && this.selectedNikud.indexOf(syl) > -1) {
            //if syllable already set, we need to remove above value, unset syllable but keep remaining items
            this.tokens[this.currentIndex].pattern.splice(this.selectedNikud.indexOf(syl), 1)
            let nikudValsToAdd = this.syllables[syl]
            nikudValsToAdd.map((val, index) => {
              if (index !== indexFound && !this.selectedNikud.indexOf(val) > -1) {
                this.tokens[this.currentIndex].pattern.push(val)
              }
            })
          }
          else {
            //if syllable clicked but not in pattern, remove syllable values from pattern if all there
            if (Object.keys(this.syllables).indexOf(val) > -1
              && this.syllables[val].every(v => this.selectedNikud.includes(v))) {
              this.syllables[val].map(item => {
                let indexToRemove = this.selectedNikud.indexOf(item)
                this.tokens[this.currentIndex].pattern.splice(indexToRemove, 1)
              })
            } else {
              //add item to pattern
              this.tokens[this.currentIndex].pattern.push(val)
            }
          }
          // }  
        }
        this.tokens[this.currentIndex].anynikud = this.patternLength(this.currentIndex) === this.nikudVals.length
        Object.values(this.syllables).map((arr, index) => {
          if (JSON.stringify(arr.sort()) === JSON.stringify(this.tokens[this.currentIndex].pattern.sort())) {
            this.tokens[this.currentIndex].pattern = [Object.keys(this.syllables)[index]]
          }
        })
        this.updateDisabledNikud()
        this.selectedNikud = this.tokens[this.currentIndex].pattern
      }
      //this.searchClicked()  
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    keymap() {
      return {
        'enter': () => {
          if (this.showImportWord) {
            if (this.wordSelectedFromDD) {
              this.setPattern()
              this.closeImportWord()
              this.importFocusOut()
            }
          }
          else if (JSON.stringify(this.tokens) !== JSON.stringify(this.blankTokens)) {
            this.$refs['wordPattern'][this.currentIndex].focus()
            this.searchClicked()
          }
        }
      }
    },
    disableImportOk() {
      return false//this.notInList || this.searchWordInput === ''
    },
    hebrew() {
      return this.$settings.hebrew
    }
  },
  watch: {
    savedTokens() {
      this.applySavedTokens()
    },
    hebrew() {
      if (this.embedded) {
        if (this.hebrew) {
          document.body.classList.add('he')
        } else {
          document.body.classList.remove('he')
        }
        if (this.embedded) {
          opener.postMessage({ message: 'NIKUD_SEARCH_LANGUAGE_SWITCH', data: this.hebrew ? 'he' : 'en' }, '*')
        }
      }
    }
  }
}  
</script>
<style scoped lang="scss">
.pattern {
  border-radius: 4px;
  background: #d6ecff;
  &.read-only {
    background: #e3e3e3;
    zoom: 0.9;
  }

  .import-btn {
    border: solid 1px #c8c8c8;

    &:hover {
      background: #B9DFFF !important;
      border: solid 1px #B9DFFF;
    }
  }

  .clear-btn {
    font-size: 13px;
  }

  .word-wrap,
  .nikud-wrap {
    label {
      font-size: 12px;
      width: 39px;
      margin-left: -4px;
      padding: 6px 0;
      text-align: center;
      height: 31px;
      vertical-align: bottom;
      color: #808080;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #b9dfff;

      &.ltr {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    .input-wrap {
      &.index-selected {
        background: white url('../assets/img/Input_a.svg');
      }

      &.word-index-selected {
        //box-shadow: 0px 0px 0px 1px rgba(0, 126, 229, 0.5) inset!important;
        background: url('../assets/img/Input_b.svg');

        &.bg-background {
          background: #f6f6f6;
          //  border: 1px solid var(--primary);
        }
      }

      margin-right: 3.8px;
      z-index: 1;
      overflow: hidden;
      border-radius: 4px;
      width: 32px;
      height: 32px;

      &:first-of-type {
        margin-right: 0;
      }

      &.ltr {
        &:last-of-type {
          margin-right: 0;
        }

        &:first-of-type {
          margin-right: 3.8px;
          margin-left: 0;
        }
      }

      &.not-dagesh-letter,
      &.dagesh-letter {
        &:before {
          position: absolute;
          content: '';
          font-size: 30px;
          right: 4px;
          bottom: 4px;
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background: var(--primary);
          z-index: 2;
        }
      }

      &.not-dagesh-letter {
        &:before {
          background: rgba(0, 126, 229, 0.38);
        }
      }

      &.no-root,
      &.root {
        &:before {
          position: absolute;
          content: '';
          width: 15px;
          height: 15px;
          background: var(--primary);
          transform: rotate(45deg);
          bottom: -6px;
          right: -6px;
        }
      }

      &.no-root {
        &:before {
          background: rgba(0, 126, 229, 0.38);
        }
      }
    }

    input {
      border-radius: 4px;
      margin-right: 4px;
      width: 32px;
      height: 32px;

      &.multiple-items {
        &:before {
          position: absolute;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: var(--primary);
          bottom: 3px;
          right: 3px;
        }
      }

      &:focus,
      &:focus-within,
      &:active {
        outline: none;
      }

      &.index-selected:not(.nikud) {
        background: url('../assets/img/Input_a.svg');
      }

      &.word-index-selected:not(.nikud) {
        background: url('../assets/img/Input_b.svg');
      }

      &:first-of-type {
        margin-right: 0;
      }

      &.small-txt {
        font-size: 12px;
      }

      &.f-narkis {
        font-size: 22px;
        line-height: 34px;

        &.mid-text {
          font-size: 19px;
        }

        &.smaller-text {
          font-size: 17px;
        }

        &.nikud {
          font-size: 40px;
          padding-bottom: 30px;
          width: 30px;
          margin: auto;
          top: -7px;
          line-height: 100;

          &.hirik {
            font-size: 90px;
            padding-bottom: 69px;
            top: -24px;
          }

          &.holam {
            padding-bottom: 0;
            top: 0;
            font-size: 90px;
            padding-top: 38px;
          }
        }
      }
    }

  }

  .nikud-wrap {
    input {
      caret-color: transparent;
    }
  }

  .btn-wrap {
    border-radius: 6px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);

    button {
      &.f-narkis {
        font-size: 20px;
      }

      width: 34px;
      height: 34px;
      margin-left: 8px;

      &.ltr {
        margin-right: 8px;
        margin-left: 0;
      }

      
      &:nth-child(15){ //,&:first-of-type &:nth-child(8),
        @media (min-width: 992px) {
          margin-right: 0;
          margin-left: 0;
        }
      }

      margin-bottom: 9px;
      line-height: 34px;

      &.selected-syllable {
        border-color: var(--primary);
        position: relative;

        &:before {
          content: "\f058";
          position: absolute;
          background: white;
          border-radius: 50%;
          top: -6px;
          right: -6px;
          font-weight: 900;
          color: var(--primary);
          font-size: 14px;
          line-height: 14px;
          font-family: 'Font Awesome 5 Free';
        }
      }
    }
  }

  .nikud-btns {
    button {

      //vertical-align: top;
      &.f-narkis {
        font-size: 22px;
        vertical-align: top;

        &.nikud-btn {
          font-size: 40px;

         /*  &:nth-child(7),
          &:nth-child(14) {
            margin-left: 0;
            margin-right: 0;
          } */

          line-height: 0;

          &.hirik {
            font-size: 70px;

            span {
              margin-top: -25px;
            }
          }

          &.holam {
            font-size: 70px;

            span {
              margin-top: 30px;
            }
          }
        }
      }

      font-size: 15px;
    }
  }

  .syllables {
    button {
      font-size: 18px;
      line-height: 18px;
    }
  }

  .controls {
    .bg-lb {
      height: 16px;
      width: 16px;
      vertical-align: middle;
      line-height: 20px;
      font-size: 17px;
      background: #d6ecff;

      &.question {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

.pattern.safari .nikud-wrap input.nikud {
  font-size: 28px;
  top: -10px;
  line-height: 1;

  &.holam {
    top: 0;
    font-size: 50px;
  }
}

.pattern.safari .word-wrap input.f-narkis {
  line-height: 1;
  position: absolute;
  top: 0;
}

.pattern.safari .nikud-btns button.f-narkis.nikud-btn span {
  margin-right: 5px;
}

.pattern.firefox .nikud-btns button.f-narkis.nikud-btn {
  font-family: roboto;
  font-size: 44px;
}

.pattern.firefox .nikud-btns button.f-narkis.nikud-btn.holam span {
  margin-top: 24px;
  font-size: 54px;
}

.pattern.firefox .nikud-btns button.f-narkis.nikud-btn.hirik span {
  margin-top: 1px;
  font-size: 54px;
  margin-left: 7px;
}
</style>  
<style lang="scss">
.root-wrap {
  margin-right: -10px;
}

.dagesh {
  margin-right: -7px;
}

.dagesh,
.root-wrap {

  .custom-control-label:after,
  .custom-control-label:before {
    right: -17px;
  }

  .custom-radio.b-custom-control-sm,
  .input-group-sm .custom-radio {
    padding-left: 7px;
    padding-right: 25px;
  }

  .custom-radio .custom-control-input:checked~.custom-control-label:after {
    box-shadow: inset 0 0 0 5px var(--primary);
    border-radius: 50%;
    background: white;
  }
}

.root-wrap {

  .custom-radio.b-custom-control-sm,
  .input-group-sm .custom-radio {
    padding-left: 0px;

  }
}
</style>  
<style lang="scss">
#import-word {
  .typeahead .spinner-wrap {
    left: 5px;

    .v-spinner {
      top: 20%;
    }
  }

  &.modal {
    position: relative;
    left: -15px;
  }

  .modal-sm {
    max-width: 350px;
    width: 350px;
    top: -15px;

    @media (max-width: 991px) {
      top: 0px;
      width: 100%;
    }

    .modal-body {
      height: 125px;
      //overflow: hidden;        
    }

    .modal-title {
      font-size: 16px;
    }

    .close {
      display: none;
    }
  }

  .search-input-wrap {
    padding: 0 !important;
    margin-bottom: 10px !important;
  }

  .main-search {
    background-color: transparent;
    border-radius: 0;
    margin-top: 0;

    label {
      display: none !important;
    }

    input {
      border-radius: 4px;
      width: 100%;
      //border: solid 1px #000000 !important;
    }

    .btn.ta-search-btn {
      display: none !important;
    }
  }

  .autocomplete-results {
    max-height: 120px;
    max-height: 75px;
  }
}

.he #import-word.modal {
  left: auto;
  right: -15px;
}
</style>