<template>
 <div class="text-edit">
    <text-editor></text-editor>
 </div>
</template>
<script>
    import TextEditor from '@/components/TextEditor'
    export default {
        name: 'TextEdit',
        components: {TextEditor},
        computed: {        
          
        }
    } 
</script> 