import Vue from 'vue'
import Router from 'vue-router'
import TextEdit from './views/TextEdit.vue'
import FilterResults from './views/FilterResults.vue'
//import Corpus from './views/Corpus.vue'
import UploadZip from './views/UploadZip'
import UploadAnalysis from './views/UploadAnalysis'
import UploadFile from './views/UploadFile'
//import AnalyzeCorpus from './views/AnalyzeCorpus'
import Library from './views/Library'

Vue.use(Router)


export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: process.env.VUE_APP_USER_NAME === 'library' || process.env.VUE_APP_USER_NAME === 'shas' ? FilterResults : TextEdit
    },
    {
      path: '/text-edit',
      name: 'edit',
      component: TextEdit
    },
    {
      path: '/upload-analysis',
      name: 'analysis',
      component: UploadAnalysis
    },
    {
      path: '/upload-zip',
      name: 'zip',
      component: UploadZip
    },
    {
      path: '/filter-results',
      name: 'results',
      component: FilterResults
    },

/*     {
      path: '/import-corpus',
      name: 'Corpus',
      component: Corpus
    }, */
    {
      path: '/upload-file',
      name: 'UploadFile',
      component: UploadFile
    }, /* {
      path: '/analyze-corpus',
      name: 'AnalyzeCorpus',
      component: AnalyzeCorpus
    }, */
    {
      path: '/library',
      name: 'Library',
      component: Library
    }
  ]
})
