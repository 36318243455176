<template>
    <div class="upload-file h-100">
        <b-row class="zip-files h-100">
            <b-col >
                <text-editor :hideTextArea="true"></text-editor>

            </b-col>
           <!--  <b-col>
                <uploaded-file-list></uploaded-file-list>
            </b-col> -->
        </b-row>
    </div>
</template>
<script>
import TextEditor from '@/components/TextEditor'
//import UploadedFileList from '@/components/UploadedFileList.vue'

export default {
    name: 'UploadFile',
    components: { TextEditor },
}    
</script> 