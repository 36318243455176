<template>
    <b-container>
        <b-row>
            <b-col :cols="nGramsDisplay ? '3' : '7'">
                <span style="font-size:18px;" :dir="hebrew ? 'rtl' : 'ltr'">
                    <span v-if="tableData.length > 0">
                        <span v-if="filters.length > 0"> (</span>
                        {{ hebrew ? 'מציג' : 'Displaying' }}
                        {{ (currentPage * 20) + 1 }}-{{ (currentPage * 20) + 20 > totalResults ? totalResults :
                            (currentPage * 20)
                            +
                            20
                        }}
                        {{ hebrew ? 'מתוך' : 'of' }}
                        {{ totalResults }}
                        <span v-if="filters.length > 0">)</span> <span v-else>{{ hebrew ? 'תוצאות' : 'results' }}</span>
                    </span>
                    <span v-else>{{ hebrew ? 'מציג 0 תוצאות' : 'No results' }}</span>
                </span>

            </b-col>
            <b-col :cols="nGramsDisplay ? '9' : '5'"
                class="justify-content-end d-flex align-items-baseline">
                <span>
                    <span v-if="nGramsDisplay">
                        <small class="ngram-notification border py-1 px-2 ml-3 bg-white border">
                            <i class="fas fa-info position-relative px-1" style="top:-2px;"></i>
                            {{
                                hebrew ? 'טבלה זו אינה מושפעת מהסינונים ומההגדרות למעלה' :
                                'This table is not affected by the above filters' }}
                        </small>
                        <nGramSlider></nGramSlider>
                    </span>
                    <download-results :items="tableData" :fields="fields" :nikud="withNikud"></download-results>
                    <b-form-checkbox v-if="!groupDisplay" v-model="withNikud" name="check-button" switch
                        class="d-inline-block mr-3 pr-5 border-left border-right">
                        <strong class="pl-2 ml-1">ניקוד</strong>
                    </b-form-checkbox>
                </span>
                <b-btn class="bg-transparent border-0 shadow-none px-2" variant="link" :disabled="currentPage === 0"
                    @click="newPage('prev')"><i class="fa fa-angle-right" aria-hidden="true"></i></b-btn>
                <b-btn class="bg-transparent border-0 shadow-none px-2" variant="link"
                    :disabled="currentPage + 1 >= totalPages" @click="newPage('next')"><i class="fa fa-angle-left"
                        aria-hidden="true"></i></b-btn>
            </b-col>
        </b-row>

        <div class="table-wrap border mt-2 mb-4">
            <b-table :items="tableData" :fields="fields" :current-page="currentPage" sticky-header
                show-empty small empty-text="">
<!--                 <template #table-busy>
     :busy="tableBusy"
                    <div class="text-center my-2">
                        <spinner class="align-middle"></spinner>
                    </div>
                </template> -->
                <template #cell(show_details)="row" v-if="contextDisplay">
                    <b-button class="bg-transparent border-0 shadow-none px-1" size="sm" @click="row.toggleDetails">
                        <i v-if="row.detailsShowing" class="far fa-minus-square"></i>
                        <i v-else class="far fa-plus-square"></i>
                    </b-button>
                </template>
                <template #row-details="row" v-if="contextDisplay">
                    <span class="py-1 d-block f-narkis" 
                        style="padding-right:35px;" 
                        v-html="withNikud ? row.item.fullExpression : removeNikud(row.item.fullExpression)">
                    </span>
                </template>
                <template v-else #row-details="row">
                    <span class="f-narkis text-muted">
                        <i class="fas fa-square border-right border-bottom text-white ml-2 position-relative"
                            aria-hidden="true"></i>
                        ...<span v-html="withNikud ? row.item.sentence : removeNikud(row.item.sentence)"></span>...
                        <span dir="ltr" class="text-body context">(<span v-if="row.item.fileName !== 'results.txt'">{{
                                row.item.fileName
                                }},</span> No. {{ row.item.wordNumber }})</span>
                    </span>
                </template>
                <template #cell(expression)="data">
                    <span v-html="withNikud ?  data.item.expression : removeNikud(data.item.expression)"></span>
                </template>
                <template #cell(pre)="data">
                    <span v-html="withNikud ? data.item.pre : removeNikud(data.item.pre)"></span>
                </template>
                <template #cell(post)="data">
                    <span v-html="withNikud ? data.item.post : removeNikud(data.item.post)"></span>
                </template>
                <template #cell(menukad)="data">
                    <span v-html="withNikud ? data.item.menukad : data.item.word"></span> <span v-html="data.item.lexeme ? '('+data.item.lexeme+')' : ''" ></span>
                </template>
                <template #cell(morphs)="data">
                    <small :class="{ 'grouped-morph': groupDisplay }" @click="openNewMorphFilter(data.item.morphs)">
                        <span>
                            {{
                                data.item.morphs.join(', ')
                            }}
                        </span>
                        <i v-if="groupDisplay" class="fas fa-external-link mx-2"></i>
                    </small>
                </template>
            </b-table>
        </div>
    </b-container>
</template>

<script>
import { RunStates } from '@/store/runStates'
//import Spinner from '@/components/spinner'
import DownloadResults from "@/components/DownloadResults"
import nGramSlider from '@/components/nGramSlider'

export default {
    name: 'TableResults',
    data() {
        return {
            loading: false,
            withNikud: true
        }
    },
    components: {
        DownloadResults, nGramSlider //Spinner, 
    },
    props: ['fields', 'tableData', 'totalResults', 'nGramsDisplay', 'groupDisplay', 'contextDisplay', 'adjacentDisplay'],
    methods: {
        removeNikud(data) {
            return data.replace(/[\u0591-\u05C7]/g, '')
        },
        newPage(direction) {
            if (direction === 'next') {
                this.$store.commit('SET_PAGE_NUMBER', this.currentPage + 1)
            } else {
                this.$store.commit('SET_PAGE_NUMBER', this.currentPage - 1)
            }
            this.$emit('get-data')
        },
        openNewMorphFilter(morphFilterItems) {
            if (this.groupDisplay) {
                 let morphParam = {
                    'filterType': 'morphology',
                    'filter': {
                        'mode': 'exact',
                        'morphs': morphFilterItems,
                        'morphLang': this.$store.state.morphLang
                    }
                }
                let newParams = this.filters.slice()
                let index = newParams.findIndex(x => x.filterType === 'morphology')
                if (index > -1) {
                    newParams.splice(index, 1)
                }
                newParams.push(morphParam)
                let newUrl = JSON.stringify(newParams)
                window.open(window.location.origin+'/filter-results?search=' + newUrl, '_blank')
            }
        }
    },
    computed: {
        hebrew() {
            return this.$settings.hebrew
        },

        tableBusy() {
            return this.loading || this.running
        },
        running() {
            return this.$store.state.morphApiState === RunStates.RUNNING
        },
        filters() {
            return this.$store.state.filters
        },
        currentPage() {
            return this.$store.state.offset
        },
        totalPages() {
            return this.totalResults ? Math.ceil(this.totalResults / 20) : 0
        }
    }
}  
</script>
<style lang="scss">
.pagination-indicator {
    color: #696969;
    top: -3px;
}

/* .page-item {
    display: none;

    &.prev-link,
    &.next-link {
        display: inline-block;
        padding: 0;
        font-size: 31px;
        border: none;
        background: transparent;

        .page-link {
            border: none !important;
            background: transparent !important;
            color: #000;

            &:hover {
                color: var(--primary)
            }

            padding: 0 4px;
        }

        &.disabled {
            .page-link {
                color: #696969;
            }
        }

    }
}
 */
.table-wrap {
    border-radius: 7px;

    .b-table-sticky-header {
        max-height: 65vh;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
    }

    .table {

        tr {
            &.b-table-row-selected {
                td {
                    background: #d6ecff !important;
                }
            }

            &.b-table-has-details {
                td {
                   // font-weight: bold;
                }
            }
        }

        tr:nth-child(odd) {
            background: #f3f2f2;
        }

        tr:nth-child(even) {
            background: #fff;
        }

        th:after,
        th:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
        }

        th,
        td {
            vertical-align: middle;
            height: 36px;
            //min-width: 70px;
            border-left: solid 1px #d8d8d8;
            font-weight: normal;
            font-size: 16px;
            border-top: none;
            padding: 9px 12px;

            &.b-table-sticky-column {
                background: transparent !important;
                font-size: 20px;
            }

            &.f-narkis {
                font-size: 20px;

                &.context-field {
                    font-size: 18px;
                }

                &.word-cell {
                    width: 180px;
                }
            }

            &.plus-icon {
                min-width: 35px;
                max-width: 35px;
                width: 35px;
            }

            &.text-small {
                font-size: 14px;
            }

            .grouped-morph {
                i {
                    color: transparent;
                }

                cursor: pointer;

                &:hover {
                    color: var(--primary);
                    i {
                        color: var(--primary);
                    }
                    span {
                        text-decoration: underline;
                    }

                    i {
                        display: inline-block;
                    }
                }
            }
        }

        thead {
            th {
                border-left: none;
                background: #d6ecff !important;
                font-size: 16px;
                font-weight: bold;
            }

            th:before {
                top: -1px;
                border-top: 1px solid #d8d8d8;
            }

            th:not(.border-left-0):after {
                bottom: -1px;
                height: 100%;
                border-left: 1px solid #d8d8d8;
                border-bottom: 2px solid #d8d8d8;
            }

            th:first-child {
                border-top-right-radius: 7px;
            }

            th:last-child {
                border-top-left-radius: 7px;
            }
        }
    }

    .fa-square {
        font-size: 8px;
        top: -4px;
    }
}

.context {
    white-space: nowrap;
}

.ngram-notification {
    border-radius: 4px;
}</style>