<template>
    <div class="display-settings">
        <div class="">
            <b-form-radio-group v-model="showWordCloud" :options="displayOptions" button-variant="outline" class="bg-white"
                buttons>
            </b-form-radio-group>
        </div>
        <div class="select-mode mt-3">
            <strong>צורת מילה:</strong>
            <b-form-group>
                <b-form-radio-group id="occ-radio-mode" v-model="selectedMode" :options="modeOptions" name="occ-radio-mode-options"
                    @change="componentKey++">
                </b-form-radio-group>
            </b-form-group>
        </div>
        <div class="d-flex justify-content-between mt-2">
            <div style="font-size:18px" >{{ hebrew ? 'מציג' : 'Displaying' }} {{ totalResults }} {{ hebrew ? 'תוצאות' : 'Results' }}</div>
            <div class="text-left">
                <b-btn variant="link" class="bg-transparent py-0 px-1" style="z-index: 1;left:5px;"
                    @click="showCloudSettingsPopup = true">
                    <i class="fa-solid fa-cog"></i>
                </b-btn>
                <b-btn variant="link" class="bg-transparent py-0 px-1" @click="download">
                    <i class="fa-solid fa-download"></i>
                </b-btn>
            </div>  
        </div>

        <div v-if="showWordCloud">
            <word-cloud ref="wordCloudComp" :key="componentKey"
                
                :selectedMode="selectedMode">
            </word-cloud>
        </div>
        <div v-else>
            <occurrence-table               
                :selectedMode="selectedMode">
            </occurrence-table>
        </div>
        <cloud-settings-modal v-if="showCloudSettingsPopup" v-model="showCloudSettingsPopup"
            :wordCloudDisplay="showWordCloud" @settings-changed="componentKey++">
        </cloud-settings-modal>
    </div>
</template>
<script>

import { downloadBlob } from '@/js/printCSV'
import WordCloud from '@/components/WordCloud'
import OccurrenceTable from '@/components/OccurrenceTable'
import CloudSettingsModal from '@/components/CloudSettingsModal'

export default {
    name: 'DisplaySettings',
    components: { WordCloud, OccurrenceTable, CloudSettingsModal },
    data() {
        return {
            showCloudSettingsPopup: false,
            componentKey: 0,
            selectedMode: 'menukad',
            modeOptions: [
                { text: 'מילה', value: 'menukad' },
                { text: 'לקסמה', value: 'lexeme' },
                { text: 'מילה ללא תחילית', value: 'baseWord' }
            ],

            showWordCloud: true,
            displayOptions: [
                { html: '<span>טבלה</span> <i class="fas fa-table">', value: false },
                { html: '<span>ענן מילים</span> <i class="fas fa-cloud">', value: true }
            ]
        }
    },
    methods: {
        download() {
            if (this.showWordCloud) {
                this.$refs.wordCloudComp.downloadImage()
            } else {
                let index = this.modeOptions.map(function(e) { return e.value; }).indexOf(this.selectedMode);
                let colHeader = this.modeOptions[index].text                
                let occurrenceArr = [ [colHeader, 'Count']]
                this.occurrenceList.map(item=>{
                    occurrenceArr.push([item[this.selectedMode], item.count])
                })
                downloadBlob(occurrenceArr)
            }
        }

    },
    computed: {
        hebrew() {
            return this.$settings.hebrew
        },
        totalResults() {
            return this.selectedMode === 'menukad' ? this.$store.state.totalWordCountItems: this.selectedMode === 'lexeme' ? this.$store.state.totalLexCountItems : this.$store.state.totalBaseCountItems
        }  

    }
}
</script>    
<style scoped lang="scss">
.unique-words {
    border-radius: 4px;
    font-size: 18px;
}

.filter-result {
    cursor: pointer;
    font-size: 16px;
    border: solid 1px #d8d8d8;
    max-width: 250px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.is-active {
        background-color: #d6ecff !important;
        /* important to override hover color */
        border: solid 1px #8fccfc;
    }

    &:hover {
        background-color: #f6f6f6;
    }
}
</style>
<style lang="scss">
.select-mode {
    display: flex;
    align-items: flex-start;

    fieldset {
        margin-bottom: 0;

        div {

            width: auto;
        }
    }
}

.display-settings .btn-group {
    direction: ltr;

    .btn {
        border: solid 1px #bfbfbf;
        cursor: pointer;
    }

    .btn:not(:disabled):not(.disabled).active,
    .btn:not(:disabled):not(.disabled):active {
        background-color: #d6ecff;
        //
    }
}
</style>