<template>
    <header>
      <dicta-header :englishSupported="false"></dicta-header>
      <div class="bg-primary second-header d-flex">
        <div class="container">
          <div class="row">
            <div class="col-8">              
              <div class="logo-div py-2" @click="logoClicked" :class="{'standalone':!this.opener}"> 
               <!--  <h2 class="logo d-inline-block bg-white rounded-circle text-primary text-center m-0 pt-1">
                  <strong>ד</strong>
                </h2>     -->   
                <div class="logo  d-inline-block"  @click="logoClicked">
                  <img src="@/assets/logo.png" alt="logo"/>
                </div>                     
                <div class="d-inline-block  text-white mx-2">
                  <div class="tool-name" v-if="shasCorpus">{{ hebrew ? 'דיקטה אנליטיקס לש"ס' : 'Dicta Analytics for Shas' }}</div>
                  <div class="tool-name" v-else-if="bibleCorpus">{{ hebrew ? 'דיקטה אנליטיקס לתנ״ך' : 'Dicta Analytics for Bible' }}</div>
                  <div class="tool-name" v-else>{{hebrew ? 'דיקטה אנליטיקס' : 'Dicta Analytics'}}</div>
                  <small class="d-none dicta">{{hebrew ? 'גרסת אלפא' : 'Alpha Version'}}</small>
                </div>
              </div>               
            </div>  
            <div class="col-4 pt-2 text-left d-block d-sm-none">
              <mobile-menu                 
                class="mt-1 text-body"
                :class="{'text-right':!hebrew}"  
                @showHowDialog="showAbout=true" 
                :englishSupported="true" 
                :howDialog="true"                    
                >
              </mobile-menu>
            </div>                                   
            <div 
              class="col-md-4">
              <div class="d-md-flex align-items-center justify-content-end h-100" >
                <b-button 
                    id="about-btn" 
                    variant="primary"
                    class="btn-outline-light d-none d-md-inline-block"                    
                    @click="showAbout = true">
                    {{hebrew ? 'איך זה עובד' : 'How it works'}}
                </b-button>
              </div>
              <about-dialog v-model="showAbout"></about-dialog>
            </div>  
          </div>
        </div>
      </div>
    </header>
</template>
<script>
import AboutDialog from "@/components/aboutDialog"

export default {
  name: "Header",
  components: {
    AboutDialog,
  },
  data() {
    return {
      showAbout:false,
      opener: null,
      bibleCorpus: process.env.VUE_APP_USER_NAME === 'library',
      shasCorpus: process.env.VUE_APP_USER_NAME === 'shas'
    }
  },
  mounted () {
    this.opener = window.parent.location !== window.location
  },  
  methods: {
    logoClicked() {
      if (!this.opener && this.$route.name !== 'home' && !this.bibleCorpus && !this.shasCorpus) {
        this.$router.push('/')
        this.$store.commit('CLEAR_RESULTS')
      }
    }
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    }
  }  
}
</script>
<style lang="scss">
  header {
    background: white;
    padding-top: 1px;
  }
  .second-header {
    box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 32%);
  }
  .standalone {
    cursor: pointer;
  }
  .logo {
    height: 36px;
    width: 36px;
    @media screen and (max-width: 575px) {
      height: 32px;
      width: 32px;
    }
    vertical-align: middle;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  button {
    height: 36px;
    font-size: 16px;
  }
  @media screen and (max-width: 575px) {
    .search-form-wrap {
      background: #bfe1fe;
    }
  }    
</style>
