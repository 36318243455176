<template>
  <b-modal v-model="show" ok-only centered size="lg" footer-class="border-top d-none d-sm-block"
    header-class="d-none d-sm-block" :ok-title="hebrew ? 'סגור' : 'Close'" ok-variant="secondary">
    <template slot="modal-header">{{ hebrew ? 'איך זה עובד' : 'How it works' }}</template>
    <div class="mobile-modal-top bg-secondary position-fixed w-100 d-block d-sm-none">
      <div class="row">
        <div class="col-8">
          <h3>{{ hebrew ? 'איך זה עובד' : 'How it works' }}</h3>
        </div>
        <div @click="show = false" class="close-icon col-4" v-bind:class="{ 'text-left': hebrew, 'text-right': !hebrew }">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
    <div class="how-to-text" v-if="hebrew">
      <p>
        כלי האנליטיקס מבית דיקטה מאפשר לערוך ניתוחים על טקסטים עבריים. באמצעותו אפשר לשאול שאילתות על הטקסט ולקבל נתונים
        כמו שכיחויות, צורות ביטוי נפוצות ועוד.
      </p>
      <p>
        <b-button variant="primary" class="px-2" style="border-radius: 4px;" @click="showVideo = true">
          <i class="fab fa-youtube p-1" style="font-size: 20px;"></i>
          <span class="px-1 align-text-bottom">סרטון הדרכה</span>
        </b-button>
        <video-modal v-model="showVideo" :videoId="'f9EwCWvJJ-Y'"></video-modal>
      </p>
      <span v-if="!bibleCorpus && !shasCorpus">
      <p>
        כדי לשאול את השאילתות על הטקסט, בשלב ראשון יש צורך ליישם ניקוד וניתוח מורפולוגי לטקסט. אלו מיושמים באופן אוטומטי
        באמצעות המערכת הממוחשבת של דיקטה.
      </p>
      <p>
        (לניתוח טקסטים מהתנ״ך, יש לגשת לקישור הבא:
        <a href="https://bibleanalytics.dicta.org.il">https://bibleanalytics.dicta.org.il</a>)
      </p>

      <h3 class="text-underline">העלאת טקסט</h3>
      <p>
        אפשר להעלות לכלי כל טקסט בעברית מודרנית.
      <ul>
        <li>
          לשונית "תיבת טקסט": מיועדת להזנת טקסטים של עד 5000 תווים.
        </li>
        <li>
          לשונית "קובץ מנותח": מיועדת להעלאת טקסטים שכוללים למעלה מ-5000 תווים.
        </li>
      </ul>
      תחילה יש להעביר את הטקסט בכלי
      <a href="https://morph-analysis.dicta.org.il?tab=upload">
        ״ניתוח מורפולוגי״</a>
      , ולאחר מכן להעלות את הטקסט המנותח
      (תיקיית ה-zip שתתקבל) באזור המיועד בלשונית.
      </p>
      </span>
      <span v-else>
        <p>
        (לניתוח טקסטים חופשיים, יש לגשת לקישור הבא:
        <a href="https://analytics.dicta.org.il">https://analytics.dicta.org.il</a>)
      </p>
      </span>
      <h3 class="text-underline">הגדרות חיפוש</h3>
      <p>
        החיפוש יכול לכלול הגדרה בודדת כמו ״מילה מנוקדת״, או לכלול מספר הגדרות שמרכיבות יחד את שאלת החיפוש כמו ״מילה
        מנוקדת״ + ״מורפולוגיה״
      </p>
      <p>
        כדי להתחיל יש ללחוץ על ״הגדרות חיפוש״ > ״הוספת שורת חיפוש״.
      </p>
      <p>
        כל שורת חיפוש כוללת שלושה שדות:
      <ul>
        <li>NOT/AND: מגדיר האם שורת החיפוש תיכלל בתוצאות או לא.</li>
        <li>רכיב חיפוש: בלחיצה על החץ תיפתח רשימה שממנה יש לבחור רכיב רצוי (הרחבה בהמשך).</li>
        <li>שורת חיפוש: הגדרת פרטי החיפוש.</li>
      </ul>
      רכיבי חיפוש:
      <ul>
        <li>מילה: אפשר להזין מילה אחת או יותר מהמילים הכלולות בטקסט. כמו כן, אפשר להעלות קובץ מילים בפורמט txt, בלחיצה על
          הסמליל בסוף השורה.
        </li>
        <li>מילה מנוקדת: אפשר להזין מילה מנוקדת אחת או יותר מהמילים הכלולות בטקסט. כמו כן, אפשר להעלות קובץ מילים מנוקדות
          בפורמט txt, בלחיצה על הסמליל בסוף השורה.
        </li>
        <li>לקסמה: אפשר להזין לקסמה אחת או יותר מהלקסמות הכלולות בטקסט. כמו כן, אפשר להעלות קובץ מילים מנוקדות בפורמט txt,
          בלחיצה על הסמליל בסוף השורה.
        </li>
        <li>
          מורפולוגיה: אפשר להזין רכיב מורפולוגי אחד או יותר.
          <ul>
            <li>מחרוזת מורפולוגית: מגדירה האם הרכיבים המורפולוגיים ייכללו במורפולוגיה הנתונה, או לפחות אחד או יותר
              מהרכיבים ייכללו במחרוזת המורפולוגית.</li>
            <li>מורפולוגיה מדויקת: המחרוזת המורפולוגית תכלול את הפריטים שבשורת החיפוש בלבד.
            </li>
            <li>שפה: בלחיצה על גלגל השיניים אפשר לבחור את שפת התצוגה של הרכיבים המורפולוגים - עברית או אנגלית.
            </li>
          </ul>
        </li>
        <li>
          תבנית ניקוד: בלחיצה על גלגל השיניים אפשר להגדיר תבנית ניקוד של אותיות, ניקוד והבהרות.
        </li>
        <li>מסמך: בלחיצה על שורת החיפוש תוצג רשימת המסמכים המלאה. אפשר לבחור אלו מסמכים ייכללו בתוצאות.</li>
      </ul>
      </p>
      <h3 class="text-underline">תצוגת תוצאות</h3>
      <p>כל סוגי התצוגה מושפעים מהחיפוש של הגדרות החיפוש למעט ״ביטויים נפוצים״. כדי להוריד את תוצאות החיפוש יש ללחוץ על
        הסמליל של ההורדה שמופיע מעל טבלת התוצאות (בצד שמאל), בכל אחת מהלשוניות.
      <ul>
        <li>מקראה: תצוגה של הטקסט המלא. התוצאות יודגשו בצהוב. בצד שמאל אפשר לראות את הרשימה המלאה של המסמכים. הכותרות
          בכחול הן מסמכים שבהם נכללים תוצאות חיפו</li>
        <li>שכיחויות: תצוגה של שכיחיות המילים בטקסט. אפשר לבחור בין תצוגה של ענן מילים לתצוגה של טבלה.</li>
        <li>הקשרים: תוצאות החיפוש יופיעו בתוך ההקשר הרחב יותר של הטקסט.</li>
        <li>פירוט מורפולוגי: פירוט מורפולוגי של כל אחת מהתוצאות.
          סיכום מורפולוגי: סיכום של כל המחרוזות המורפולוגיות האפשריות.
        </li>
        <li>ערך קודם: הערך שמופיע לפני תוצאת החיפוש.</li>
        <li>הערך הבא: הערך שמופיע אחרי תוצאת החיפוש.</li>
        <li>סיכום: נתונים של תוצאות החיפוש שמושפעים מהגדרות החיפוש.</li>
        <li>ביטויים נפוצים: טבלה זו אינה מושפעת מהגדרות החיפוש. אפשר להגדיר בה את מספר המילים שייכללו בכל ביטוי.</li>
      </ul>
      </p>
      <p>
        כיוון שמדובר בתהליכים אוטומטיים ייתכנו שיבושים בניקוד ובניתוח המורפולוגי. אם נתקלתם בטעויות כאלה - אנא ידעו אותנו.
      </p>
    </div>
    <div v-else class="how-to-text">

    </div>
  </b-modal>
</template>
<script>
import VideoModal from '@/components/VideoModal'
export default {
  name: "about-dialog",
  props: ["value"],
  components: {
    VideoModal
  },
  data() {
    return {
      bibleCorpus: process.env.VUE_APP_USER_NAME === 'library',
      shasCorpus: process.env.VUE_APP_USER_NAME === 'shas',
      showVideo: false
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
<style lang="scss">
@media screen and (max-width: 767px) {
  #mobile-how-modal {
    img {
      max-width: 100%;
      height: auto;
    }

    -webkit-overflow-scrolling: touch;
    direction: ltr;
    text-align: left;

    .modal-dialog {
      margin: 0 !important;
    }

    .modal-content {
      border-radius: 0 !important;
      height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      background: white;
    }

    .mobile-modal-top {
      height: 50px;
      top: 0;
      right: 0;
      padding: 15px;
      z-index: 1;

      h3 {
        text-decoration: none;
      }
    }

    .how-to-text {
      padding-top: 50px;
      max-height: 88vh;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    @media screen and (max-width: 667px) {
      .how-to-text {
        max-height: 83vh;
      }
    }
  }

  .he #mobile-how-modal {
    text-align: right;
    direction: rtl;
  }
}
</style>
<style scoped lang="scss">
.how-to-text {
  max-height: 70vh;
  overflow: auto;

  li {
    margin: 5px 0;
  }
}
</style>

<style lang="scss">
@media (min-width: 1280px) {
  #mobile-how-modal {

    .modal-lg,
    .modal-xl {
      max-width: 1200px;
    }
  }
}
</style>